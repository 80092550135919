import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import React from "react";
import Header from '../header';
import Footer from '../footer';
import './eco-therm.css';
function ecotherm() {
  return (
    
    <>
    <div className="boxed_wrapper" id="eco-therm">
    <Helmet>
        <title>ECO THERM Environmentally Friendly Acoustic Product</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="ECO THERM Environmentally Friendly Acoustic Product" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/eco-therm" />
        <link rel="alternate" href="https://www.moovgroup.com/eco-therm" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section ecotherm-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>

    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link active">Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link">Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link">Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link">D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link">WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link">Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link">Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="#eco-therm" className="nav-link" role="tab" data-toggle="tab" aria-selected="true">Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link">Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link">Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link">Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link">Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link">Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link">Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link">Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
           
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
           
                <div role="tabpanel" className="tab-pane fade active show" id="eco-therm">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">08. </span>
                        <h1 className="title1">ECOTHERM</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <span className="product8"></span>
                            </div>
                            <div className="top-text-box">
                                    <h2 className="title">ECO THERM</h2>
                                    <p>The carbon-neutral ECOTHERM® acoustic spraying  is made from a mixture of recycled paper, wood adhesive, and water. It is professionally installed and effectively absorbs reverberation due to its tubular cellulosic fiber structure.</p>
                                    <p>ECOTHERM® is an environmentally friendly product, as it is based on recycled paper. It can be directly installed on concrete or plasterboard. You can choose the layer thickness between 5 to 40 mm, depending on the level of damping desired. For specific applications, layer thicknesses greater  than 40 mm are also possible.</p>
                                    <p>When using ECOTHERM® acoustic spraying, you achieve a timeless, simplified, and industrial appearance. The seamless design makes it easy to implement, as only the layer thickness and color need to be specified. This solution is not only efficient but also cost-effective.</p>
                            </div>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/polysorb" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                        </div>
                    </div>
                   
                </div>
                
            </div>
	    </div>
    </div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default ecotherm;