import React from "react";
import { Link } from "react-router-dom";
import './footer.css';
function footer() {
    return (
    <>
      <footer className="footer-area style4">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="single-footer-widget marbtm50-s4">
                  <div className="our-info-box">
                    <div className="footer-logo">
                      <Link to="/" className="moov-logo"></Link>
                    </div>
                    <div className="text">
                      <p>At Moov, we design, engineer, supply and install a variety of acoustic Panels & engineering products and systems across a diverse range of projects creating a seamless, fast track and personal service for the client from concept design to project handover. Subscribe us and get latest acoustic product updates.</p>
                    </div>
                  </div>
                </div>
					
                <div className="single-footer-widget pdtop50-s4">
                  <div className="subscribe-box">
                    <form className="subscribe-form" action="#">
                      <input type="email" name="email" placeholder="Your Email" />
                        <Link to="mailto:mail@moovgroup.com" className="btn-one button" >Subscribe<span className="flaticon-next"></span></Link>
                    </form>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="single-footer-widget s4">
                  <div className="title-style2">
                    <h3>Useful Links</h3>
                  </div>
                  <div className="usefull-links">
                    <ul className="float-left">
                      <li><Link to="/about">About Us</Link></li>
                      <li><Link to="/sectors">Sectors</Link></li>
                      <li><Link to="/products">Products</Link></li>
                      <li><Link to="/projects">Projects</Link></li>   
							      	<li><Link to="/event">Events</Link></li>
                      <li><Link to="/career">Career</Link></li>
                      <li><Link to="/contact">Contact us</Link></li>
                      <li><Link to="/search">Search</Link></li>
                    </ul>
                    <ul className="float-left borders-left">
                      <li><Link to="/architectural-acoustics">Architectural Acoustics</Link></li>
                      <li><Link to="/auditorium-acoustics">Auditorium Acoustics</Link></li>              
                      <li><Link to="/themepark-acoustics">Theme Park Acoustics</Link></li> 
								      <li><Link to="/studio-acoustics">Studio Acoustics</Link></li>
								      <li><Link to="/education-acoustics">Education Acoustics</Link></li>
								      <li><Link to="/homecinema-acoustics">Home Cinema Acoustics</Link></li>
								      <li><Link to="/entertainment-acoustics">Entertainment Acoustics</Link></li>
								      <li><Link to="/acoustic-wall">Acoustic Wall and Floors</Link></li>
							      </ul>
						      </div>
                </div>
              </div>
              
              <div className="col-xl-4 col-lg-3 col-md-12 col-sm-12">
                <div className="single-footer-widget pdtop50-s4">
                  <div className="title-style2">
                    <h3>MOOV CORPORATE OFFICE</h3>
                  </div>
                  <p>Office No# B2002, Latifa Tower,<br />
							      Sheikh Zayed Road,<br />
							      P.O. Box 113239 | Dubai - U.A.E. <br />
							      Tel: 04-3310000 <br />
							      Mail: mail@moovgroup.com</p>     
						      <div className="mapouter">
				            <div className="gmap_canvas">
						          <iframe title="mapframe" width="550" height="200" id="gmap_canvas" src="https://maps.google.com/maps?q=moov group&t=&z=8&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
							        </iframe><br />
					          </div>
					        </div><br />
					      </div>
                <div className="follow-us-social-links clearfix" style={{position: "relative", display: "block"}}>
					        <span>Follw Us On:</span>
						      <ul>
							      <li><Link to="https://www.facebook.com/moovgroup" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook" aria-hidden="true"></i></Link></li>
							      <li><Link to="https://www.instagram.com/moovgroup/" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram" aria-hidden="true"></i></Link></li>
							      <li><Link to="https://www.linkedin.com/company/moov-group/" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin" aria-hidden="true"></i></Link></li>
							      <li><Link to="https://twitter.com/TheMoovGroup" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter" aria-hidden="true"></i></Link></li>
						      </ul>
					      </div>
              </div>
            </div>
          </div>  
        </footer>
    
        <section className="footer-bottom-area style3">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div className="copyright-text text-center">
                  <p><Link to="/">©  All Rights Reserved by </Link>| Designed By<Link to="/">Moov Group</Link></p>
                </div>
              </div>
            </div>
          </div>
        </section>
       
      </>
    );
  }
export default footer;
