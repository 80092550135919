import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './pu-foam.css';
function putrap() {
  return (
    
    <>
    <div className="boxed_wrapper" id="pu-foam">
    <Helmet>
        <title>Pufoam Bass Trap Design Noise Stop Polyurethane Foams</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Pufoam Bass Trap Design Noise Stop Polyurethane Foams" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/pu-foam-bass-trap-design" />
        <link rel="alternate" href="https://www.moovgroup.com/pu-foam-bass-trap-design" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section pufoam-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="section-gap">            
        <div className="container">
            <div className="row">
                
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                    <div className="sidebar-wrapper">
                    
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Categories</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/pu-foam-pyramid-design" className="nav-link" >Foam Stop Pyramid Design</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam-flat-design" className="nav-link" >Foam Stop Plain (FLAT) Design</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam-cylindrical-panels" className="nav-link" >Foam Stop Cylindrical Design</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam-bass-trap-design" className="nav-link active"  role="tab" data-toggle="tab" aria-selected="true">Foam Stop Bass Trap Design</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam-eggcrate-design" className="nav-link" >Foam Stop Eggcrate Design</Link></li>
                            </ul>
                        </div>
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link></li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link></li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link></li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link></li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link></li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link></li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane Foam</Link></li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link></li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link></li>
                            </ul>
                        </div>
                      
                    </div>
                </div>
       
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">04. </span>
                        <h1 className="title1">POLYURETHANE FOAMS BASS TRAP DESIGN</h1>
                    </div>
                    
                    <div className="row">
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="pufoam-sub4"></div>
                                </div>
                                <div className="top-text-box">
                                    <h1><span className="fabriwall">POLYURETHANE FOAMS </span>- BASS TRAP DESIGN</h1>
                                    <br />
                                    <p> Acoustic foam is an open celled foam used for acoustic treatment. It attenuates airborne sound waves by increasing air resistance, thus reducing the amplitude of the waves. The energy is dissipated as heat.</p>
                                    <p>Acoustic foam comes in a variety of sizes and can be attached to walls, ceilings, doors, and other features of a room to control noise levels, vibration, and echoes. </p>
                                </div>
                            </div>
                      
							<div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
                                <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Offices</li>
                                            <li>Schools</li>
                                            <li>Meeting Rooms</li>
											<li>Hotels</li>
											<li>Auditorium</li>
											<li>Recording Studios</li>
											<li>Conference halls</li>
											<li>Broadcasting Studios</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
							
                        </div>
                    </div>
                 
                    <div className="row">
                        <div className="faq-content-box">
                            <div className="accordion-box">
							
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>Specification</h4>
                                    </div>
                                    <div className="accord-content collapsed">
									    <table className="table">
									        <tbody>
											<tr>
											<th scope="row">Core</th>
												<td>Open Cell Polyurethane Acoustical Foam</td>
											</tr>
											<tr>
											<th scope="row">Thickness (mm)</th>
												<td> Custom thickness</td>
											</tr>
											<tr>
											<th scope="row">Sizes (mm)</th>
												<td>300mm x 300mm x 600mm, 350mm x 300mm x 600mm & also custom sizes</td>
											</tr>
											<tr>
											<th scope="row">Acoustic Properties</th>
												<td>NRC 0.75 ( varies with thickness and mounting ) </td>
											</tr>
											<tr>
											<th scope="row">Density</th>
												<td>1lbs/cub.ft and 2 lbs/cub.ft</td>
											</tr>
											<tr>
											<th scope="row">Colours</th>
												<td>Charcoal Grey</td>
											</tr>
											<tr>
											<th scope="row">Flammability</th>
												<td>Meets UL94 HF -1 Standard</td>
											</tr>
											<tr>
											<th scope="row">Installation</th>
												<td>ASI S.T.O.P Noise Acoustical Adhesive or any construction adhesive </td>
											</tr>
                                            <tr>
											<th scope="row">Mounting type</th>
												<td>Type A</td>
											</tr>
										    </tbody>
									    </table>
                                    </div>
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>REVERBERATION</h4>
                                    </div>
                                    <div className="accord-content collapsed">
                                        <table className="table">
										<thead>
											<tr>
											    <th scope="col">Thickness</th>
												<th scope="col">125Hz</th>
												<th scope="col">250Hz</th>
												<th scope="col">500Hz</th>
												<th scope="col">1KHz</th>
												<th scope="col">2KHz</th>
												<th scope="col">4KHz</th>
												<th scope="col">NRC</th>
											</tr>
										</thead>
										<tbody>
											<tr>
											<th scope="row">BS-1</th>
												<td>0.46</td>
												<td>1.00</td> 
												<td>0.78</td>
												<td>0.64</td>
												<td>0.63</td>
												<td>0.70</td>
												<td>0.75</td>
											</tr>
											<tr>
											<th scope="row">BS-2</th>
												<td>0.90</td>
												<td>0.84</td> 
												<td>0.80</td>
												<td>0.64</td>
												<td>0.62</td>
												<td>0.70</td>
												<td>0.75</td>
											</tr>
										</tbody>
									    </table>
                                    </div>
                                </div>
                               
							</div>
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <a href="https://www.acousticalsurfaces.in/foamstop-pu" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default putrap;