import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './diffuso.css';
function diffuso() {
  return (
    
    <>
    <div className="boxed_wrapper" id="diffuso">
    <Helmet>
        <title>Diffuso is control reflections in the listening environment</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Diffuso is control reflections in the listening environment" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/diffuso" />
        <link rel="alternate" href="https://www.moovgroup.com/diffuso" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section diffuso-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link">Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link">Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link">Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link">D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link">WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link">Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link">Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link">Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link">Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link">Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link">Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#diffuso" className="nav-link" role="tab" data-toggle="tab" aria-selected="true">Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link">Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link">Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link">Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
         
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
         
                <div role="tabpanel" className="tab-pane fade active show" id="diffuso">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">12. </span>
                        <h1 className="title1">DIFFUSO</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <div className="product12"></div>
                            </div>
                            <div className="top-text-box">
                                <p>DIFFUSO is a 2-Dimensional PRD Diffuser carefully designed utilizing modulated prime root number theory. The calculation shows us that the reflected energies will be equal in all the diffraction directions. They are used to control reflections in the listening environment, particularly from rear walls and first-reflection points.</p>
                                
                                <div className="blog-single-bottom-content-box">
                                    <h2>Features </h2>
								    <div className="row">
                                        <ul>
                                            <li>Made from solid wood</li>
                                            <li>Long lasting and impact resistant</li>
                                            <li>Highly aesthetic on exposed installation</li>
										    <li>Reduce Flutter Echoes and Sound Bouncing of the walls</li>
										    <li>Works best on Mid-High frequencies</li>
                                        </ul>
                                    </div>
                                </div>
                               
                                <div className="blog-single-bottom-content-box">
                                    <h2>Applications</h2>
							        <div className="row">
                                        <ul>
                                            <li>Studios</li>
                                            <li>Mixing Rooms</li>
                                            <li>Hi-Fi Rooms</li>
									        <li>Home Theaters</li>
									        <li>Auditoriums</li>
                                        </ul>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <Link to="/polymax" className="main-btn main-btn-2 mt-40">Polymax <span className="icon">+</span></Link>
                    </div>
                </div>
                
            </div>
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default diffuso;