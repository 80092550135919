import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './vinyl-barrier.css';
function vinylbarrier() {
  return (
    
    <>
    <div className="boxed_wrapper" id="vinyl">
    <Helmet>
        <title>Mass Loaded Vinyl Barrier MLVB</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Mass Loaded Vinyl Barrier MLVB" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/vinyl-barrier" />
        <link rel="alternate" href="https://www.moovgroup.com/vinyl-barrier" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section vinyl-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link active">Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link">Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link">Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link">D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link">WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link">Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="#vinyl-barrier" className="nav-link" role="tab" data-toggle="tab" aria-selected="true">Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link">Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link">Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link">Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link">Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link">Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link">Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link">Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link">Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
            
            <div role="tabpanel" className="tab-pane fade active show" id="vinyl-barrier">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">07. </span>
                    <h1 className="title1">Mass Loaded Vinyl Barrier</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product7"></div>
                        </div>
                        <div className="top-text-box">
                            <h1><span className="fabriwall"> </span>Noise S.T.O.P.™ Vinyl Barrier</h1><br />
                            <p> When noise and sound transmission needs to be effectively controlled, Mass Loaded Vinyl Noise Barrier is the solution to build noise barriers and soundproof walls. </p>
                        </div>
                    </div>
                    <div className="blog-single-bottom-content-box">
					    <h2>Product Specs </h2>
                        <div className="row">
                            <div className="col-xl-12">
                            <ul>
                                <li className="li-specs"><p>Material<span className="specs">Mass Loaded Vinyl Sound Barrier</span></p></li>
                                <li className="li-specs"><p>Pattern<span className="specs">Smooth Finish</span></p></li>
                                <li className="li-specs"><p>Features<span className="specs">This acoustical barrier is made from high density limp material to reduce noise transmission.</span></p></li>
								<li className="li-specs"><p>Applications<span className="specs">Noise S.T.O.P. Vinyl Barrier reduces noise transmission through ceilings, walls, floors, machinery enclosures, ductwork. Apply over suspended ceilings on studs or joints.</span></p></li>
								<li className="li-specs"><p>Thickness<span className="specs">1/16″ – 1/2 lb/sf; 1/8″ – 1 lb/sf; 1/4″ – 2 lb/sf</span></p></li>
								<li className="li-specs"><p>Sizes<span className="specs">54″ Wide × 20′, 30′, or 60′ Rolls – 2′×2′, 2′×4′ Sheets, Die Cutting Available</span></p></li>
								<li className="li-specs"><p>Colors<span className="specs">Black, Grey (Reinforced), Tan and Clear (Transparent or Translucent) - Aluminized Mylar / Foil can be added to one side</span></p></li>
								<li className="li-specs"><p>Flammability<span className="specs">UL 94VO</span></p></li>
								<li className="li-specs"><p>Installation<span className="specs">Acoustical barrier material can be nailed, screwed, stapled or can be reinforced and grommeted and hung like a curtain.</span></p></li>
							</ul>
                            </div>
                        </div>
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/mass-loaded-vinyl" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
            
            </div>
        
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default vinylbarrier;