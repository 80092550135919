import React from "react";
import {Helmet} from "react-helmet";
import Header from '../header';
import Footer from '../footer';
import './pet-panel.css';
import { Link } from "react-router-dom";
function petpanel() {
  return (
    
    <>
    <div className="boxed_wrapper" id="petpanel">
    <Helmet>
        <title>Acoustic Wall Panels - PET Panels Manufacturer and Supplier</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="PET Panels Polyester Fiber Acoustic Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/pet-panel" />
        <link rel="alternate" href="https://www.moovgroup.com/pet-panel" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section decopanel-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><a href="/#">Moov Group</a></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link active" >Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link"  >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link"  >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link"  >Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link"  >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
            <div role="tabpanel" className="tab-pane fade active show" id="decopanel">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">4.1 </span>
                    <h1 className="title1">PET Panel</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="pet"></div>
                        </div>
                        <div className="top-text-box">
                            <p>PET Panels are highly absorptive <Link to="/">Acoustic Wall Panels</Link> with non-woven polyester fiber as its core. They form an effective remedy for reverberation related issues in interior spaces and are customizable to any design depending upon the customer demands. These panels are available in 21 different color options.</p>
                            
						    <div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Aesthetically pleasing</li>
                                        <li>High sound absorption performance</li>
                                        <li>Available with various edges and colours</li>
									    <li>Fire resistant</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Environmentally friendly</li>
                                        <li>Humidity resistance</li>
										<li>Easily cleanable and can be vacuumed</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
						    
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/decopanel" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
            
            </div>
        
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default petpanel;