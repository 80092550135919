import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './sectors.css';
import Carousel from '../carousel';
function hospitality() {
  return (
    
    <>
    <div className="boxed_wrapper" id="sector">
    <Helmet>
        <title>Hospitality Acoustics and Soundproofing Solutions</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Hospitality Acoustics and Soundproofing Solutions" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/hospitality-acoustics" />
        <link rel="alternate" href="https://www.moovgroup.com/hospitality-acoustics" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section sector-bg">
        <div className="container">
            <span className="page-tag">BUSINESS</span>
            <h1 className="page-title">SECTORS</h1>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Sectors</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/architectural-acoustics" className="nav-link">Architectural Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/auditorium-acoustics" className="nav-link">Auditorium Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/themepark-acoustics" className="nav-link">Theme Park Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/studio-acoustics" className="nav-link">Studio Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/education-acoustics" className="nav-link">Education Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/homecinema-acoustics" className="nav-link">Home Cinema Acoustics</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/entertainment-acoustics" className="nav-link">Entertainment Acoustics</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/seating-solutions" className="nav-link">Seating Solutions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/hospitality-acoustics" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Hospitality Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/commercial-acoustics" className="nav-link">Commercial Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-wall" className="nav-link">Acoustic Wall and Floors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/industrial-acoustics" className="nav-link">Industrial Acoustics</Link>
                                </li>
							</ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
                <div role="tabpanel" className="tab-pane fade active show" id="eco-therm">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">09. </span>
                        <h1 className="title1">HOSPITALITY ACOUSTICS</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <span className="sector-hospitality"></span>
                            </div>
                            <div className="top-text-box">
                                    <h2 className="title">HOSPITALITY ACOUSTICS</h2>
                                    <p>At Moov Group, we understand the importance of acoustics in the hospitality industry. Whether it’s a restaurant, bar, cafe, or hotel, the ability to communicate clearly and enjoy a pleasant sensory experience is crucial to customer satisfaction and retention.</p>
                                    <p>Our team of experts specializes in soundproof insulation and acoustic solutions for a wide range of applications. We offer a variety of products and services to help improve the acoustics of your hospitality space, including acoustic wall panels, stretched fabric wall systems, thick fabric curtains, carpeting, and fabric seating.</p>
                                    <p>When designing an acoustic treatment for your hospitality center, we take into account several factors such as the size of the space, the materials used in construction, the location of the space relative to potential noise sources, and the use of sound-absorbing materials. Our goal is to create a balance between sound-reflecting and sound-absorbing materials to reduce echo and improve overall acoustics.</p>
                                    <p>With our extensive experience and expertise in the field of acoustics, you can trust Moov Group to provide top-quality solutions for your hospitality space. Contact us today to learn more about how we can help improve the acoustics of your venue.</p>
                            </div>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/sectors" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                        </div>
                    </div>
                    
                </div>
                
            </div>
	    </div>
    </div>
    </section>
    
    <section className="recently-project-style2-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="pro-title">Our Projects</div>
                    <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                </div>
                <Carousel />
            </div>
        </section>
        </div>
    </div>
<Footer />
</>
  );
}

export default hospitality;