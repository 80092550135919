import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './sectors.css';
import Carousel from '../carousel';
function commercial() {
  return (
    
    <>
    <div className="boxed_wrapper" id="sector">
    <Helmet>
        <title>Commercial Acoustics and Soundproofing Solutions</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Commercial Acoustics and Soundproofing Solutions" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/commercial-acoustics" />
        <link rel="alternate" href="https://www.moovgroup.com/commercial-acoustics" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
        <section className="breadcrumb-section sector-bg">
            <div className="container">
                <span className="page-tag">BUSINESS</span>
                <h1 className="page-title">SECTORS</h1>
            </div> 
        </section>
    
        <section className="advanced-tabs section-gap">            
	        <div className="container">
                <div className="row">
			
			        <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                        <div className="sidebar-wrapper">
                            <div className="single-sidebar categories-box">
                                <div className="sidebar-title">
                                    <div className="title">Sectors</div>
                                </div>
                                <div className="container-fluid">
                                    <ul className="nav categories clearfix product-tabs" role="tablist">
                                        <li className="nav-item">
                                            <Link to="/architectural-acoustics" className="nav-link">Architectural Acoustics</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/auditorium-acoustics" className="nav-link">Auditorium Acoustics</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/themepark-acoustics" className="nav-link">Theme Park Acoustics</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/studio-acoustics" className="nav-link">Studio Acoustics</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/education-acoustics" className="nav-link">Education Acoustics</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/homecinema-acoustics" className="nav-link">Home Cinema Acoustics</Link>
                                        </li>
								        <li className="nav-item">
								            <Link to="/entertainment-acoustics" className="nav-link">Entertainment Acoustics</Link>
								        </li>
                                        <li className="nav-item">
                                            <Link to="/seating-solutions" className="nav-link">Seating Solutions</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/hospitality-acoustics" className="nav-link">Hospitality Acoustics</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/commercial-acoustics" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Commercial Acoustics</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/acoustic-wall" className="nav-link">Acoustic Wall and Floors</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/industrial-acoustics" className="nav-link">Industrial Acoustics</Link>
                                        </li>
							        </ul>
                                </div>
                            </div>
                        </div>
                    </div> 
            
                    <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                        <div role="tabpanel" className="tab-pane fade active show" id="eco-therm">
                            <div className="section-title mb-40">
                                <span className="line"></span>
                                <span className="title-tag">10. </span>
                                <h1 className="title1">COMMERCIAL ACOUSTICS</h1>
                            </div>
                            <div className="blog-post">
                                <div className="single-blog-post">
                                    <div className="main-image-box">
                                        <span className="sector-commercial"></span>
                                    </div>
                                    <div className="top-text-box">
                                        <h2 className="title">COMMERCIAL ACOUSTICS</h2>
                                        <p>At Moov Group, we are at the forefront of the architectural acoustic product supply and contracting industry in the Middle East.We offer a wide range of products for the Acoustics and Sound Proofing Industry, and our activities are based on mutual trust and flexible cooperation with our customers at every stage of the work. With our high quality and reliable services, we have consistently been able to meet our customers’ needs and establish long-term relationships with clients from all over the world.</p>
                                        <p>Our commercial acoustics solutions are designed to meet the needs of businesses and organizations that require effective noise control and sound absorption. Our team of experts works closely with architects, developers, general contractors, and end clients to develop technically feasible and acceptable solutions. By combining our core competencies of acoustic absorption, sound blocking, sound masking, and acoustic consulting, we are able to deliver quality acoustic solutions on our projects.</p>
                                        <p>When undertaking a commercial acoustic project with us at Moov Group, there are several key points to focus on. These include reverberation analysis, location, sound absorption, sound blocking, sound masking, and acoustic consulting. Our team of experts will guide you through every step of the process to ensure that your commercial space achieves effective noise control and sound absorption.</p>
                                        <p>At Moov Group, we are committed to providing our clients with the highest quality commercial acoustic solutions. Contact us today to learn more about how we can help you achieve your commercial acoustics goals.</p>
                                    </div>
                                </div>
                                <span className="line-footer"></span>
                                <div className="portfolio-container2">
                                    <Link to="/sectors" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
	            </div>
            </div>
        </section>
        <section className="recently-project-style2-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="pro-title">Our Projects</div>
                    <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                </div>
                <Carousel />
            </div>
        </section>
    </div>
</div>
<Footer />
</>
  );
}

export default commercial;