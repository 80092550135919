import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './echo-eliminator.css';
function echoeliminator() {
  return (
    
    <>
    <div className="boxed_wrapper" id="echo-eliminator">
    <Helmet>
        <title>Echo Eliminator - Bonded Acoustical Cotton Ceiling and Wall Panels</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Echo Eliminator - Bonded Acoustical Cotton Ceiling and Wall Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/echo-eliminator" />
        <link rel="alternate" href="https://www.moovgroup.com/echo-eliminator" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
        <section className="breadcrumb-section echoeliminator-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link active">Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link">Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link">D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link">WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#echo-eliminator" className="nav-link" role="tab" data-toggle="tab" aria-selected="true">Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link">Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link">Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link">Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link">Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link">Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link">Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link">Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link">Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link">Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
            
            <div role="tabpanel" className="tab-pane fade active show" id="echo-eliminator">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">06. </span>
                    <h1 className="title1">ECHO ELIMINATOR</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product6"></div>
                        </div>
                        <div className="top-text-box">
                            <h1><span className="fabriwall"> </span>Bonded Acoustical Cotton (B.A.C.)</h1><br /><br />
                            <h4>The Most Cost Effective Acoustical Absorbing Material On The Market </h4><br />
						    <p>Echo Eliminator is a high-performance acoustical material made from recycled cotton. </p>
							<p>These cotton fabric panels are ideal for noise control applications.</p>
							<p>Echo Eliminator can be used as an acoustic wall panel or hanging baffle. </p>
							<p>Common applications of these cotton fabric panels are school gyms, classNamerooms, lunch rooms and any application where a high-performance noise control product is needed.</p> 
							<p>Echo Eliminator cotton fabric panels are eligible for LEED™ credits, className A fire rated and 100% recyclable. </p>
                        </div>
                    </div>
                    
					<div className="blog-single-bottom-content-box">
					    <h2>Applications </h2>
                        <div className="row">
                            <div className="col-xl-6">
                            <ul>
                                <li>Recycled Cotton (Green Acoustical Material)</li>
                                <li>className A – Non-Flammable (Per ASTM E-84)</li>
                                <li>Lightweight</li>
								<li>Easy to Install</li>
								<li>Low Cost</li>
								<li>High Light Reflectance</li>
								<li>High Performance Acoustical Absorption</li>
								<li>Relocatable (with Grommets)</li>
                            </ul>
                            </div>
                        </div>
                    </div>
					
                    <div className="blog-single-bottom-content-box">
                        <h2>Product Photos </h2>
                        <div className="row">
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub1"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub2"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub3"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub4"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub5"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub6"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub7"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub8"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
				    </div>
                    
                    <div className="blog-single-bottom-content-box">
                        <h2>Color Options</h2>
                        <div className="row">
                           
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub9"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub10"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub11"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub12"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub13"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub14"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
				    </div>
                      
				</div>
				<span className="line-footer"></span>
				<div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/echo-eliminator" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
            
        </div>
        
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default echoeliminator;