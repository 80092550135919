import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './fabriwall.css';
function fabripanel() {
  return (
    
    <>
    <div className="boxed_wrapper" id="fabriwall">
    <Helmet>
        <title>Fabriwall Panel Stretch Fabric Acoustic Panels</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Fabriwall Panel Stretch Fabric Acoustic Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/fabriwall-panel" />
        <link rel="alternate" href="https://www.moovgroup.com/fabriwall-panel" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section fabriwall-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="section-gap">            
        <div className="container">
            <div className="row">
                
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                    <div className="sidebar-wrapper">
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Categories</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabriwall-track" className="nav-link" >FabriWALL TRACK</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall-fabric" className="nav-link" >FabriWALL FABRIC</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall-panel" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">FabriWALL PANEL</Link></li>
                            </ul>
                        </div>
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link></li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link></li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link></li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link></li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link></li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link></li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane Foam</Link></li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link></li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">03. </span>
                        <h1 className="title1">FabriWALL Panel</h1>
                    </div>
                    
                    <div className="row">
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="fabriwall-sub3"></div>
                                </div>
                                <div className="top-text-box">
                                   <h1><span className="fabriwall">Absorbtion</span> PANEL</h1>
                                   <br />
                                   <p>PANEL is the unseen element of the system installed inside Track's frame and fabric. They remain hidden behind fabric and are having high sound absorption properties. Increase in hard surfaces in the modern buildings contribute largely to the increased sound propagation and excess noise levels these days. </p>
							        <p>The panels in Fabriwall helps in efficient sound absorption without  compromising the aesthetics of the space.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="faq-content-box">
                            <div className="accordion-box">
							
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>ECHO ELIMINATOR</h4>
                                    </div>
									<div className="accord-content collapsed">
									<h2>Bonded Acoustical Cotton (B.A.C.)<br /> The Most Cost Effective Acoustical Absorbing Material On The Market</h2> <br/>
                                    <p>Echo Eliminator is a high-performance acoustical material made from 
										recycled cotton. These cotton fabric panels are ideal for noise control 
										applications. Echo Eliminator can be used as an acoustic wall panel or 
										hanging baffle. Common applications of these cotton fabric panels are 
										school gyms, classNamerooms, lunch rooms and any application where a 
										high-performance noise control product is needed. Echo Eliminator 
										cotton fabric panels are eligible for LEED™ credits, className A fire rated 
										and 100% recyclable. </p>
									    <div className="blog-single-bottom-content-box">
                                        <h2>Features</h2>
                                        <br/>
                                            <div className="row">
                                                <div className="col-xl-6">
                                                <ul>
                                                <li>Recycled Cotton</li>
                                                <li>className A – Non-Flammable</li>
                                                <li>Lightweight</li>
												<li>Easy to Install</li>
												<li>Low Cost</li>
                                                <li>High Light Reflectance</li>
                                                <li>High Acoustical Absorption</li>
												<li>Getting Upto 1 NRC by related thickness</li>
                                                </ul>
                                                </div>
                                            </div>
					                    </div>
                                    </div>  
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>POLYMAX</h4>
                                    </div>
									<div className="accord-content collapsed">
									<h2>POLYMAX</h2> <br />
                                    <p>Poly Max polyester tackable wall panels offer an acoustically absorbent panel 
										that is non-allergenic, non-toxic, and contains no chemical irritants or formaldehyde </p>
										<div className="blog-single-bottom-content-box">
                                    <h2>Features</h2>
                                    <br />
                                            <div className="row">
                                                <div className="col-xl-6">
                                                <ul>
                                                <li>Formaldehyde Free</li>
                                                <li>No Binding Agents</li>
                                                <li>No Chemical Irritants</li>
												<li>Architecturally Decorative</li>
												<li>Tackable Surface</li>
                                                <li>Impact Resistant</li>
                                                <li>className A Fire Rated</li>
												<li>60% Polyethylene Terephthalate </li>
												<li>Recycled Content </li>
                                                </ul>
                                                </div>
                                            </div>
					                    </div>
                                    </div>  
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>FOAMSTOP</h4>
                                    </div>
									<div className="accord-content collapsed">
									<h2>FOAMSTOP<br /> </h2> <br/>
                                    <p>Echo Eliminator is a high-performance acoustical material made 
										from recycled cotton. Echo Eliminator cotton fabric panels are 
										eligible for LEED™ credits, className A fire rated </p>
										<div className="blog-single-bottom-content-box">
                                        <h2>Features</h2>
                                        <br />
                                            <div className="row">
                                                <div className="col-xl-6">
                                                <ul>
                                                <li>Recycled Cotton</li>
                                                <li>className A – Non-Flammable</li>
                                                <li>Lightweight</li>
												<li>Easy to Install</li>
												<li>Low Cost</li>
                                                <li>High Light Reflectance</li>
                                                <li>High Acoustical Absorption</li>
												<li>Getting Upto 1 NRC by related thickness </li>
                                                </ul>
                                                </div>
                                            </div>
					                    </div>
                                    </div>  
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>FIBER BOARD</h4>
                                    </div>
									<div className="accord-content collapsed">
									<h2>FIBER BOARD<br /> </h2> <br/>
                                    <p>Acoustic fiberglass as a form of audio insulation consists 
										of rather small particles of compressed glass or plastic. </p>
										<div className="blog-single-bottom-content-box">
                                        <h2>Features</h2>
                                        <br />
                                            <div className="row">
                                                <div className="col-xl-6">
                                                <ul>
                                                <li>Recyclable Glass</li>
                                                <li>Fiberglass conducts thermal isolation</li>
                                                <li>Lightweight</li>
												<li>Easy to Install</li>
												<li>Low Cost</li>
                                                <li>High Light Reflectance</li>
                                                <li>High Acoustical Absorption</li>
												<li>Getting Upto 1 NRC by related thickness</li>
                                                </ul>
                                                </div>
                                            </div>
					                    </div>
                                    </div>  
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <a href="https://www.acousticalsurfaces.in/fabriwall" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                    </div>
                </div>
               
            </div>
        </div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default fabripanel;