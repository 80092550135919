import React from "react";
import { Helmet } from "react-helmet";
import Header from '../header';
import Footer from '../footer';
import './sectors.css';
function sector() {
    return (
        <>
            <div className="boxed_wrapper" id="sector">
                <Helmet>
                    <title>Our Business Sectors - Moov Group</title>
                    <meta name="description" content="" />
                    <meta property="og:title" content="Our Business Sectors" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/sectors" />
                    <link rel="alternate" href="https://www.moovgroup.com/sectors" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section sector-bg">
                        <div className="container">
                            <span className="page-tag">Business</span>
                            <h1 className="page-title">Sectors</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image1 mt-4"></span>
                                                <span className="sector-image13 mt-2"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">01.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Architectural Acoustics and Soundproofing</h5>
                                                <p>“Moov Group- Transforming the world of sound ”</p>
                                                <p>We are a leading provider of soundproof insulation and acoustic solutions, with expertise in the field of architectural acoustics and soundproofing. Our wide range of products and services are designed to meet the needs of all applications, including auditoriums, recording studios, home theatres, hotels, resorts, and many more.</p>
                                                <p>We understand the importance of acoustics in building design. Our team of experts works closely with architects and designers to ensure that the acoustics of a building are considered early in the design process. We    a variety of acoustic products, tools such as simulation softwares and design techniques to create a good listening experience that enhances the design rather than compromising it.</p>
                                                <p>When undertaking an architectural acoustics and soundproofing project with us at Moov Group, there are several key points we concentrate on. These include reverberation analysis, location, sound absorption, sound blocking, sound masking, and acoustic consulting. Our team of experts will guide you through every step of the process to ensure that your space achieves effective noise control and sound absorption.</p>
                                                <p>Our soundproofing solutions are designed to reduce noise levels in environments near major avenues, like schools, construction zones, or even drummers’ neighbours. We use various acoustic products that increase the structural mass of walls, floors, and ceilings, and seal air gaps around doors and windows to reduce noise transmission.</p>
                                                <p>In addition to soundproofing, we also offer sound treatment solutions to improve sound quality within an environment. Our acoustic absorbing materials are designed to absorb sound that would otherwise be reflected back into the room. We also offer diffusion solutions to reduce echo and reverberation levels in a space.</p>
                                                <p>We at Moov Group, are committed to provide our clients with the highest quality acoustic solutions. Contact us today to learn more about how we can help you achieve your architectural acoustics and soundproofing goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center justify-content-center">
                                        <div className="col-lg-6 col-md-6 order-md-2">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image2"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">02.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Auditorium Acoustics</h5>
                                                <p>Let’s dive into the fascinating world of auditorium acoustics. Designing an acoustically pleasing auditorium involves both science and art, where every decision impacts overall sonic experience. The goal is to control the behaviour of sound waves by minimizing undesired reflections, echoes, and reverberation while enhancing the desired sound qualities. Acoustic treatment for auditoriums may vary based on the shape of the room but typically involves the use of high-end sound-absorbing materials and sound-diffusing materials.</p>
                                                <p>Acoustic treatment can be achieved through a variety of materials and its usage. The placement of these materials is crucial in achieving optimal acoustics, as they must be strategically placed to target specific frequencies and areas within the space. At Moov, we carefully handle these factors to achieve our goal by utilizing high performance materials tailored for specific acoustic needs and providing customised solutions to meet the unique space requirements.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image3"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">03.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Theme Park Acoustics</h5>
                                                <p>Moov group, being a premier provider of architectural acoustic products in the Middle East, our dynamic business group is dedicated to deliver top-quality products and services to meet the requirements of our customers.</p>
                                                <p>Our extensive range of acoustic and engineering products and systems are custom-designed, engineered, supplied, and installed to meet the unique requirements of each project. Our team of skilled designers and engineers collaborate to provide seamless, efficient, and personalized service from the initial concept design to the final project handover.</p>
                                                <p>In the world of theme park design, managing noise and vibration levels is essential for creating an optimal acoustic environment for visitors. Our expertise in this area makes us a leading provider of acoustics solutions for entertainment venues.</p>
                                                <p>Guided by our visionary Managing Director, Moov Group has grown into one of the most successful and professionally managed enterprises in the United Arab Emirates. We value building long-lasting relationships with our clients based on mutual trust and cooperation at every stage of our work.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6 order-md-2">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image4"></span>
                                                <span className="sector-image15"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">04.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Broadcasting & Recording Studio Acoustics</h5>
                                                <p>"Trust Moov Group for exceptional acoustic solutions for your recording studio needs.”</p>
                                                <p>We understand the importance of acoustics in a recording studio. Our goal is to help you create an acoustically balanced environment that allows for accurate monitoring and recording of sound. Our team of experts has extensive knowledge in soundproof insulation, acoustic solutions, and acoustic panels, allowing us to offer top-quality services for a variety of applications. </p>
                                                <p>We specialize in the Acoustic Design & Construction of world-class TV/Radio recording and broadcasting Studios to recording studios and Podcast rooms. By utilizing various acoustic treatment techniques such as absorption, diffusion, and bass trapping, we can advise and give solutions control reflections, reduce standing waves, and enhance the overall sound quality within your studio. We’ve had the privilege to work on several high-profile broadcasting projects, including Azam TV, Kalba TV, Dance FM, and SKY News. These collaborations highlight our expertise and dedication in delivering top-notch solutions for the broadcasting industry.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image5 mt-3"></span>
                                                <span className="sector-image14 mt-2"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">05.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Education Acoustics</h5>
                                                <p>“Unlock the learning potential with Moov’s Educational Acoustics”</p>
                                                <p>At Moov Group, we recognize the critical role that acoustics play in educational institutions. A well-designed acoustic environment can significantly enhance the learning experience for students and facilitate a more conducive teaching atmosphere for educators.</p>
                                                <p>Research has demonstrated that suboptimal acoustics in classrooms can have a detrimental impact on students’ comprehension of speech, resulting in diminished academic performance and elevated stress levels. This is particularly relevant for students with hearing impairments or language processing difficulties. Educators are also adversely affected by poor acoustics, as they are compelled to exert their voices to be audible over background noise, leading to vocal strain and diminished job satisfaction.</p>
                                                <p>To address these concerns, we provide an array of acoustic solutions specifically tailored for educational institutions. Our products are designed to mitigate background noise, enhance speech intelligibility, and foster a more comfortable and productive learning environment.</p>
                                                <p>When acoustically treating an educational setup, several specifications must be taken into consideration. These include the level of ambient noise, the reverberation time of the room, and the speech transmission index (STI). Our team of experts can evaluate these factors and recommend optimal solutions to meet your requirements.</p>
                                                <p>At Moov Group we standardise acoustic solutions for educational institutions by adhering to international standard such as BB93 standard, which is globally recognised for acoustic design in educational institutions.</p>
                                                <p>In addition to our standard products, we also provide bespoke solutions customized to the distinct needs of each educational institution. Whether you are constructing a new school or renovating an existing one, Moov Group can assist you in creating an acoustically optimized learning environment.</p>
                                                <p>Imagine a lecture hall where every word resonates crystal clear with our crafted acoustic solutions because learning deserves the perfect stage.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6 order-md-2">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image6"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">06.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Home Cinema Acoustics</h5>
                                                <p>“Whether it’s a blockbuster or a quiet Indie film Moov’s acoustics set the stage for magic.”</p>
                                                <p>Good acoustics are essential in any home cinema, allowing sound to travel naturally in the room and enabling the audience to fully experience the music, plays, or other performances. Welcome to explore the ultimate cinematic experience at your very own home theatre.</p>
                                                <p>As a leader in the industry, Moov Group is dedicated to creating unforgettable entertainment experiences through their expertise in home cinema acoustics. Their team of experts provides acoustical design consultation for home cinemas, working closely with clients to deliver captivating performing arts events, movies, and multi-media presentations from the cinema screen.</p>
                                                <p>At Moov Group we’ve fine-tuned acoustics to turn movie nights into blockbuster experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image7"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">07.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Entertainment Acoustics (CINEMAS)</h5>
                                                <p>Experience the magic of theatre like never before with Moov Group! As a key player in creating memorable entertainment experiences, we offer our expertise in theatre acoustics. Our team consults on acoustical design for theatres and cinemas, helping to deliver captivating performing arts events, movies, and multimedia presentations from the cinema screen and theatre stage. Good acoustics are essential in any theatre or performance space, allowing sound to travel naturally for long distances and enabling the audience to fully experience the music, plays, or other performances, even if they are sitting far away from the stage. However, buildings with poor acoustics can hinder the sound from traveling effectively from one side of the space to another.</p>
                                                <p>At Moov Group, we specialize in providing soundproof insulation and acoustic solutions for entertainment venues. Our range of products, including PET Acoustic Panels, are suitable for use in auditoriums, recording studios, home theatres, hotels and resorts. We have worked on a number of high-profile projects, including Ferrari World in Abu Dhabi, Orbi Theatre in Dubai, Vox Cinemas, Novo Cinemas at several locations in UAE, Egypt and Oman, Sports Complex in Fujairah, Sky News in Abu Dhabi, Motion Gate, Multipurpose Theatre and Play house, Al Ahli Stadium, Star Cinema and many more. Our expertise and experience make us a leading provider of entertainment acoustics solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6 order-md-2">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image8"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">08.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Cinema & Auditorium Seating Solution</h5>
                                                <p>When it comes to creating a memorable experience in cinemas, theatres and auditoriums, the right seating is more than just a place to rest- Its an integral part of the show.</p>
                                                <p>Experience with Moov Group’s auditorium and theater seating solutions that elevate both comfort and aesthetics. Our expertly designed seating options provide audiences with an unparalleled viewing experience.</p>
                                                <p>Our wide range of seating options includes fixed seating solutions for auditoriums, conference halls, theaters, and multi-purpose areas. Crafted from high-quality materials and designed with aesthetics in mind, our seating products offer the perfect combination of comfort and style to leave an everlasting impression in the minds of audience.</p>
                                                <p>In addition to our standard seating products, we also offer custom solutions tailored to the unique needs of each venue. Our team of experts will work with you to assess your seating requirements and recommend the best solutions to meet your acoustic requirements. Whether you are building a new theatre or refurbishing an existing auditorium, Moov Group can help you create the perfect seating arrangement.</p>
                                                <p>Contact us today to learn more about our auditorium and theatre seating solutions and discover how we can help enhance the audience experience at your venue.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image9"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">09.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Hospitality Acoustics</h5>
                                                <p>In the world of hospitality, acoustics play a silent but powerful role. From luxurious hotels to bustling restuarants, the right sound environment can make or break an experience. At Moov Group, we understand the importance of acoustics in the hospitality industry. Whether it’s a restaurant, bar, cafe, hotel, or restobars, the ability to communicate clearly and enjoy a pleasant sensory experience is crucial to customer satisfaction and retention.</p>
                                                <p>Our team of experts specializes in soundproof insulation and acoustic solutions for a wide range of applications. We offer a variety of products and services to help improve the acoustics of your hospitality space, including acoustic wall panels, stretched fabric wall systems, thick fabric curtains, carpeting, and fabric seating.</p>
                                                <p>While designing an acoustic treatment for your hospitality center, we take into account several factors such as the size of the space, acoustic products used in construction, the location of the space relative to potential noise sources, and the use of sound-absorbing materials. Our goal is to create a balance between sound-reflecting and sound-absorbing materials to reduce echo and improve overall acoustics.</p>
                                                <p>With our extensive experience and expertise in the field of acoustics, you can trust Moov Group to provide top-quality solutions for your hospitality space. Contact us today to learn more about how we can help harmonizing the acoustics of your venue.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6 order-md-2">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image10"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">10.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Commercial Acoustics</h5>
                                                <p>Welcome to the world of commercial acoustics where tranquility and productivity go hand in hand. We, Moov Group, being at the forefront of the architectural acoustic product supply and contracting industry in the Middle East. We offer a wide range of acoustic products for sound proofing Industries, office spaces etc. Our activities are based on mutual trust and flexible cooperation with our customers at every stage of the work. With our high quality and reliable services, we have consistently been able to meet our customers’ needs and establish long-term relationships with clients from all over the world.</p>
                                                <p>Our commercial acoustics solutions are designed to meet the requirements of businesses and organizations that require effective noise control and sound absorption. Our team of experts works closely with architects, developers, general contractors, and end clients to develop technically feasible and acceptable solutions. By combining our core competencies of acoustic absorption, sound blocking, sound masking, and acoustic consulting, we are able to deliver quality acoustic solutions on our projects.</p>
                                                <p>When undertaking a commercial acoustic project with us at Moov Group, there are several key points to focus on. These include reverberation analysis, location, sound absorption, sound blocking, sound masking, and acoustic consulting. Our team of experts will guide you through every step of the process to ensure that your commercial space achieves effective noise control and sound absorption.</p>
                                                <p>At Moov Group, we are committed to providing our clients with the highest quality commercial acoustic solutions. Contact us today to learn more about how we can help you achieve your commercial acoustics goals.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image11 mt-4"></span>
                                                <span className="sector-image16"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">11.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Acoustic Wall & Floors</h5>
                                                <p>Moov group: The sound solution for your space.</p>
                                                <p>Are you looking for a sound solution that can transform your space into a comfortable and enjoyable environment? Whether you need to design a home theater, a concert hall, a cinema, or any other entertainment venue, you need to consider the acoustics of your space.</p>
                                                <p>Acoustics is the art and science of sound design. It involves optimizing the sound system, the room layout, the materials, and the noise isolation to achieve the best possible sound experience for your audience. Acoustics can affect the mood, the comfort, the productivity, and the satisfaction of your space.</p>
                                                <p>At Moov group, we are experts in acoustics and visual entertainment solutions. We have the knowledge, the experience, and the technology to create immersive and realistic sound environments for your projects. We can help you with:</p>
                                                <ul style={{ textAlign: "justify" }}>
                                                    <li>Acoustic flooring: We can provide you with flooring that prevents the transmission of sound from an upper floor to a lower floor, such as footsteps, furniture movement, or impact noise. We use various materials or a combination of layers that provide different levels of sound insulation and damping.</li>
                                                    <li>Acoustic panels: We can install panels on your walls or ceilings that absorb sound waves and reduce reverberation and echo in your room. We use materials like foam, fabric, wood, metal, or mineral wool, and we can customize them to suit your aesthetic and functional needs.</li>
                                                    <li>Acoustic finishes: We can apply finishes to your walls, ceilings, or floors that enhance the acoustic performance of your space. We use materials like plaster, paint, wallpaper, tiles, or wood that have special properties or textures that help diffuse or absorb sound waves. We can also integrate them with other elements like lighting, furniture, or plants to create a pleasant and comfortable environment.</li>
                                                </ul>
                                                <p>We are committed to delivering high-quality and cost-effective solutions that meet your expectations and budget. We work with you from the initial consultation to the final installation and testing to ensure your satisfaction.</p>
                                                <p>If you are interested in our services, please contact us today for a free quote. We would love to hear from you and help you with your acoustic needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-item portfolio-style-four">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6 col-md-6 order-md-2">
                                            <div className="portfolio-thumb">
                                                <span className="sector-image12"></span>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc">
                                                <span className="count">12.</span>
                                                <span className="line"></span>
                                                <h5 className="title1">Industrial Noise and Vibration Control</h5>
                                                <p>Industrial noise and vibration present significant challenges in many working environments. In settings such as manufacturing factories, petroleum and chemical plants, quarries, and raw-material extraction facilities, noise levels frequently exceed acceptable thresholds. Excessive noise and vibration can have an adverse impact on worker productivity, safety, and well-being.</p>
                                                <p>At Moov Group, we understand these challenges and provides an array of acoustic solutions specifically tailored for industrial applications. Our products are designed to mitigate noise levels, control vibrations, and foster a safer and more comfortable working environment.</p>
                                                <p>Our team of experts can evaluate the level of noise and vibration in your industrial setting and recommend optimal solutions to meet your requirements. We offer a range of products, including soundproof insulation, acoustic panels, and vibration control measures.</p>
                                                <p>Every industrial space has its own rhythm. That’s why we tailor our solutions. Contact us today to explore more about industrial noise solutions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default sector;