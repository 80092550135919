import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './acoustic-doors.css';
function doors() {
  return (
    
    <>
    <div className="boxed_wrapper" id="acoustic-doors">
    <Helmet>
        <title>Studio Acoustic 3D Soundproofing Interior Doors</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Studio 3D™ Soundproof Interior Doors" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/acoustic-doors" />
        <link rel="alternate" href="https://www.moovgroup.com/acoustic-doors" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section doors-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link active" >Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#acoustic-doors" className="nav-link" role="tab" data-toggle="tab" aria-selected="true">Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
            
            <div role="tabpanel" className="tab-pane fade active show" id="acoustic-doors">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">10. </span>
                    <h1 className="title1">Acoustic Doors</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product10"></div>
                        </div>
                        <div className="top-text-box">
                            <h1><span className="fabriwall"> </span>Studio 3D™ Soundproof Interior Doors</h1>
                            <p>Moov Group is proud to offer the markets first affordable soundproof interior door. Our recording studio doors have an STC value up to 56. The Studio 3D soundproof doors are ideal for recording studios, offices, band rooms, hotels, dorm rooms, conference rooms or any application where a soundproof interior door is required. The soundproofing doors come standard in oak, ready to stain with silver hardware. Custom finishes and hardware options are available for this door sound insulation product.</p>
                        </div>
                    </div>
                    
				    <div className="blog-single-bottom-content-box">
				    <h2>Features </h2>
                        <div className="row">
                            <div className="col-xl-6">
                                <ul>
                                <li>Low Cost – High Performance</li>
                                <li>STC Ratings Up To 56</li>
                                <li>Products Tested per ASTM E-90</li>
								<li>Custom Manufactured Acoustic Door to Any Size Specification</li>
								<li>Appx. 300 lb Door Assembly</li>
								<li>2¾″ 13 Layer Door Assembly</li>
								<li>Comes Pre-Hung and Assembled</li>
								<li>Adjustable Soundproof Jambs & Seals</li>
								<li>Ready To Stain/Paint to Match Your Decor</li>
								<li>Best Performance Wood Finished Door Assembly Available</li>
								<li>Automatic Sill</li>
								<li>Heavy Duty Hinges (4)</li>
								<li>3-Point Latching System</li>
                                </ul>
                            </div>
                        </div>
                    </div>
				    
                    <div className="blog-single-bottom-content-box">
                        <h2>Type of Doors </h2>
                        <div className="row">
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub1"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>2¾″ Door Thickness</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub2"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Mortise Drop Seal & Strike Plate</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub3"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Exposed Jamb Kit</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub4"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Wood Caps Over Jamb Kit</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub5"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Window Lite Kit</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub6"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Smaller Window Kit</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub7"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Double Door</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub8"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Double Door</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub9"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Left Hand Swing</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub10"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Right Hand Swing</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub11"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Left Hand Active – Double Door</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product10-sub12"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <h3>Right Hand Active – Double Door</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
				    </div>
                    
                </div>
				<span className="line-footer"></span>
				<div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/soundproof-interior-doors" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
       
        </div>
        
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default doors;