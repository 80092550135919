import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './sectors.css';
import Carousel from '../carousel';
function education() {
  return (
    
    <>
    <div className="boxed_wrapper" id="sector">
    <Helmet>
        <title>Education Acoustics and Soundproofing - Noise Control Solutions</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Education Acoustics and Soundproofing - Noise Control Solutions" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/education-acoustics" />
        <link rel="alternate" href="https://www.moovgroup.com/education-acoustics" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section sector-bg">
        <div className="container">
            <span className="page-tag">BUSINESS</span>
            <h1 className="page-title">SECTORS</h1>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Sectors</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/architectural-acoustics" className="nav-link">Architectural Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/auditorium-acoustics" className="nav-link">Auditorium Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/themepark-acoustics" className="nav-link">Theme Park Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/studio-acoustics" className="nav-link">Studio Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/education-acoustics" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true" >Education Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/homecinema-acoustics" className="nav-link">Home Cinema Acoustics</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/entertainment-acoustics" className="nav-link">Entertainment Acoustics</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/seating-solutions" className="nav-link">Seating Solutions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/hospitality-acoustics" className="nav-link">Hospitality Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/commercial-acoustics" className="nav-link">Commercial Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-wall" className="nav-link">Acoustic Wall and Floors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/industrial-acoustics" className="nav-link">Industrial Acoustics</Link>
                                </li>
							</ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
                <div role="tabpanel" className="tab-pane fade active show" id="eco-therm">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">05. </span>
                        <h1 className="title1">EDUCATION ACOUSTICS</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <span className="sector-education"></span>
                            </div>
                            <div className="top-text-box">
                                    <h2 className="title">EDUCATION ACOUSTICS</h2>
                                    <p>At Moov Group, we recognize the critical role that acoustics play in educational institutions. A well-designed acoustic environment can significantly enhance the learning experience for students and facilitate a more conducive teaching atmosphere for educators.</p>
                                    <p>Research has demonstrated that suboptimal acoustics in classNamerooms can have a detrimental impact on students’ comprehension of speech, resulting in diminished academic performance and elevated stress levels. This is particularly relevant for students with hearing impairments or language processing difficulties. Educators are also adversely affected by poor acoustics, as they are compelled to exert their voices to be audible over background noise, leading to vocal strain and diminished job satisfaction.</p>
                                    <p>To address these concerns, Moov Group provides an array of acoustic solutions specifically tailored for educational institutions. Our products are designed to mitigate background noise, enhance speech intelligibility, and foster a more comfortable and productive learning environment.</p>
                                    <p>When acoustically treating an educational setup, several specifications must be taken into consideration. These include the level of ambient noise, the reverberation time of the room, and the speech transmission index (STI). Our team of experts can evaluate these factors and recommend optimal solutions to meet your requirements.</p>
                                    <p>In addition to our standard products, we also provide bespoke solutions customized to the distinct needs of each educational institution. Whether you are constructing a new school or renovating an existing one, Moov Group can assist you in creating an acoustically optimized learning environment.</p>
                                    <p>Contact us today to discover more about our education acoustics solutions and how we can help augment the learning experience at your institution.</p>
                            </div>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/sectors" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                        </div>
                    </div>
                    
                </div>
                
            </div>
	    </div>
    </div>
    </section>
        
    <section className="recently-project-style2-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="pro-title">Our Projects</div>
                    <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                </div>
                <Carousel />
            </div>
        </section>
        </div>
    </div>
<Footer />
</>
  );
}

export default education;