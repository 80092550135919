import React from "react";
import {Helmet} from "react-helmet";
import Header from '../header';
import Footer from '../footer';
import './fabriwall.css';
import { Link } from "react-router-dom";
function fabriwall() {
  return (
    
    <>
    <div className="boxed_wrapper" id="fabriwall">
    <Helmet>
        <title>Fabriwall Acoustical Wall and Ceiling Acoustic panels</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Fabriwall Acoustical Wall and Ceiling Acoustic panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/fabriwall" />
        <link rel="alternate" href="https://www.moovgroup.com/fabriwall" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section fabriwall-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><a href="/#">Moov Group</a></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" href="fabrisorb">Fabrisorb</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="fabrisorb-deco">Fabrisorb-DECO</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#fabriwall" role="tab" data-toggle="tab" aria-selected="true">Fabriwall</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="decopanel" >D'ecopanel</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="woodtec">WoodTec</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="echo-eliminator">Echo Eliminator</a>
                                </li>
								<li className="nav-item">
								    <a className="nav-link" href="vinyl-barrier">Noise STOP Vinyl Barrier</a>
								</li>
                                <li className="nav-item">
                                    <a className="nav-link" href="eco-therm" >Eco Therm</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="deco-wood" >Deco Wood</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="acoustic-doors">Acoustic Doors</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="pu-foam">Foam Stop Polyurethane</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="diffuso">Diffuso</a>
                                </li>
								<li className="nav-item">
								    <a className="nav-link" href="polymax">Polymax</a>
								</li>
								<li className="nav-item">
								    <a className="nav-link" href="polysorb" >Polysorb</a>
								</li>
								<li className="nav-item">
								    <a className="nav-link" href="polysorb-deco">Polysorb-DECO</a>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
            
            <div role="tabpanel" className="tab-pane fade active show" id="fabriwall">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">03. </span>
                    <h1 className="title1">FabriWALL</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product3"></div>
                        </div>
                        <div className="top-text-box">
                            <p>Fabriwall acoustical panel is a versatile, sustainable, and innovative track solution that empowers architects and designers to meet their desired specifications and criteria for any project. The track system comprises a framework, with the infill placed between it. The fabric is then stretched into the interlocking jaws of the track, providing the entire system with a seamless finish.</p>
                         
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
							    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Offices</li>
                                            <li>Schools</li>
                                            <li>Meeting Rooms</li>
										    <li>Hotels</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Auditorium</li>
                                            <li>Recording Studios</li>
										    <li>Conference halls</li>
                                            <li>Broadcasting Studios</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                
						    <div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Aesthetically pleasing</li>
                                            <li>High sound absorption performance</li>
                                            <li>Available with various edges and colours</li>
											<li>Fire resistant</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Environmentally friendly</li>
                                            <li>Humidity resistance</li>
						                    <li>Easily cleanable and can be vacuumed</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
							
                            <div className="blog-single-bottom-content-box">
                                <h2>Categories </h2>
                                <div className="row">
                             
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product3-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/fabriwall-track"><h3>FabriWALL TRACK</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>FabriWALL TRACK</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>The fabric is stretched over the semi rigid absorption panel to control sound</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/fabriwall-track"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product3-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/fabriwall-fabric"><h3>FabriWALL FABRIC</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>FabriWALL FABRIC</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Acoustical stretch fabric systems are in high demand in office spaces, lobbies, classNamerooms, theaters and more</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/fabriwall-fabric"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product3-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/fabriwall-panel"><h3>FabriWALL PANEL</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>FabriWALL PANEL</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>PANEL is the unseen element of the system installed inside Track's frame and fabric</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/fabriwall-panel"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
					        </div>
				        </div>
                       
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/fabriwall" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
         
            </div>
 
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default fabriwall;
    