import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './polysorb-deco.css';
function polydeco() {
  return (
    
    <>
    <div className="boxed_wrapper" id="polysorb-deco">
    <Helmet>
        <title>Polysorb DECO - 100% polyester (60% PET-recycled fiber, 40% PET-virgin fiber)</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Polysorb-DECO Fabric Wrapped Acoustical Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/polysorb-deco" />
        <link rel="alternate" href="https://www.moovgroup.com/polysorb-deco" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section polydeco-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link">WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link">Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link">Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link">Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link">Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link">Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="#polysorb-deco" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
               
            <div role="tabpanel" className="tab-pane fade active show" id="polysorb-deco">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">15. </span>
                    <h1 className="title1">Polysorb-DECO</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <span className="product15"></span>
                        </div>
                        <div className="top-text-box">
                            <p>Polysorb acoustic panels create a great visual design statement whilst improvingthe acoustic response of your space along with a rich and luxurious feel. The uniqueness of our panels are that they are light weight, eco-friendly, sustainable polyester fibers which are made from 100% polyester (60% PET-recycled fiber, 40% PET-virgin fiber). </p> 
							<p>POLYSORB range of panels made from our Polymax polyester acoustical panels are non-allergenic, non-toxic, fungal-resistant, and do not contain chemical irritants or formaldehyde. Final finish and colour can be bespoken to  meet any design challenge as we have a huge range of fabric choice to create stunning results envisaged by the architect / designer.</p>
							
							<div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>High absorption values</li>
                                        <li>Available in various thickness and density as options</li>
                                        <li>High resistance to humidity</li>
										<li>Non-allergenic, non-toxic, fungal-resitant</li>
									</ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Does not contain chemical irritants or formaldehyde</li>
										<li>Can contribute to LEED V4.1 EQ Credit: Low-Emitting Materials</li>
                                        <li>Light weight and can be mounted easily</li>
                                        <li>Easily cleanable</li>
									</ul>
                                    </div>
                                </div>
                            </div>
							
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                            <h2>Categories </h2>
                            <div className="row">
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product15-sub1"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Wall Panels</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>01</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Acoustical<br /> Wall Panels</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Our POLYSORB-DECO wall panels are very easy to install, very decorative and can be glued or mounted to any surface.</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product15-sub2"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Ceiling Clouds</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>02</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br />Ceiling Clouds</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>POLYSORB clouds are the right choice to use as clouds where there is generally a tight space requirement due to the low ceiling height</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product15-sub3"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Ceiling Baffles</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>03</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br /> Ceiling Baffles</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>POLYSORB-DECO panels are light weight and can be easily hung as baffles or raft ceiling. Due to its strong and rigid edges give the baffles highly appealing and luxurious feel once installed</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
					    </div>
				    </div>
                   
                </div>
            </div>
            <span className="line-footer"></span>
            <div className="portfolio-container2">
                <a href="polysorb-deco" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">Polysorb DECO <span className="icon">+</span></a>
            </div>
        </div>
        
        </div>
        
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default polydeco;