import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './decopanel.css';
function decogrooved() {
  return (
    
    <>
    <div className="boxed_wrapper" id="decopanel">
    <Helmet>
        <title>Decopanel - Grooved Design PET Acoustic Panels</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Decopanel Grooved Design Acoustic Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/decopanel-grooved-design" />
        <link rel="alternate" href="https://www.moovgroup.com/decopanel-grooved-design" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section decopanel-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><a href="/#">Moov Group</a></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="section-gap">            
        <div className="container">
            <div className="row">
                
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                    <div className="sidebar-wrapper">
                       
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Categories</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/decopanel-plain-colors" className="nav-link" >D'ecopanel Plain Colors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel-grooved-design" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">D'ecopanel Grooved Design</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel-print-design" className="nav-link" >D'ecopanel Print Design</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel-tiles" className="nav-link" >D'ecopanel Tiles</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel-plain-print-rolls" className="nav-link" >D'ecopanel Plain Print Rolls</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel-baffles-clouds" className="nav-link" >Decopanel Baffles Clouds</Link>
                                </li>
							</ul>
                        </div>
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane Foam</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                       
                    </div>
                </div>
                
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">02. </span>
                        <h1 className="title1">D'ecopanel - Baffles/Clouds</h1>
                    </div>
                    
                    <div className="row">
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="deco-sub2"></div>
                                </div>
                                <div className="blog-single-bottom-content-box">
                                    <h2>D'ecopanel - GROOVED DESIGN</h2>
                                    <br />
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <ul>
                                                <li>Aesthetically Pleasing</li>
                                                <li>Available in many vibrant colours</li>
                                                <li>Eco Friendly & Easily installable</li>
												<li>Light weight and flexible</li>
												<li>Can be used as Pin boards</li>
                                             </ul>
                                         </div>
                                    </div>
					            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="faq-content-box">
                            <div className="accordion-box">
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>Specification</h4>
                                    </div>
                                    <div className="accord-content collapsed">
									    <table className="table">
										    <tbody>
											<tr>
											<th scope="row">Core</th>
												<td>100% PET</td>
											</tr>
											<tr>
											<th scope="row">Thickness (mm)</th>
												<td> 10mm</td>
											</tr>
											<tr>
											<th scope="row">Sizes (mm)</th>
												<td>1220mm x 2440mm</td>
											</tr>
											<tr>
											<th scope="row">Acoustic Properties</th>
												<td>Up to 0.9 NRC</td>
											</tr>
											<tr>
											<th scope="row">Colours</th>
												<td>Available in different colours</td>
											</tr>
											<tr>
											<th scope="row">Flamability</th>
												<td>B-s1, d0 as per EN-13501</td>
											</tr>
											<tr>
											<th scope="row">Mounting Type</th>
												<td>Type A mounting , E-100 & E-50</td>
											</tr>
											<tr>
											<th scope="row">Installation</th>
												<td>Adhesive or frame work depending on mounting</td>
											</tr>
										    </tbody>
									    </table>
                                    </div>
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>Reverberation</h4>
                                    </div>
                                    <div className="accord-content collapsed">
                                        <table className="table">
										    <thead>
											<tr>
												<th scope="col">Thickness</th>
												<th scope="col">125Hz</th>
												<th scope="col">250Hz</th>
												<th scope="col">500Hz</th>
												<th scope="col">1KHz</th>
												<th scope="col">2KHz</th>
												<th scope="col">4KHz</th>
												<th scope="col">NRC</th>
											</tr>
										    </thead>
										    <tbody>
											<tr>
											<th scope="row">10mm</th>
												<td>0.23</td>
												<td>0.71</td> 
												<td>0.94</td>
												<td>0.98</td>
												<td>0.87</td>
												<td>0.87</td>
												<td>0.90</td>
											</tr>
										    </tbody>
									    </table>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div> 
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <a href="https://www.acousticalsurfaces.in/decopanel" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default decogrooved;