import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './sectors.css';
import Carousel from '../carousel';
function acousticwall() {
  return (
    
    <>
    <div className="boxed_wrapper" id="sector">
    <Helmet>
        <title>Acoustic Wall and Floors Soundproofing Solutions</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Acoustic Wall and Floors Soundproofing Solutions" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/acoustic-wall" />
        <link rel="alternate" href="https://www.moovgroup.com/acoustic-wall" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section sector-bg">
        <div className="container">
            <span className="page-tag">BUSINESS</span>
            <h1 className="page-title">SECTORS</h1>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Sectors</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/architectural-acoustics" className="nav-link" >Architectural Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/auditorium-acoustics" className="nav-link" >Auditorium Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/themepark-acoustics" className="nav-link">Theme Park Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/studio-acoustics" className="nav-link"  >Studio Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/education-acoustics" className="nav-link">Education Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/homecinema-acoustics" className="nav-link" >Home Cinema Acoustics</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/entertainment-acoustics" className="nav-link" >Entertainment Acoustics</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/seating-solutions" className="nav-link" >Seating Solutions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/hospitality-acoustics" className="nav-link" >Hospitality Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/commercial-acoustics" className="nav-link" >Commercial Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-wall" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Acoustic Wall and Floors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/industrial-acoustics" className="nav-link" >Industrial Acoustics</Link>
                                </li>
							</ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
                <div role="tabpanel" className="tab-pane fade active show" id="eco-therm">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">11. </span>
                        <h1 className="title1">ACOUSTIC WALL & FLOORS</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <span className="sector-acousticwall"></span>
                            </div>
                            <div className="top-text-box">
                                    <h2 className="title">ACOUSTIC WALL & FLOORS</h2>
                                    <p>Moov group: The sound solution for your space.</p>
                                    <p>Are you looking for a sound solution that can transform your space into a comfortable and enjoyable environment? Whether you need to design a home theater, a concert hall, a cinema, or any other entertainment venue, you need to consider the acoustics of your space.</p>
                                    <p>Acoustics is the art and science of sound design. It involves optimizing the sound system, the room layout, the materials, and the noise isolation to achieve the best possible sound experience for your audience. Acoustics can affect the mood, the comfort, the productivity, and the satisfaction of your space.</p>
                                    <p>At Moov group, we are experts in acoustics and visual entertainment solutions. We have the knowledge, the experience, and the technology to create immersive and realistic sound environments for your projects. We can help you with:</p>
                                    <p>Acoustic flooring: We can provide you with flooring that prevents the transmission of sound from an upper floor to a lower floor, such as footsteps, furniture movement, or impact noise. We use materials like rubber, cork, vinyl, or carpet, or a combination of layers that provide different levels of sound insulation and damping.</p>
                                    <p>Acoustic panels: We can install panels on your walls or ceilings that absorb sound waves and reduce reverberation and echo in your room. We use materials like foam, fabric, wood, metal, or mineral wool, and we can customize them to suit your aesthetic and functional needs.</p>
                                    <p>Acoustic finishes: We can apply finishes to your walls, ceilings, or floors that enhance the acoustic performance of your space. We use materials like plaster, paint, wallpaper, tiles, or wood that have special properties or textures that help diffuse or absorb sound waves. We can also integrate them with other elements like lighting, furniture, or plants to create a pleasant and comfortable environment.</p>
                                    <p>We are committed to delivering high-quality and cost-effective solutions that meet your expectations and budget. We work with you from the initial consultation to the final installation and testing to ensure your satisfaction.</p>
                                    <p>If you are interested in our services, please contact us today for a free quote. We would love to hear from you and help you with your acoustic needs.</p>
                            </div>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/sectors" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                        </div>
                    </div>
                    
                </div>
                
            </div>
	    </div>
    </div>
    </section>
    
        <section className="recently-project-style2-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="pro-title">Our Projects</div>
                    <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                </div>
                <Carousel />
            </div>
        </section>
        </div>
    </div>
<Footer />
</>
  );
}

export default acousticwall;