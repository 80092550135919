import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from '../header';
import Footer from '../footer';
import Map from '../../images/map.webp';
import './contact.css';
function Contact() {
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        selectmenu: '',
        email: '',
        phone: '',
        message: '',
    });
    const [secondFormData, setSecondFormData] = useState({
        fname: '',
        lname: '',
        selectmenu: '',
        email: '',
        phone: '',
        message: '',
    });
    const [fnameError, setFnameError] = useState('');
    const [lnameError, setLnameError] = useState('');
    const [fnameError2, setFnameError2] = useState('');
    const [lnameError2, setLnameError2] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [phoneError2, setPhoneError2] = useState('');

    const validateName = (name) => {
        const regex = /^[A-Za-z\s]*$/;
        return regex.test(name);
    };
    const validatePhone = (phone) => {
        const regex = /^\d+$/;
        return regex.test(phone);
    };

    const handleFirstNameChange = (e) => {
        const newName = e.target.value;
        if (!validateName(newName)) {
            setFnameError('First name should contain only letters.');
        } else {
            setFnameError('');
        }
        setFormData({ ...formData, fname: newName });
    }
    const handleLastNameChange = (e) => {
        const newName = e.target.value;
        if (!validateName(newName)) {
            setLnameError('Last name should contain only letters.');
        } else {
            setLnameError('');
        }
        setFormData({ ...formData, lname: newName });
    }
    const handlePhoneChange = (e) => {
        const newPhone = e.target.value;
        if (!validatePhone(newPhone)) {
            setPhoneError('Phone number should contain only numbers.');
        } else {
            setPhoneError('');
        }
        setFormData({ ...formData, phone: newPhone });
    };

    const handleFirstNameChange2 = (e) => {
        const newName = e.target.value;
        if (!validateName(newName)) {
            setFnameError2('First name should contain only letters.');
        } else {
            setFnameError2('');
        }
        setSecondFormData({ ...secondFormData, fname: newName });
    }
    const handleLastNameChange2 = (e) => {
        const newName = e.target.value;
        if (!validateName(newName)) {
            setLnameError2('Last name should contain only letters.');
        } else {
            setLnameError2('');
        }
        setSecondFormData({ ...secondFormData, lname: newName })
    }
    const handlePhoneChange2 = (e) => {
        const newPhone = e.target.value;
        if (!validatePhone(newPhone)) {
            setPhoneError2('Phone number should contain only numbers.');
        } else {
            setPhoneError2('');
        }
        setSecondFormData({ ...secondFormData, phone: newPhone });
    };
    const handleFormSubmit1 = (e) => {
        e.preventDefault();
        const { fname, lname, selectmenu, email, phone, message } = formData;
        let isValid = true;
        if (!validateName(fname)) {
            setFnameError('First name should contain only letters.');
            isValid = false;
        } else {
            setFnameError('');
        }

        if (!validateName(lname)) {
            setLnameError('Last name should contain only letters.');
            isValid = false;
        } else {
            setLnameError('');
        }

        if (!validatePhone(phone)) {
            setPhoneError('Phone number should contain only numbers.');
            isValid = false;
        } else {
            setPhoneError('');
        }
        if (isValid) {
            const subject = encodeURIComponent('Moov-group Contact Form Submission');
            const body = encodeURIComponent(`${message}\n\nContact Details:\nName: ${fname} ${lname}\nEmail: ${email}\nPhone: ${phone}\nEnquire About: ${selectmenu}`);
            const mailtoLink = `mailto:mail@moovgroup.com?subject=${subject}&body=${body}`;
            window.open(mailtoLink);
            setFormData({
                fname: '',
                lname: '',
                selectmenu: '',
                email: '',
                phone: '',
                message: '',
            });
        }
    }

    const handleFormSubmit2 = (e) => {
        e.preventDefault();
        const { fname, lname, selectmenu, email, phone, message } = secondFormData;
        let isValid = true;

        if (!validateName(fname)) {
            setFnameError2('First name should contain only letters.');
            isValid = false;
        } else {
            setFnameError2('');
        }

        if (!validateName(lname)) {
            setLnameError2('Last name should contain only letters.');
            isValid = false;
        } else {
            setLnameError2('');
        }

        if (!validatePhone(phone)) {
            setPhoneError2('Phone number should contain only numbers.');
            isValid = false;
        } else {
            setPhoneError2('');
        }

        if (isValid) {
            const mailtoLink = `mailto:mail@moov.ae?subject=Moov-group%20Contact%20Form%20Submission&body=${encodeURIComponent(`${message}\n\nContact Details:\nName: ${fname} ${lname}\nEmail: ${email}\nPhone: ${phone}\nEnquire About: ${selectmenu}`)}`;
            window.location.href = mailtoLink;
            setSecondFormData({
                fname: '',
                lname: '',
                selectmenu: '',
                email: '',
                phone: '',
                message: '',
            });
        }
    };

    return (

        <>
            <div className="boxed_wrapper" id="contact">
                <Helmet>
                    <title>Get In Touch With Us - Moov Group</title>
                    <meta name="description" content=" " />
                    <meta property="og:title" content="Get In Touch With Us - Moov Group" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/contact" />
                    <link rel="alternate" href="https://www.moovgroup.com/contact" hreflang="en-GB" />
                </Helmet>
                <Header />
                <section className="breadcrumb-area style2 contact-page contact-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="inner-content-box clearfix">
                                    <div className="title-s2 text-center">
                                        <h1>Get In Touch With Us</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-address-area">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-contact-address-box">
                                    <span className="icon-global"></span>
                                    <h3>Visit Our Office</h3>
                                    <p>#B2002, Latifa Tower, Sheikh Zayed Road<br />P.O. Box 113239 | Dubai - U.A.E. </p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-contact-address-box">
                                    <span className="icon-support1"></span>
                                    <h3>Call Us</h3>
                                    <p>Tel: +971 4 331 0000<br />Tel: +971 2 550 0104</p>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="single-contact-address-box">
                                    <span className="icon-shipping-and-delivery"></span>
                                    <h3>Mail Us</h3>
                                    <p>mail@moovgroup.com<br /></p>
                                    <p>project@moovgroup.com</p>
                                </div>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="bottom-text text-center">
                                    <div className="content-info-box info-bg">
                                        <div className="con-inner">
                                            <div className="con-inner-top">
                                                <div className="con-inner-box wow fadeInDown animated animated" style={{ visibility: "visible", animationName: "fadeInDown" }}>
                                                    <div className="con-inner-box-top">             <span className="icon-global"></span>
                                                        <h3>Visit Our<br /> <span className="addcol">Dubai Head Office</span></h3>
                                                    </div>
                                                    <div className="con-inner-box-bot">
                                                        <p>MOOV GROUP<br />
                                                            Office No# B2002, Latifa Tower,<br />
                                                            Sheikh Zayed Road,<br />
                                                            P.O. Box 113239 | Dubai - U.A.E.<br /></p>
                                                    </div>
                                                </div>
                                                <div className="con-inner-box wow fadeInUp animated animated" style={{ visibility: "visible", animationName: "fadeInUp" }}>
                                                    <div className="con-inner-box-top">
                                                        <span className="icon-global"></span>
                                                        <h3>Visit Our<br /> <span className="addcol"> Abu Dhabi Office</span></h3>
                                                    </div>
                                                    <div className="con-inner-box-bot">
                                                        <p>MOOV CONSTRUCTION SOLUTIONS LLC<br />
                                                            Office # 5 | ICAD - Building 5<br />
                                                            P.O. Box 132852 | Abu Dhabi - U.A.E.<br /><br /></p>
                                                    </div>
                                                </div>
                                                <div className="con-inner-box wow fadeInDown animated animated" style={{ visibility: "visible", animationName: "fadeInDown" }}>
                                                    <div className="con-inner-box-top">
                                                        <span className="icon-global"></span>
                                                        <h3>Visit Our<br /><span className="addcol"> Deco Design Lab</span></h3>
                                                    </div>
                                                    <div className="con-inner-box-bot">
                                                        <p>MOOV GENERAL TRADING LLC<br />
                                                            W/H No. 7 | Dubai Industrial Park-1<br />
                                                            P.O. Box 113239 | Dubai - U.A.E.<br /><br /></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="con-inner-bot">
                                                <img src={Map} alt="Map" style={{ width: "700px" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-info-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12">
                                <div className="contact-form">
                                    <div className="sec-title with-text">
                                        <p>Drop Us a Line</p>
                                        <div className="title">Send Your <span>Message</span></div>
                                        <p className="bottom-text">If you have question or would like more information on our works, Please complete the form and we’ll aim get back to you with in 24 hours.</p>
                                    </div>
                                    <div className="inner-box">
                                        <form id="contact-form" name="contact_form" className="default-form" method="post" onSubmit={handleFormSubmit1}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <input type="text" id="form_first_name1" placeholder="First Name" value={formData.fname} onChange={handleFirstNameChange} required />
                                                        <div className="icon">
                                                            <i className="fa fa-user" aria-hidden="true"></i>
                                                        </div>
                                                        {fnameError && <span style={{ color: "red", fontSize: "12px", padding: "0px" }} className="error-message">{fnameError}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <input type="text" id="form_last_name1" placeholder="Last Name" value={formData.lname} onChange={handleLastNameChange} required />
                                                        <div className="icon">
                                                            <i className="fa fa-user" aria-hidden="true"></i>
                                                        </div>
                                                        {lnameError && <span style={{ color: "red", fontSize: "12px", padding: "0px" }} className="error-message">{lnameError}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <select className="selectmenu" value={formData.selectmenu} onChange={(e) => setFormData({ ...formData, selectmenu: e.target.value })} required>
                                                            <option selected="selected">Enquire About</option>
                                                            <option>Products Supply</option>
                                                            <option>Acoustic Treatment</option>
                                                            <option>Soundproofing Solutions</option>
                                                            <option>Project Execution</option>
                                                            <option>Home Theater Construction</option>
                                                        </select>
                                                        <div className="icon">
                                                            <i className="fa fa-question" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <input type="text" id="form_phone1" placeholder="Phone" value={formData.phone} onChange={handlePhoneChange} required />
                                                        <div className="icon">
                                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                                        </div>
                                                        {phoneError && <span style={{ color: "red", fontSize: "12px", padding: "0px" }} className="error-message">{phoneError}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="input-box">
                                                        <input type="email" id="form_email1" placeholder="Email Address" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} required />
                                                        <div className="icon">
                                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="input-box">
                                                        <textarea id="form_message1" placeholder="Your Message..." value={formData.message} onChange={(e) => setFormData({ ...formData, message: e.target.value })} required></textarea>
                                                        <div className="icon envelop">
                                                            <i className="fa fa-comment" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="button-box">
                                                        <input id="form_botcheck" name="form_botcheck1" className="form-control" type="hidden" value="" />
                                                        <button className="col-md-6 col-sm-6 btn-one" type="submit">
                                                            Send Your Message
                                                            <span className="flaticon-next"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-7 col-md-12 col-sm-12">
                                <div className="contact-form">
                                    <div className="sec-title with-text">
                                        <p>Drop Us a Line</p>
                                        <div className="title">Send Your <span>Message</span></div>
                                        <p className="bottom-text">If you have question or would like more information on our works, Please complete the form and we’ll aim get back to you with in 24 hours.</p>
                                    </div>
                                    <div className="inner-box">
                                        <form id="contact-form" name="contact_form" className="default-form" method="post" onSubmit={handleFormSubmit2}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <input type="text" id="form_first_name2" placeholder="First Name" value={secondFormData.fname} onChange={handleFirstNameChange2} required />
                                                        <div className="icon">
                                                            <i className="fa fa-user" aria-hidden="true"></i>
                                                        </div>
                                                        {fnameError2 && <span style={{ color: "red", fontSize: "12px", padding: "0px" }} className="error-message">{fnameError2}</span>}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <input type="text" id="form_last_name2" placeholder="Last Name" value={secondFormData.lname} onChange={handleLastNameChange2} required />
                                                        <div className="icon">
                                                            <i className="fa fa-user" aria-hidden="true"></i>
                                                        </div>
                                                        {lnameError2 && <span style={{ color: "red", fontSize: "12px", padding: "0px" }} className="error-message">{lnameError2}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <select className="selectmenu" value={secondFormData.selectmenu} onChange={(e) => setSecondFormData({ ...secondFormData, selectmenu: e.target.value })} required >
                                                            <option selected="selected">Enquire About</option>
                                                            <option>Construction</option>
                                                            <option>Acoustic Treatment</option>
                                                            <option>Soundproofing Solutions</option>
                                                            <option>Project Execution</option>
                                                            <option>Home Theater Construction</option>
                                                        </select>
                                                        <div className="icon">
                                                            <i className="fa fa-question" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-box">
                                                        <input type="text" id="form_phone2" placeholder="Phone" value={secondFormData.phone} onChange={handlePhoneChange2} required />
                                                        <div className="icon">
                                                            <i className="fa fa-phone" aria-hidden="true"></i>
                                                        </div>
                                                        {phoneError2 && <span style={{ color: "red", fontSize: "12px" }} className="error-message">{phoneError2}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="input-box">
                                                        <input type="email" id="form_email2" placeholder="Email Address" value={secondFormData.email} onChange={(e) => setSecondFormData({ ...secondFormData, email: e.target.value })} required />
                                                        <div className="icon">
                                                            <i className="fa fa-envelope" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="input-box">
                                                        <textarea id="form_message2" placeholder="Your Message..." value={secondFormData.message} onChange={(e) => setSecondFormData({ ...secondFormData, message: e.target.value })} required></textarea>
                                                        <div className="icon envelop">
                                                            <i className="fa fa-comment" aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="button-box">
                                                        <input id="form_botcheck" name="form_botcheck2" className="form-control" type="hidden" value="" />
                                                        <button className="col-md-6 col-sm-6 btn-one" type="submit">
                                                            Send Your Message
                                                            <span className="flaticon-next"></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="job-career-brochures-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-4 col-lg-6">
                                <div className="inner-box">
                                    <iframe title="moov dubai" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14437.803046597743!2d55.2811628!3d25.2217384!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5ccf92ba6a49%3A0x391f6adefe92826c!2sMoov%20Group!5e0!3m2!1sen!2sin!4v1692443130189!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0", padding: "6px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6">
                                <div className="inner-box">
                                    <iframe title="moov sharja" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14541.13094691572!2d54.5196231!3d24.3366419!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e40aafd02a9d7%3A0x100fa4a47254dca2!2sMoov%20Construction%20Solutions%20LLC!5e0!3m2!1sen!2sin!4v1692443062432!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0", padding: "6px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6">
                                <div className="inner-box">
                                    <iframe title="deco-lab" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3616.387317813261!2d55.16109087537495!3d24.98695177784699!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDU5JzEzLjAiTiA1NcKwMDknNDkuMiJF!5e0!3m2!1sen!2sin!4v1692607152550!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0", padding: "6px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    );
}

export default Contact;