import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Louvre from '../../images/Louvre-Abu-Dhabi-moov-group.webp';
import Nbad from '../../images/nbad.webp';
import Airport from '../../images/Dubai-Airport-Terminal-2-moov-group.webp';
import Voxsharjah from '../../images/Vox-Sharjah-moov-group.webp';
import Zayed from '../../images/Zayed-sports-complex-moov-group.webp';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects Vol.2</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects (vol.2)." />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects-v2" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects-v2" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <span className="page-tag">Recently</span>
                            <h1 className="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Louvre} alt="Louvre Abu Dhabi" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="louvre-img"></div>
                                                <div className="img-bot">
                                                    <div className="louvre-img2"></div>
                                                    <div className="louvre-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">25.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/louvre-acoustics">Louvre - Abu Dhabi</Link></h5>
                                                <p>Location: Abu Dhabi, UAE</p>
                                                <p>Client: TDIC</p>
                                                <p>Consultant: Jean Nouvel</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">Abu Dhabi’s Tourism Development & Investment Company (TDIC) has completed the installation of the dome structure in its upcoming attraction Louvre Abu Dhabi, Designed by architect Jean Nouvel. Louvre Abu Dhabi encompass 9,200 square metres of art galleries including a 6,681 sqm Permanent Gallery and a 2,364 sqm Temporary Gallery. Located in the Saadiyat Cultural District, it is one of the three main museums coming up on the island, with the other two including Zayed National Museum and Guggenheim Abu Dhabi.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">We have been instrumental in treating the Echo and optimizing the Reverberation Times by introducing more than 22,000SQM of acoustic wood wool.</p>
                                                <Link to="/louvre-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Nbad} alt="NBAD" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="nbad-img"></div>
                                                <div className="img-bot">
                                                    <div className="nbad-img2"></div>
                                                    <div className="nbad-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">24.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/national-bank-acoustics">National Bank of Abu Dhabi</Link></h5>
                                                <p>Location: Abu Dhabi, UAE</p>
                                                <p>Client: NBAD</p>
                                                <p>Consultant: WS Planning & Architecture</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The UAE’s second biggest bank by assets has a network of 83 branches across the UAE. Its overseas network stretches from Oman, Kuwait and Bahrain in the GCC to Egypt and Sudan in Africa, to London, Paris and Geneva in Europe and Washington DC in the US.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">We were appointed as the acoustic contractor for this job, where we have used recycled cellulose fiber core with designer fabric linings to keep the aesthetic appeal at the same time to reduce the Echo levels in the bank.</p>
                                                <Link to="/national-bank-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Airport} alt="Dubai Airport" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="dubaiairport-img"></div>
                                                <div className="img-bot">
                                                    <div className="dubaiairport-img2"></div>
                                                    <div className="dubaiairport-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">23.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/dubai-airport-acoustics">Dubai Airport, Terminal - 2</Link></h5>
                                                <p>Location:Dubai, UAE</p>
                                                <p>Client: Dubai Airport Authority</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The Dubai International Airport Terminal 2 Expansion project will involve approx. 30,000 m² of new build and 29,000 m² of refurbishment. Dubai International Airport Terminal 2 will be able to handle more than 10 million passengers per year.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Areas with heavy traffic such as the arrivals and departure areas were lined with fabric acoustic wall panels with absorbent core to reduce echo.</p>
                                                <Link to="/dubai-airport-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Voxsharjah} alt="Vox Sharjah" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="voxsharjah-img"></div>
                                                <div className="img-bot">
                                                    <div className="voxsharjah-img2"></div>
                                                    <div className="voxsharjah-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">22.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/vox-cinema-acoustics">VOX Cinemas</Link></h5>
                                                <p>Location: Ajman City Center</p>
                                                <p>Client: Majid Al Futtaim</p>
                                                <p>Consultant: Unick Architects Ltd</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">VOX Cinemas is the Middle East’s most innovative and customer-focused cinema experience. VOX Cinemas features high-resolution digital projection systems, immersive 3D content, expansive VOX MAX screens, Dolby Atmos and 7.1 multidimensional surround sound with tailored experiences such as IMAX with Laser, VOX THEATRE by Rhodes, VOX GOLD, VOX 4DX, VOX KIDS and value-oriented seating alternatives.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Acoustical Interiors and Fit outs for more than 25 screens in Dubai, Abu Dhabi, Ajman & Oman.</p>
                                                <Link to="/vox-cinema-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Zayed} alt="Sports Complex" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="sportscomplex-img"></div>
                                                <div className="img-bot">
                                                    <div className="sportscomplex-img2"></div>
                                                    <div className="sportscomplex-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">21.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/sports-complex-acoustics">Sports Complex, Fujairah</Link></h5>
                                                <p>Location: Fujairah</p>
                                                <p>Client: Ministry of Public Works</p>
                                                <p>Contractor: Constuction & Building Engineering (CBE)</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Hanging baffles and acoustic wall panels.<br />
                                                    Built Up Area: 227,000 sq ft</p>
                                                <Link to="/sports-complex-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-items">
                                    <div className="portfolio-item portfolio-style-four">
                                        <span className="line-footer"></span>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container1">
                                                    <Link to="/projects-v1" className="portfolio-previous">&laquo; Previous</Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container2">
                                                    <Link to="/projects-v3" className="portfolio-next">Next &raquo;</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;