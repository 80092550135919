import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Emirati from '../../images/emirati-school.webp';
import Voxcinemas from '../../images/voxcinemas-bahrain.webp';
import Museum from '../../images/museum-of-future-dubai-moov-group.webp';
import Beeah from '../../images/beeah.webp';
import Rashid from '../../images/rashid-library.webp';
import templepro1 from '../../images/moov-pro1.jpeg';
import gemspro1 from '../../images/gems1.jpeg';
import './projects.css';
function projects() {
    return (

        <>
            <div className="boxed_wrapper" id="project">
                <Helmet>
                    <title>Our Recently Completed Acoustic Projects</title>
                    <meta name="description" content="We are extremely fortunate to be part of these iconic projects." />
                    <meta property="og:title" content="Our Recently Completed Acoustic Projects" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/projects" />
                    <link rel="alternate" href="https://www.moovgroup.com/projects" hreflang="en-GB" />
                </Helmet>
                <Header />
                <div id="moov">
                    <section className="breadcrumb-section projects-bg">
                        <div className="container">
                            <span className="page-tag">Recently</span>
                            <h1 className="page-title">Completed Projects</h1>
                        </div>
                    </section>

                    <section className="portfolio-section section-gap" id="portfolio">
                        <div className="container">
                            <div className="portfolio-items">
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 col-md-6 mt-3">
                                            <div className="portfolio-thumb">
                                                <img src={templepro1} alt="temple project" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="temple-pro1"></div>
                                                <div className="img-bot">
                                                    <div className="temple-pro2"></div>
                                                    <div className="temple-pro3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="portfolio-desc" style={{ marginBottom: "10px" }}>
                                                <span className="count">37.</span>
                                                <span className="line" style={{ margin: "0px 0px 0px 0px" }}></span>
                                                <h5 className="title"><Link to="/emarati-school-acoustics">MANDIR, Abu Dhabi</Link></h5>
                                                <p>Location: Abu Dhabi</p>
                                                <p>Client: MANDIR</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content"> Built across to the shilpa shastras, the ancient Hindu treatises of mandir construction, the BAPS Hindu
                                                    Mandir in Abu Dhabi is the largest Hindu temple in West Asia and the first traditional hindu stone
                                                    mandir in the Middle East. The mandir stands at 108 feet in height, 262 feet in length, and 180 feet in
                                                    width.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Supply and Installation of FABRIWALL system and ACOUSTIC BAFFLES.</p>
                                                <Link to="/emarati-school-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={gemspro1} alt="temple project" />
                                            </div>

                                            <div className="gallery images">
                                                <div className="gems-pro1"></div>
                                                <div className="img-bot">
                                                    <div className="gems-pro2"></div>
                                                    <div className="gems-pro3"></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">36.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/vox-cinema-acoustics">Gems Founders School</Link></h5>
                                                <p>Location: Dubai, UAE</p>
                                                <p>Client: Gems Education</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The GEMS Education Core Values are our collective beliefs. They set the standard that we
                                                    expect from all GEMS Education people– from employees and teachers, to parents and
                                                    students.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content"> Supply and Installation of
                                                    Acoustic ceiling baffles, Acoustic
                                                    wall panels for multipurpose hall,
                                                    dance & music rooms.</p>
                                                <Link to="/vox-cinema-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Emirati} alt="Emirati School" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="emarati-img"></div>
                                                <div className="img-bot">
                                                    <div className="emarati-img2"></div>
                                                    <div className="emarati-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">35.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/emarati-school-acoustics">Emarati Schools</Link></h5>
                                                <p>Location: Abu Dhabi</p>
                                                <p>Client: Presidential Courts</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The Emirates Private School-Abu Dhabi, since its inception in 1982 has been to impart education to all children of our society. To achieve this, we solicit the support of parents, teachers and students so as to make a consistent effort to achieve our aspiration.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Auditorium Acoustics Works</p>
                                                <Link to="/emarati-school-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Voxcinemas} alt="Vox Cinemas" />
                                            </div>

                                            <div className="gallery images">
                                                <div className="voxbahrain-img"></div>
                                                <div className="img-bot">
                                                    <div className="voxbahrain-img2"></div>
                                                    <div className="voxbahrain-img3"></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">34.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/vox-cinema-acoustics">VOX Cinemas</Link></h5>
                                                <p>Location: Bahrain</p>
                                                <p>Client: Majid Al Futtaim</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">Owned and operated by Majid Al Futtaim Entertainment, VOX Cinemas is the cinema arm of Majid Al Futtaim, the leading shopping malls, communities, retail and leisure pioneer across the Middle East, Africa and Central Asia. </p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Acoustic Fabric Paneling on Walls, Wall Carpets & Other Acoustic Treatment for 15 Screens</p>
                                                <Link to="/vox-cinema-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Museum} alt="Museum of Future Dubai" />
                                            </div>

                                            <div className="gallery images">
                                                <div className="futuremuseum-img"></div>
                                                <div className="img-bot">
                                                    <div className="futuremuseum-img2"></div>
                                                    <div className="futuremuseum-img3"></div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">33.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/museum-of-the-future-acoustics">The Museum of Future, Dubai</Link></h5>
                                                <p>Location: Dubai, UAE</p>
                                                <p>Client: MERAAS</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">The Museum of the Future is an exhibition space for innovative and futuristic ideologies, services and products. It has three main elements - green hill, building, and void.The exterior façade of the building comprises windows that form an Arabic poem by Dubai's ruler about the Emirate’s future.This torus-shaped shell sits on top of the building and comprises 1,024 fire-retardant composite panels clad in stainless steel, and each of which has a unique 3D shape to create the Arabic script.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Acoustical Plaster ceiling in Auditorium below the walkway & Hall area.</p>
                                                <Link to="/museum-of-the-future-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 order-md-2 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Beeah} alt="Beeah" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="beeah-img"></div>
                                                <div className="img-bot">
                                                    <div className="beeah-img2"></div>
                                                    <div className="beeah-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">32.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/beeah-acoustics">BEE'AH Head Quarters</Link></h5>
                                                <p>Location: Sharjah</p>
                                                <p>Client: BEE'AH</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">BEEAH Group is driven to secure a sustainable future for all. By leveraging experience across future-critical sectors and modern, scalable technologies, we are advancing sustainability agendas for companies, cities and governments.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Complete Acoustics Works</p>
                                                <Link to="/beeah-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="line-footer1"></span>
                                <div className="portfolio-item portfolio-style-four mb-5">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 mt-4">
                                            <div className="portfolio-thumb">
                                                <img src={Rashid} alt="Rashid Library" />
                                            </div>
                                            <div className="gallery images">
                                                <div className="rashid-img"></div>
                                                <div className="img-bot">
                                                    <div className="rashid-img2"></div>
                                                    <div className="rashid-img3"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 mt-2">
                                            <div className="portfolio-desc">
                                                <span className="count">31.</span>
                                                <span className="line"></span>
                                                <h5 className="title"><Link to="/rashid-library-acoustics">Mohammed Bin Rashid Library</Link></h5>
                                                <p>Location: Dubai, UAE</p>
                                                <p>Client: Dubai Municipality</p>
                                                <span className="completed-date mb-0 mt-2">About</span>
                                                <p className="about-content">Founded by law no. 14 in 2016 during the national Year of Reading , the library is the vision of His Highness Sheikh Mohammed bin Rashid Al Maktoum. Supporting Dubai and UAE goals to develop a knowledge society, the creation of the Mohammed bin Rashid Library follows a number of important initiatives set in motion to promote and encourage reading, literacy and Arabic literature.</p>
                                                <span className="completed-date mb-0 mt-2">Scope</span>
                                                <p className="scope-content">Acoustical Plaster Ceiling in Auditorium</p>
                                                <Link to="/rashid-library-acoustics" className="portfolio-link">Project Details <span className="icon">+</span></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="portfolio-items">
                                    <div className="portfolio-item portfolio-style-four">
                                        <span className="line-footer"></span>
                                        <div className="row align-items-center">
                                            <div className="col-lg-6 col-md-6">
                                                <Link to="/projects-v1" className="portfolio-next">Next &raquo;</Link>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="portfolio-container2">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default projects;