import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import Video from '../../images/deco-design-lab.mp4';
import Voxevent from '../../images/vox-event.webp';
import Big5event from '../../images/big5-event-2022.webp';
import Saudiexpo from '../../images/saudi-expo.webp';
import Big52018 from '../../images/big5-event-2018.webp';
import Arablab from '../../images/arab-lab-event.webp';
import Big52017 from '../../images/big5-event-2017.webp';
import Big52016 from '../../images/big5-event-2016.webp';
import Arab2016 from '../../images/arab-lab-2016.webp';
import Big52015 from '../../images/big5-event-2015.webp';
import Arab2015 from '../../images/arab-lab-2015.webp';
import Big52014 from '../../images/big5-event-2014.webp';
import Arab2014 from '../../images/arab-lab-2014.webp';
import Exhibi2013 from '../../images/exhibition-2013.webp';
import Semi2014 from '../../images/seminar-2014.webp';
import Krantz from '../../images/krantz-event.webp';
import './event.css';
function event() {
  return (
    
    <>
    <div className="boxed_wrapper" id="event">
    <Helmet>
        <title>Our Recent Events - Moov Group</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Our Recent Events - Moov Group" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/event" />
        <link rel="alternate" href="https://www.moovgroup.com/event" hreflang="en-GB" />
    </Helmet>
    <Header />
    <section className="breadcrumb-area style2 event-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content-box clearfix">
                            <div className="title-s2">
                                <span className="page-tag">OUR </span>
                                <h1 className="page-title">RECENT EVENTS</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section id="blog-area" className="blog-default-area">
            <div className="container">
                <div className="row">
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <video width="340" height="235" controls autoplay>
								        <source src={Video} type="video/mp4" />
								    </video>
								    <div className="post-date">
                                        <h3>Mar <br /><span>14</span></h3>
                                    </div>
								</div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">DECO Design Lab</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li>By <Link to="/#">Moov Group</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>DECO Design Lab</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                                <div className="img-holder">
								    <iframe title="asi" width="340" height="235" src="https://www.youtube.com/embed/m1RH0zYQDyY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
								    <div className="post-date">
                                        <h3>Feb <br /><span>14</span></h3>
                                    </div>
								</div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Moov Group's 10th year Anniversary Celebration</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li>By <Link to="/#">Moov Group</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>10 years of Excellence and Professionalism with the output delivered ...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="img-holder">
								    <iframe width="340" height="235" src="https://www.youtube.com/embed/6kGy1TV5akQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
								    <div className="post-date">
                                        <h3>Jan <br /><span>16</span></h3>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Architectural Acoustics Training</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li>By <Link to="/#">Moov Group</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Voxevent} alt="vox event" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Mar <br /><span>02</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title"><Link to="/#"> Our proud partner "VOX" recognising our efforts</Link></h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                            <li>By <Link to="/#">Majjid Al Futtaim</Link></li>
                                            <li>To <Link to="/#">Moov Group</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>On behalf on Majjid Al Futtaim Cinemas given for Excellence in performance on the...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                                <div className="img-holder">
								    <iframe title="acoustics training" width="340" height="235" src="https://www.youtube.com/embed/TVfuZ2P_E5M" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
								    <div className="post-date">
                                        <h3>Jan <br /><span>31</span></h3>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Easy Training Session</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li>By <Link to="/#">Moov Group</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>In association with EASE AFMG, Germany we are very delighted to conduct 5 days seminar....</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <iframe title="easy training" width="340" height="235" src="https://www.youtube.com/embed/kicTUwuiydY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    <div className="post-date">
                                        <h3>Dec <br /><span>25</span></h3>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Vibration Acoustics Training Session</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li>By <Link to="/#">Moov Group</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Big5event} alt="big5 event 2022" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Dec <br /><span>5</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title"><Link to="/#">BIG 5 - International Building & Construction Show - 2022</Link></h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                            <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group is participating in the BIG 5 Exhibition 2022 with a wide ..</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="500ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Saudiexpo} alt="saudi expo" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Sep <br /><span>6</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title"><Link to="/#">INDEX SAUDI ARABIA - 2022</Link></h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                            <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group is participating in the Index Exhibition 2022 with a wide ..</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Big52018} alt="big5 event 2018" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Nov <br /><span>26</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title"><Link to="/#">BIG 5 - International Building & Construction Show - 2018</Link></h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li> <Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group is participating in the BIG 5 Exhibition 2018 to showcase....</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="700ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Arablab} alt="arab lab event" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Mar <br /><span>18</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Arab Lab Exhibition 2018</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                            <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>Moov Group is participating in the Arab Lab Exhibition 2018 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Big52017} alt="big5 event 2017" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Nov <br /><span>26</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">BIG 5 - International Building & Construction Exhibition - 2017</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group has participated in the BIG 5 exhibition 2017 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Arablab} alt="arab lab event" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Mar <br /><span>20</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Arab Lab Exhibition 2017</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>Moov Group has participated in the Arab Lab Exhibition 2017 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Big52016} alt="big5 event 2016" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Nov <br /><span>21</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">BIG 5 - International Building & Construction Exhibition - 2016</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group has participated in the BIG 5 Exhibition 2016 with a wide...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Arab2016} alt="arab lab 2016" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Mar <br /><span>20</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title"><Link to="/#">Arab Lab Exhibition 2016</Link></h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                            <li> <Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group has participated in the Arab Lab Exhibition 2016 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Big52015} alt="big5 event 2015" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Nov <br /><span>23</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">BIG 5 - International Building & Construction Exhibition - 2015</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group has participated in the BIG 5 exhibition 2015 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Arab2015} alt="arab lab 2015" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Mar <br /><span>23</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">ARABLAB - 2015</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group has participated in the Arab Lab 2015 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Big52014} alt="big5 event 2014" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Nov <br /><span>17</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">BIG 5 - International Building & Construction Exhibition - 2014</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group has participated in the BIG 5 exhibition 2014 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Arab2014} alt="arab lab 2014" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Mar <br /><span>17</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Arab Lab - 2014</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>The Moov Group has participated in the Arab Lab 2014 to showcase...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Exhibi2013} alt="exhibition 2013" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>May <br /><span>22</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">Exhibition - 2013</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Exhibition</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Semi2014} alt="seminar 2014" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>May <br /><span>08</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title">
                                        <Link to="/#">International Competence Seminar - 2014</Link>
                                    </h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                        <li><Link to="/#">Seminar</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>We look forward to welcoming you on May 8 and 9, 2014 at our...</p>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                        <div className="single-blog-colum-style1">
                            <div className="single-blog-post style3 wow fadeInLeft" data-wow-delay="800ms" data-wow-duration="1500ms">
                                <div className="img-holder">
                                    <img src={Krantz} alt="krantz event" />
                                    <div className="overlay-style-two"></div>
                                    <div className="overlay">
                                        <div className="box">
                                            <div className="post-date">
                                                <h3>Aug <br /><span>03</span></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-holder">
                                    <h3 className="blog-title"><Link to="/#">Krantz-IMTECH</Link></h3>
                                    <div className="meta-box">
                                        <ul className="meta-info">
                                            <li> <Link to="/#">Seminar</Link></li>
                                        </ul>
                                    </div>
                                    <div className="text">
                                        <p>Claus Schweinheim, Divisional Manager for Caverion Deutschland...</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
        
    </div>
    <Footer />
    </>
      );
    }
    
    export default event;