import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './sectors.css';
import Carousel from '../carousel';
function entertainment() {
  return (
    
    <>
    <div className="boxed_wrapper" id="sector">
    <Helmet>
        <title>Entertainment Acoustics and Soundproofing Solutions</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Entertainment Acoustics and Soundproofing Solutions" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/entertainment-acoustics" />
        <link rel="alternate" href="https://www.moovgroup.com/entertainment-acoustics" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section sector-bg">
        <div className="container">
            <span className="page-tag">BUSINESS</span>
            <h1 className="page-title">SECTORS</h1>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Sectors</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/architectural-acoustics" className="nav-link">Architectural Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/auditorium-acoustics" className="nav-link">Auditorium Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/themepark-acoustics" className="nav-link">Theme Park Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/studio-acoustics" className="nav-link">Studio Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/education-acoustics" className="nav-link">Education Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/homecinema-acoustics" className="nav-link">Home Cinema Acoustics</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/entertainment-acoustics" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Entertainment Acoustics</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/seating-solutions" className="nav-link">Seating Solutions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/hospitality-acoustics" className="nav-link">Hospitality Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/commercial-acoustics" className="nav-link">Commercial Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-wall" className="nav-link">Acoustic Wall and Floors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/industrial-acoustics" className="nav-link">Industrial Acoustics</Link>
                                </li>
							</ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
                <div role="tabpanel" className="tab-pane fade active show" id="eco-therm">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">07. </span>
                        <h1 className="title1">ENTERTAINMENT ACOUSTIC (CINEMAS)</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <span className="sector-entertainment"></span>
                            </div>
                            <div className="top-text-box">
                                    <h2 className="title">ENTERTAINMENT ACOUSTIC</h2>
                                    <p>Experience the magic of theater like never before with Moov! As a key player in creating memorable entertainment experiences, we offer our expertise in theater acoustics. Our team consults on acoustical design for theaters and cinemas, helping to deliver captivating performing arts events, movies, and multimedia presentations from the cinema screen and theater stage. Good acoustics are essential in any theater or performance space, allowing sound to travel naturally for long distances and enabling the audience to fully experience the music, plays, or other performances, even if they are sitting far away from the stage. However, buildings with poor acoustics can hinder the sound from traveling effectively from one side of the space to another. Let Acoustical Surfaces Industries LLC help ensure that your theater or cinema has optimal acoustics for an unforgettable entertainment experience.</p>
                                    <p>At Moov Group, we specialize in providing soundproof insulation and acoustic solutions for entertainment venues. Our range of products, including PET Acoustic Panels, are suitable for use in auditoriums, recording studios, home theatres, hotels and resorts. We have worked on a number of high-profile projects, including Ferrari World in Abu Dhabi, Orbi Theater in Dubai, Vox Cinemas, Novo Cinemas, Sports Complex in Fujairah, Sky News in Abu Dhabi, Gems Wellington School in Dubai and KAPSARC in Saudi Arabia. Our expertise and experience make us a leading provider of entertainment acoustics solutions.</p>
                            </div>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/sectors" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                        </div>
                    </div>
                    
                </div>
                
            </div>
	    </div>
    </div>
    </section>
        
    <section className="recently-project-style2-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="pro-title">Our Projects</div>
                    <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                </div>
                <Carousel />
            </div>
        </section>
        </div>
    </div>
<Footer />
</>
  );
}

export default entertainment;