import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './polymax.css';
function polymax() {
  return (
    
    <>
    <div className="boxed_wrapper" id="polymax">
    <Helmet>
        <title>Polyester Acoustic Panels - Polymax</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Polymax Polyester Acoustic Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/polymax" />
        <link rel="alternate" href="https://www.moovgroup.com/polymax" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section polymax-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link active" >Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link"  >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link"  >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link"  >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link"  >Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link"  >Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="#polymax" className="nav-link"  role="tab" data-toggle="tab" aria-selected="true">Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link"  >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
            
            <div role="tabpanel" className="tab-pane fade active show" id="polymax">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">13. </span>
                    <h1 className="title1">Polymax</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product13"></div>
                        </div>
                        <div className="top-text-box">
                            <h2>PolyMax<sup>Tm</sup> Acoustical Wall & Ceiling Tiles</h2>
                            <p>PolyMax<sup>Tm</sup> polyester acoustic panel is a combination of 100% polyester (60% PET - recycled fibre, 40% PET - virgin fibre) which makes this panel non-allergic, non-toxic, fungal resistant and decorative acoustic panels with zero chemical irritants or formaldehyde.</p>
                            <p>PolyMax<sup>Tm</sup> acoustic panels are available in a variety of colors & can be printed with custom graphics which fit into any decor.</p>
                        
							<div className="blog-single-bottom-content-box">
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Formaldehyde Free</li>
                                        <li>No Binding Agents</li>
                                        <li>No Chemical Irritants</li>
										<li>Architecturally Decorative</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Tackable Surface</li>
                                        <li>Impact Resistant</li>
						                <li>className A Fire Rated</li>
                                        <li>60% Polyethylene Terephthalate – Recycled Content</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
					
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                            <h2>Applications </h2>
                            <p>Theaters & home theaters, gymnasiums & multipurpose room, commercial & office buildings, studios & production houses, pro audio, restaurants & night clubs, government & municipal buildings, hospitals & medical clinics, schools & universities, houses of worship, industrial & manufacturing facilities and much more</p>
                            <div className="row">
                               
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub1"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub2"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub3"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub4"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub5"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
            
        </div>
        
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default polymax;