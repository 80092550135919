import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './projects.css';
import Carousel from '../carousel';
import Etisalat1 from '../../images/etisalat-auditorium-project1.webp';
import Etisalat2 from '../../images/etisalat-auditorium-project2.webp';
import Etisalat3 from '../../images/etisalat-auditorium-project3.webp';
import Etisalat4 from '../../images/etisalat-auditorium-project4.webp';
import Etisalat5 from '../../images/etisalat-auditorium-project5.webp';
function etisalat() {
  return (
    
    <>
    <div className="boxed_wrapper" id="project">
    <Helmet>
        <title>Etisalat Auditorium - Moov Group</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Etisalat Auditorium " />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/etisalat-auditorium-acoustics" />
        <link rel="alternate" href="https://www.moovgroup.com/etisalat-auditorium-acoustics" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section projects-bg">
        <div className="container">
            <h1 className="page-title">Etisalat Auditorium</h1>
            <span className="page-tag">Supply and Installation of seats</span>
        </div>
    </section>
    
    <section className="portfolio-section section-gap-top" id="portfolio">
        <div className="container">
            
	            <div className="portfolio-items">
		            <div className="portfolio-item portfolio-style-four">
		                
			            <div className="row align-items-center">
				            <div className="col-lg-6 col-md-6">
				            <div className="portfolio-container1">
				                   <h1 className="portfolio-previous">Etisalat Auditorium</h1>
				            </div>
				            </div>
				            <div className="col-lg-6 col-md-6">
				                <div className="portfolio-container2">
				                    <Link to="/american-school-acoustics" className="portfolio-next">Next &raquo;</Link>
				                </div>
                            </div>
                        </div>
                        <span className="line-footer"></span>
                    </div>
                </div>
                
            <div className="row portfolio-items portfolio-filter" id="portsub"  style={{position: "relative", height: "580px"}}>
                <div className="col-lg-3 col-md-6 col-sm-6 filter-item design web" style={{position: "absolute", right: "0px", bottom: "0px"}}>
                    <div className="portfolio-item">
                        <div className="portfolio-thumb">
                            <div className="thumb">
                                <img src={Etisalat1} alt="Etisalat Auditorium Acoustics - 1" />
                            </div>
                        </div>
                        <div className="portfolio-desc">
                            <span className="cat">Dubai</span>
                            <h5 className="title"><Link to="/etisalat-auditorium-acoustics">Etisalat Auditorium</Link></h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 filter-item game" style={{position: "absolute", right: "295px", bottom: "0px"}}>
                    <div className="portfolio-item big-item">
                        <div className="portfolio-thumb">
                            <div className="thumb">
                                <img src={Etisalat2} alt="Etisalat Auditorium Acoustics - 2" />
                            </div>
                        </div>
                        <div className="portfolio-desc">
                            <span className="cat">Dubai</span>
                            <h5 className="title"><Link to="/etisalat-auditorium-acoustics">Etisalat Auditorium</Link></h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 filter-item web" style={{position: "absolute", right: "885px", bottom: "0px"}}>
                    <div className="portfolio-item">
                        <div className="portfolio-thumb">
                            <div className="thumb">
                                <img src={Etisalat3} alt="Etisalat Auditorium Acoustics - 3" />
                            </div>
                        </div>
                        <div className="portfolio-desc">
                            <span className="cat">Dubai</span>
                            <h5 className="title"><Link to="/etisalat-auditorium-acoustics">Etisalat Auditorium</Link></h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 filter-item design" style={{position: "absolute", right: "0px", bottom: "290px"}}>
                    <div className="portfolio-item">
                        <div className="portfolio-thumb">
                            <div className="thumb">
                                <img src={Etisalat4} alt="Etisalat Auditorium Acoustics - 4" />
                            </div>
                        </div>
                        <div className="portfolio-desc">
                            <span className="cat">Dubai</span>
                            <h5 className="title"><Link to="/etisalat-auditorium-acoustics">Etisalat Auditorium</Link></h5>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 filter-item game web" style={{position: "absolute", right: "885px", bottom: "290px"}}>
                    <div className="portfolio-item">
                        <div className="portfolio-thumb">
                            <div className="thumb">
                                <img src={Etisalat5} alt="Etisalat Auditorium Acoustics - 5" />
                            </div>
                        </div>
                        <div className="portfolio-desc">
                            <span className="cat">Dubai</span>
                            <h5 className="title"><Link to="/etisalat-auditorium-acoustics">Etisalat Auditorium</Link></h5>
                   
				        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="recently-project-style2-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="pro-title">Our Projects</div>
                    <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                </div>
                <Carousel />
            </div>
        </section>
        </div>
    </div>
<Footer />
</>
  );
}

export default etisalat;