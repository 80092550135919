import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './sectors.css';
import Carousel from '../carousel';
function seating() {
  return (
    
    <>
    <div className="boxed_wrapper" id="sector">
    <Helmet>
        <title>Cinema and Auditorium Seating Solutions and Soundproofing Solutions</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Cinema and Auditorium Seating Solutions and Soundproofing Solutions" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/seating-solutions" />
        <link rel="alternate" href="https://www.moovgroup.com/seating-solutions" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section sector-bg">
        <div className="container">
            <span className="page-tag">BUSINESS</span>
            <h1 className="page-title">SECTORS</h1>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Sectors</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/architectural-acoustics" className="nav-link">Architectural Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/auditorium-acoustics" className="nav-link">Auditorium Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/themepark-acoustics" className="nav-link">Theme Park Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/studio-acoustics" className="nav-link ">Studio Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/education-acoustics" className="nav-link ">Education Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/homecinema-acoustics" className="nav-link">Home Cinema Acoustics</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/entertainment-acoustics" className="nav-link">Entertainment Acoustics</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/seating-solutions" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Seating Solutions</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/hospitality-acoustics" className="nav-link">Hospitality Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/commercial-acoustics" className="nav-link">Commercial Acoustics</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-wall" className="nav-link">Acoustic Wall and Floors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/industrial-acoustics" className="nav-link">Industrial Acoustics</Link>
                                </li>
							</ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
                <div role="tabpanel" className="tab-pane fade active show" id="eco-therm">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">08. </span>
                        <h1 className="title1">CINEMA & AUDITORIUM SEATING SOLUTION</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <span className="sector-seating"></span>
                            </div>
                            <div className="top-text-box">
                                    <h2 className="title">CINEMA & AUDITORIUM SEATING SOLUTION</h2>
                                    <p>Experience the ultimate in comfort and functionality with Moov Group’s auditorium and theater seating solutions. Our expertly designed seating options provide audiences with an unparalleled viewing experience.</p>
                                    <p>Our range of seating options includes fixed seating solutions for auditoriums, conference halls, theaters, and multi-purpose areas. Crafted from high-quality materials and designed with aesthetics in mind, our seating products offer the perfect combination of comfort and style.</p>
                                    <p>In addition to our standard seating products, we also offer custom solutions tailored to the unique needs of each venue. Our team of experts will work with you to assess your seating requirements and recommend the best solutions to meet your needs. Whether you are building a new theater or refurbishing an existing auditorium, Moov Group can help you create the perfect seating arrangement.</p>
                                    <p>Contact us today to learn more about our auditorium and theater seating solutions and discover how we can help enhance the audience experience at your venue.</p>
                            </div>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/sectors" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span></Link>
                        </div>
                    </div>
                    
                </div>
                
            </div>
	    </div>
    </div>
    </section>
    <section className="recently-project-style2-area">
            <div className="container">
                <div className="sec-title text-center">
                    <div className="pro-title">Our Projects</div>
                    <p className="bottom-text">we have successfully completed more than 900 acoustic projects<br /> over the last 17 years across the Middle East.<br /> Have a glance at our recently compeleted projects</p>
                </div>
                <Carousel />
            </div>
        </section>
        </div>
    </div>
<Footer />
</>
  );
}

export default seating;