import React from "react";
import './carousel.css';
import Yasarena from '../images/yas-arena.webp';
import Quranradio from '../images/quran-radio-studio.webp';
import Germanpavilion from '../images/german-pavilion.webp';
import DirectEnergy from '../images/direct-energy-research-center.webp';
import Emiratischool from '../images/emirati-school.webp';
import Ferrari from '../images/ferrari-word-abu-dhabi-moov-group.webp';
import Kapsarc from '../images/kasparc-project.webp';
import Presidential from '../images/presidential-palace.webp';
import Skynews from '../images/skynews-project.webp';
import Eibf from '../images/eibf-project.webp';
import Lauvre from '../images/lauvre-project.webp';
import NBA from '../images/nba-project.webp';
import Voxcinemas from '../images/vox-project.webp';
import Dubaiairport from '../images/dubai-airport-project.webp';
import Sportscomplex from '../images/sports-complex-project.webp';
import Novocinemas from '../images/novo-project.webp';
import Novopark from '../images/novo-img-project.webp';
import Azamtv from '../images/azam-tv-project.webp';
import Dubaipark from '../images/dubai-parks-project.webp';
import Emal from '../images/emal-project.webp';
function carousel() {
    return (
<>      
        <div id="carousel">
            <div className="row">
                <div className="col-xl-12">
                    <div className="items">
                        <div className="item">
                            <div className="img-holder">
                                <img src={Yasarena} alt="Yas Arena" />
                                <div className="title-box">
                                    <h3><span>Project: </span> YAS ARENA</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Quranradio} alt="Quran Radio" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Quran Radio Studio</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Germanpavilion} alt="German Pavilion" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Project: German Pavilion</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={DirectEnergy} alt="Direct Energy" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Direct Energy Research Center</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Emiratischool} alt="Emirati School" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Emirati School</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Ferrari} alt="Ferrari World" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Ferrari World</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Kapsarc} alt="Kapsarc" />
                                <div className="title-box">
                                    <h3><span>Project: </span> KAPSARC</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Presidential} alt="Presidential" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Presidential Palace</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Skynews} alt="Skynews" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Sky News</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Eibf} alt="EIBFS" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Emirates Institute of Banking & Financial Studies</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Lauvre} alt="Lauvre" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Lauvre</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={NBA} alt="NBA" />
                                <div className="title-box">
                                    <h3><span>Project: </span> National Bank of Abu Dhabi</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Dubaiairport} alt="Dubai Airport" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Dubai Airport, Terminal 2</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Voxcinemas} alt="Vox Cinemas" />
                                <div className="title-box">
                                    <h3><span>Project: </span> VOX Cinemas</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Sportscomplex} alt="Sports Complex" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Sports Complex, Fujairah</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Novocinemas} alt="Novo Cinemas" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Novo Cinemas</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Novopark} alt="Novo Park" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Novo Cinemas - IMG World Theme Park</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Azamtv} alt="Azam TV" />
                                <div className="title-box">
                                    <h3><span>Project: </span> AZAM TV</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Dubaipark} alt="Dubai Park" />
                                <div className="title-box">
                                    <h3><span>Project: </span> Dubai Parks</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="img-holder">
                                <img src={Emal} alt="EMAL" />
                               <div className="title-box">
                                    <h3><span>Project: </span> Emirates Aluminum Company (EMAL) Auditorium</h3>
                                    <h4><span>Scope: </span> Supply & installation of Acoustic Panels</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>    
            </div>                       
        </div>
    </>
    );
  }
  
  export default carousel;