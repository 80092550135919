import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './woodtec.css';
function woodtec() {
  return (
    
    <>
    <div className="boxed_wrapper" id="woodtec">
    <Helmet>
        <title>WoodTec - Wooden Wall Acoustic Panels</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="WoodTEC Acoustic Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/woodtec" />
        <link rel="alternate" href="https://www.moovgroup.com/woodtec" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section woodtec-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav"> 
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link"  >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link"  >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/#woodtec" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link"  >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link"  >Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link"  >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
            
            <div role="tabpanel" className="tab-pane fade active show" id="woodtec">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">05. </span>
                    <h1 className="title1">WoodTEC</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product5"></div>
                        </div>
                        <div className="top-text-box">
                            <p>WoodTEC panels are acoustic panels with high acoustic performance applicable for both walls and ceilings. The panels are made of MDF a perfect blend of aesthetical and technical qualities.</p>
                          
                            <div className="blog-single-bottom-content-box">
                            <h2>Applications </h2>
							<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p><br />
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditoriums</li>
                                        <li>Meeting Rooms</li>
                                        <li>Theatres</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Lecture Halls</li>
									    <li>Educational Instituition</li>
                                        <li>Airports</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                     
                        <div className="blog-single-bottom-content-box">
                            <h2>Categories </h2>
                            <div className="row">
                           
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/woodtec-linear-acoustic-panel"><h3>Linear Acoustic Panel</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>Linear Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Linearo Series Acoustic Panels comes with grooving on the viewed face and perforations</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/woodtec-linear-acoustic-panel"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/woodtec-perforated-acoustic-panel"><h3>Perforated Acoustic Panel</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Perforated Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Perfero acoustic panels are the go to choice when it comes to economically achieving high absorption characteristics</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/woodtec-perforated-acoustic-panel">
                                        <span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/woodtec-slotted-acoustic-panel"><h3>Slotted Acoustic Panel</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Slotted Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Slotto acoustic panels help to achieve high absorption and acoustic levels at a medium cost</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/woodtec-slotted-acoustic-panel">
                                        <span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub4"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/woodtec-designer-acoustic-panel"><h3>Designer Acoustic Panel</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>04</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Designer Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Designo acoustic panels comes in painted finish only and improves the visual outlook of the spaces by a great deal</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/woodtec-designer-acoustic-panel">
                                        <span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/woodtec" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
            
            </div>
        
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default woodtec;