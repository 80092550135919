import React from "react";
import { Link } from "react-router-dom";
import logo from '../images/moov-group.svg';
import './header.css';
function header() {
    return (
        <>
        <section className="top-bar-style2">
            <div className="top-style2 clearfix">
                <div className="top-style2-left">
                    <p><span className="icon-music"></span>Noise Control Helpline
                    </p>
                    <ul>
                        <li><a className="elinks" href="tel:+97143310000">04-3310000</a></li>
                    </ul> 
                </div>
                <div className="top-style2-right">
                    <ul className="topbar-social-links">
                        <li><a className="elinks" href="https://wa.me/97143310000" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;E-Brochure</a></li>
                        <li><a className="elinks" href="https://wa.me/97143310000" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;PQD</a></li>
                        <li><a className="elinks" href="https://wa.me/97143310000" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp" aria-hidden="true"></i>&nbsp;&nbsp;Product Guide</a></li>
                        <li><Link to="mailto:mail@moovgroup.com" className="elinks"><i className="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;Mail us</Link></li>
                    </ul>
                </div>
            </div>
        </section>
        
        <header className="main-header header-style2 stricky">
            <div className="inner-container clearfix">
                <div className="logo-box-style2 float-left">
                    <Link to="/">
                       <img src={logo} alt="Moov Group" />
                    </Link>
                </div>
                <div className="main-menu-box float-right">
                    <nav className="main-menu style2 clearfix">
                        <div className="navbar-header clearfix">
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <div className="navbar-collapse collapse clearfix">
                            <ul className="navigation clearfix">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/sectors">Sectors</Link></li>
                                <li><Link to="/projects">Projects</Link></li>
                                <li><Link to="/products">Products</Link></li>
                                <li><Link to="/event">Events</Link></li>
                                <li><Link to="/career">Career</Link></li>
								<li><Link to="/contact">Contact us</Link></li>
                            </ul>
                        </div>
                    </nav>
                    <div className="mainmenu-right style2">
                        <div className="outer-search-box"></div>
                        <div className="button">
                            <Link to="tel:+97143310000" className="btn-one">Reach us
                                <span className="fa fa-phone" aria-hidden="true"></span></Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        </>
    );
}
export default header;