import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './fabrisorb.css';
function fabriclouds() {
  return (
    
    <>
    <div className="boxed_wrapper" id="fabrisorb">
    <Helmet>
        <title>Sound Absorbing Fabrisorb Acoustic Clouds</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Sound Absorbing Fabrisorb Acoustic Clouds" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/fabrisorb-acoustic-clouds" />
        <link rel="alternate" href="https://www.moovgroup.com/fabrisorb-acoustic-clouds" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section fabrisorb-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="section-gap">            
        <div className="container">
            <div className="row">
          
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                    <div className="sidebar-wrapper">
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Categories</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb-acoustic-wall-panels" className="nav-link">Acoustical Wall Panels</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-acoustic-clouds" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Acoustical Clouds</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-acoustic-baffles" className="nav-link" >Acoustical Baffles</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-acoustic-designer-panels" className="nav-link" >Acoustical Designer Panels</Link></li>
                            </ul>
                        </div>
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link></li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link></li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link></li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link></li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link></li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link></li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane Foam</Link></li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link></li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">02. </span>
                        <h1 className="title1">Fabrisorb Acoustic Clouds</h1>
                    </div>
                    
                    <div className="row">
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="fabrisorb-sub2"></div>
                                </div>
                                <div className="top-text-box">
                                    <p>Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces.</p>
                                    <p>Edge Hardened Fabric Acoustic Panels have no internal frame, making them lightweight and suitable for multiple mounting techniques. The construction and integrity of frameless panels starts with a rigid fiberglass core which is extremely effective at absorbing sound. Fabric is tightly and evenly stretched around the core to prevent any visual imperfections such as wrinkles. Borders are treated to form hard, impact-resistant edges.   </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="faq-content-box">
                            <div className="accordion-box">
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>Specification</h4>
                                    </div>
                                    <div className="accord-content collapsed">
									    <table className="table">
										    <tbody>
											<tr>
											<th scope="row">Core</th>
												<td>96kg/m3 Fiberglass Insulation with tissue facing</td>
											</tr>
											<tr>
											<th scope="row">Thickness (mm)</th>
												<td>20 – 25 – 40 – 50 mm, custom thickness are also available</td>
											</tr>
											<tr>
											<th scope="row">Sizes (mm)</th>
												<td>600 x 600 - 600 x 1200 - 1200 x 1200 - 1200 x 2400 &amp; many more custom sizes </td>
											</tr>
											<tr>
											<th scope="row">Acoustic Properties</th>
												<td>NRC 0.75 – 1.15 ( varies with thickness and mounting )</td>
											</tr>
											<tr>
											<th scope="row">Edges</th>
												<td>Concealed and Hardened</td>
											</tr>
											<tr>
											<th scope="row">Edge Finishes</th>
												<td>Square / Radius / Bevel / Half Bevel</td>
											</tr>
											<tr>
											<th scope="row">Facing</th>
												<td>Fabric</td>
											</tr>
											<tr>
											<th scope="row">Colours</th>
												<td>Wide range of standard colours. Also available in custom colours</td>
											</tr>
											<tr>
											<th scope="row">Flamability</th>
												<td>Tested as per ASTM E84 and classNameified as className A</td>
											</tr>
											<tr>
											<th scope="row">Mounting type</th>
												<td>Type A, E200 &amp; E400</td>
											</tr>
											<tr>
											<th scope="row">Installation</th>
												<td>Adhesive, Velcro, Impaling clips, Z clips etc</td>
											</tr>
										    </tbody>
									    </table>
                                    </div>
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>Reverberation</h4>
                                    </div>
                                    <div className="accord-content collapsed">
                                        <table className="table">
										    <thead>
											<tr>
												<th scope="col">Thickness</th>
												<th scope="col">125Hz</th>
												<th scope="col">250Hz</th>
												<th scope="col">500Hz</th>
												<th scope="col">1KHz</th>
												<th scope="col">2KHz</th>
												<th scope="col">4KHz</th>
												<th scope="col">NRC</th>
											</tr>
										    </thead>
										    <tbody>
											<tr>
											<th scope="row">20mm</th>
												<td>0.07</td>
												<td>0.32</td>
												<td>0.75</td>
												<td>0.99</td>
												<td>0.93/td&gt;
												</td><td>0.85</td>
												<td>0.75</td>
											</tr>
											<tr>
											<th scope="row">25mm</th>
												<td>0.09</td>
												<td>0.40</td>
												<td>0.91</td>
												<td>0.97</td>
												<td>0.92</td>
												<td>0.86</td>
												<td>0.80</td>
											</tr>
											<tr>
											<th scope="row">40mm</th>
												<td>0.17</td>
												<td>0.82</td>
												<td>0.94</td>
												<td>0.98</td>
												<td>0.88</td>
												<td>0.83</td>
												<td>0.90</td>
											</tr>
											<tr>
											<th scope="row">50mm</th>
												<td>0.22</td>
												<td>0.81</td> 
												<td>1.24</td>
												<td>1.30</td>
												<td>1.21</td>
												<td>1.16</td>
												<td>1.15</td>
											</tr>
										    </tbody>
									    </table>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <a href="https://www.acousticalsurfaces.in/fabrisorb" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default fabriclouds;