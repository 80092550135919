import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Framesystem1 from '../../images/fabriwall-track-54-51.webp';
import Framesystem2 from '../../images/fabriwall-track-44-51.webp';
import Framesystem3 from '../../images/fabriwall-track-44-45.webp';
import Framesystem4 from '../../images/fabriwall-track-50-8.webp';
import Framesystem5 from '../../images/fabriwall-track-29-25.webp';
import Framesystem6 from '../../images/fabriwall-track-38-25.webp';
import Framesystem7 from '../../images/fabriwall-track-29-13.webp';
import Framesystem8 from '../../images/fabriwall-track-27-13.webp';
import Framesystem9 from '../../images/fabriwall-track-37-13.webp';
import Framesystem10 from '../../images/fabriwall-track-31-75.webp';
import Framesystem11 from '../../images/fabriwall-track-35-25.webp';
import Framesystem12 from '../../images/fabriwall-track-48-26.webp';
import Framesystem13 from '../../images/fabriwall-track-1-31-75.webp';
import Framesystem14 from '../../images/fabriwall-track-2-31-75.webp';
import Framesystem15 from '../../images/fabriwall-track-3-34-2.webp';
import Header from '../header';
import Footer from '../footer';
import './fabriwall.css';
function fabritrack() {
  return (
    
    <>
    <div className="boxed_wrapper" id="fabriwall">
	<Helmet>
		<title>Fabriwall Track System Wall and Ceiling Acoustic Solutions</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Fabriwall Track System Wall and Ceiling Acoustic Solutions" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/fabriwall-track" />
    	<link rel="alternate" href="https://www.moovgroup.com/fabriwall-track" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section fabriwall-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
   
    <section className="section-gap">            
        <div className="container">
            <div className="row">
                
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                    <div className="sidebar-wrapper">
                       
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Categories</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabriwall-track" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">FabriWALL TRACK</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall-fabric" className="nav-link">FabriWALL FABRIC</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall-panel" className="nav-link">FabriWALL PANEL</Link></li>
                            </ul>
                        </div>
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link></li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link></li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link></li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link></li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link></li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link></li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane Foam</Link></li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link></li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">01. </span>
                        <h1 className="title1">FabriWALL Track</h1>
                    </div>
                    
                    <div className="row">
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="fabriwall-sub1"></div>
                                </div>
                                <div className="top-text-box">
                                    <h1>Fabri<span className="fabriwall">WALL</span> TRACK</h1>
								    <br />
								    <p>Tracks are on-site fabricated acoustical systems that enables fabric to be stretched for interior architectural works. </p>
								    <p>The fabric is stretched over the semi rigid absorption panel to control sound. They are aesthetically pleasing and provides flexibility with onsite fabricated installation. They fit perfectly to any design concept, courtesy to their versatility and the availability of wide range of fabric selections. They are available in over 500 different designs. </p>
								    <h4>ON-SITE ASSEMBLY AND INSTALLATION</h4>
							        <p> FabriWALL Tracks can be built and installed on site, which in turn ensures proper fitting. </p>
							        <p>In addition, this allows making last minute alterations before installation if required to suit the updated measurements. </p>
							        <p>FabriWALL Tracks are easier to mount and are normally installed with traditional track profiles or magnets in order to be easily dismounted. </p>
							    </div>
							    
							    <div className="blog-single-bottom-content-box">
                                <h2>Acoustical Metal Ceiling Tiles </h2>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <ul>
                                                <li>13 mm</li>
                                                <li>25 mm</li>
										        <li>50 mm</li>
                                            </ul>
                                        </div>
                                    </div> 
                                </div>
							    
							    <br /> 
							    <br />
                              
							    <div className="blog-single-bottom-content-box">
                                <h2>PRODUCT FEATURES </h2>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <ul>
                                                <li>Suitable for wall use</li>
                                                <li>Customisable to any shape</li>
                                                <li>Flexible and easily adjustable during installation</li>
										        <li>Made with at least 25 % recycled fiberglass</li>
										        <li>Excellent noise reduction</li>
										        <li>Resists microbial growth</li>
										        <li>Structurally rigid</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
						    </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="faq-content-box">
                            <div className="accordion-box">
                                <h1><strong>TRACK / FRAME SYSTEMS</strong> Components</h1>
	                            
	                            <div className="row pb-2">
    	                            <div className="col-md-4 col-sm-12 featured-card">
				                        <div className="card">
		  			                        <img className="card-img-top" src={Framesystem1} alt="Frame Systems" />
											<div className="card-block">
    					                        <div className="card-text">
			               	                        <ul type="square">
  					                                    <li>2" Profile</li>
  					                                    <li>Center Track</li>
  					                                    <li>54 : 51 mm</li>
				                                    </ul>
				                                </div>
    				                        </div>
            	                        </div>
		                            </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
			                            <div className="card">
										<img className="card-img-top" src={Framesystem2} alt="Fabwall Featured Card" />
  			                                <div className="card-block">
        				                        <div className="card-text">
				                                    <ul type="square">
      					                                <li>2" Profile</li>
  					                                    <li>Side Track</li>
  					                                    <li>44 : 51 mm</li>
				                                    </ul>
				                                </div>
    			                            </div>
                                        </div>
                                    </div>
			                        <div className="col-md-4 col-sm-12 featured    -card">
			                            <div className="card">
										<img className="card-img-top" src={Framesystem3} alt="Fabwall Track" />
  			                                <div className="card-block">
    				                            <div className="card-text">
				                                    <ul type="square">
  					                                    <li>2" Profile</li>
  					                                    <li>Square Track</li>
  					                                    <li>44.45 : 50.8 mm</li>
				                                    </ul>
				                                </div>
                                            </div>
				                        </div>
			                        </div>
		                        </div>
		                        
		                        <div className="row pb-2">
			                        <div className="col-md-4 col-sm-12 featured-card">
		    	                        <div className="card">
										<img className="card-img-top" src={Framesystem4} alt="Featured Fabwall" />
  			                                <div className="card-block">
    		    		                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1" Profile</li>
  					                                    <li>Center Track</li>
  					                                    <li>50.8 : 25.4 mm</li>
				                                    </ul>
				                                </div>
          			                        </div>
		                                </div>
		                            </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
		                                <div className="card">
			                                <img className="card-img-top" src={Framesystem5} alt="Square Fabwall" />
  			                                <div className="card-block">
    		                                    <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1" Profile</li>
  					                                    <li>Side Track</li>
  					                                    <li>29 : 25.4 mm</li>
				                                    </ul>
				                                </div>
    				                        </div>
		                                </div>
		                            </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
				                        <div className="card">
										<img className="card-img-top" src={Framesystem6} alt="Fabwall Square Track" />
  			                                <div className="card-block">
    					                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1" Profile</li>
  					                                    <li>Square Track</li>
  					                                    <li>38 : 25.4 mm</li>
				                                    </ul>
				                                </div>
      			                            </div>
				                        </div>
			                        </div>
		                        </div>
		                        
		                        <div className="row pb-2">
			                        <div className="col-md-4 col-sm-12 featured-card">
				                        <div className="card">
										<img className="card-img-top" src={Framesystem7} alt="Square Card Track" />
  			                                <div className="card-block">
    					                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1/2" Profile</li>
  					                                    <li>Center Track</li>
  					                                    <li>29 : 13 mm</li>
				                                    </ul>
				                                </div>
    			  	                        </div>
				                        </div>
			                        </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
				                        <div className="card">
			  		                        <img className="card-img-top" src={Framesystem8} alt="Card Square" />
  			                                <div className="card-block">
    					                        <div className="card-text">
		        		                            <ul type="square">
  					                                    <li>1/2" Profile</li>
  					                                    <li>Side Track</li>
  					                                    <li>27 : 13 mm</li>
				                                    </ul>
				                                </div>
    				                        </div>
				                        </div>
			                        </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
		    	                        <div className="card">
					                        <img className="card-img-top" src={Framesystem9} alt="Square Fabwall Profile" />
  			                                <div className="card-block">
        				                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1/2" Profile</li>
  					                                    <li>Square Track</li>
  					                                    <li>37 : 13 mm</li>
				                                    </ul>
				                                </div>
            		                        </div>
		                                </div>
		                            </div>
		                        </div>
		                        <h2>BEVELED TRACKS</h2>
		                        <div className="row pb-2">
		                            <div className="col-md-4 col-sm-12 featured-card">
		                                <div className="card">
			 		                        <img className="card-img-top" src={Framesystem10} alt="Featured Fabwall Square" />
  			                                <div className="card-block">
    					                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1" Profile</li>
  					                                    <li>Side Track</li>
  					                                    <li>31.75 : 25.4 mm</li>
				                                    </ul>
				                                </div>
    				                        </div>
				                        </div>
			                        </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
		    	                        <div className="card">
					                        <img className="card-img-top" src={Framesystem11} alt="Square Profile Fabwall" />
  			                                <div className="card-block">
    					                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1" Profile</li>
  					                                    <li>Corner Track</li>
  					                                    <li>35 : 25 mm</li>
				                                    </ul>
				                                </div>
          			                        </div>
				                        </div>
			                        </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
				                        <div className="card">
					                        <img className="card-img-top" src={Framesystem12} alt="Card Fabwall" />
  			                                <div className="card-block">
    					                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1" Profile</li>
  					                                    <li>Center Track</li>
  					                                    <li>48.26 : 25.4 mm</li>
				                                    </ul>
				                                </div>
    		                                </div>
        		                        </div>
		                            </div>
		                        </div>
		                        <h2>OTHER TRACKS</h2>
	                            <div className="row pb-5">
			                        <div className="col-md-4 col-sm-12 featured-card">
				                        <div className="card">
					                        <img className="card-img-top" src={Framesystem13} alt="Featured Fabwall" />
  			                                <div className="card-block">
    		                                    <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1" Profile</li>
  					                                    <li>Mid-Stream Track</li>
  					                                    <li>31.75 : 25.4 mm</li>
				                                    </ul>
				                                </div>
    				                        </div>
		                                </div>
		                            </div>
		                            <div className="col-md-4 col-sm-12 featured-card">
		                                <div className="card">
			 		                        <img className="card-img-top" src={Framesystem14} alt="Square Profile Fabwall Track" />
  			                                <div className="card-block">
    					                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1/2" Profile</li>
  					                                    <li>SQ Edge Track</li>
  					                                    <li>31.75 : 25.4 mm</li>
				                                    </ul>
				                                </div>
    		                                </div>
				                        </div>
			                        </div>
			                        <div className="col-md-4 col-sm-12 featured-card">
				                        <div className="card">
			 		                        <img className="card-img-top" src={Framesystem15} alt="Mounting System" />
  			                                <div className="card-block">
    					                        <div className="card-text">
				                                    <ul type="square">
  					                                    <li>1/2" Profile</li>
  					                                    <li>Outside Corner Track</li>
  					                                    <li>34.2 : 27.29 mm</li>
				                                    </ul>
				                                </div>
    				                        </div>
				                        </div>
			                        </div>
		                        </div>
		                       
                            </div>
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <a href="https://www.acousticalsurfaces.in/fabriwall" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
	</div>
    </div>
<Footer />
</>
  );
}

export default fabritrack;