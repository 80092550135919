import React from "react";
import {Helmet} from "react-helmet";
import Header from '../header';
import Footer from '../footer';
import office from '../../images/moovgroup-office.webp';
import van from '../../images/moov-group-installation.webp';
import mission from '../../images/mission.webp';
import vission from '../../images/vission.webp';
import acs from '../../images/acs.webp';
import acoustical from '../../images/acoustical-surfaces.webp';
import asi from '../../images/asi.webp';
import geometry from '../../images/acoustic-geometry.webp';
import krantz from '../../images/krantz.webp';
import celenit from '../../images/celenit.webp';
import ideatec from '../../images/idea-tec.webp';
import fadeceiling from '../../images/fade-ceilings-logo.webp';
import kyd from '../../images/kyd.webp';
import newmat from '../../images/newmat.webp';
import cineak from '../../images/cineak.webp';
import starhome from '../../images/star-home-cenema.webp';
import './about.css';
function about() {
  return (
    <>
    <div className="boxed_wrapper" id="about">
    <Helmet>
        <title>Moov Group Established in the year 2007 as a Trading and Installer of Acoustic products</title>
        <meta name="description" content="Moov Group has become one of the paramount multi-disciplined architectural acoustic product supply and contracting companies in the Middle East"/>
        <meta property="og:title" content="About The Moov Group" />
        <meta property="og:description" content="Moov Group has become one of the paramount multi-disciplined architectural acoustic product supply and contracting companies in the Middle East"/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/about" />
        <link rel="alternate" href="https://www.moovgroup.com/about" hreflang="en-GB" />
    </Helmet>
    <Header />
    <section className="breadcrumb-area about-bg">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="inner-content clearfix">
                            <div className="title">
                                <h1>About Us<br /> </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="company-overview-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="intro-box clearfix">
                            <div className="col-xl-6 order-md-2">
                                <div className="sec-title">                         
                                    <div className="title">
                                        <img src={office} alt="moovgroup office" />
                                    </div>
                                </div>    
                            </div>
                            <div className="col-xl-6">
                                <div className="text">   
                                <h3 className="about-moov">Moov Group</h3>
                                <p>Established in the year 2007 as a Trading and Installer of Architectural Acoustic products, Moov Group has become one of the paramount multi-disciplined architectural acoustic product supply and contracting companies in the Middle East. The Moov Group is a diversified business group based in Dubai, all of the business units are professionally organized and managed which have been established to focus on requirement basis along with provision of high quality products and services.</p><br />
								<p>At Moov, we design, engineer, supply and install a variety of acoustic & engineering products and systems across a diverse range of projects creating a seamless, fast track and personal service for the client from concept design to project handover. The group consists of vertically integrated strategic Business Units, coordinated to cover the following industry sectors.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<div className="row">
                    <div className="col-xl-12">
                        <div className="intro-box clearfix">
                            <div className="col-xl-6">     	
                                <div className="sec-title">                         
                                    <div className="title">
                                        <img src={van} alt="moovgroup installation" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 order-md-2">
                                <div className="text">
                                    <p>The integration and coordination of our in-house professional designers and engineers produces designs which conform to every level of precision and performance whilst delivering the pragmatic, value engineered solutions expected in the markets of today. Quality and service are key elements of “ MOOV GROUP “ corporate philosophy and are upheld by a professional management team whose project credits span the globe. The highly motivated, experienced and multi-disciplined team develops and implements manufacturing and installation processes that meet and often exceed stringent British, European and American Standards.</p><br />
								    <p>The group has emerged as one of the most successful and professionally managed enterprises in United Arab Emirates under the guidance of our visionary Chairman. Our activities are based on mutual trust and flexible cooperation with our customers at every stage of the work. With the high quality and reliability of our services, we have always been able to fulfill our customers' requirements and establish long-term relationships with our clients from all over the world.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="history-content-box clearfix">
                            <div className="history-carousel owl-carousel owl-theme">
                                
                                <div className="single-history-content">
                                    <div className="img-box">
                                        <div className="inner">
                                            <img src={mission} alt="mission" />
                                        </div>
                                    </div>
                                    <div className="text-box">
                                        <div className="inner">
                                            <div className="date">
                                                <h3>Mission</h3>
                                            </div>
											<div className="title"></div>				
                                                <div className="text">
                                                    <p>Moov aims to be the world’s premier engineering, construction and project management company focusing on the quality delivers of integrated services, products and solutions to meet our client requirements.</p>                </div>
                                                </div>
                                        </div>
                                    </div>
                                
                                <div className="single-history-content">
                                    <div className="img-box">
                                        <div className="inner">
                                            <img src={vission} alt="vission" />
                                        </div>
                                    </div>
                                    <div className="text-box">
                                        <div className="inner">
                                            <div className="date">
                                                <h3>Vision</h3>
                                            </div>
											<div className="title"></div>				
                                            <div className="text">
                                                <p>Our Vision is to be the first choice for our clients worldwide wherein we focus to build close working relationships with all of our clients with the highest degree of mutuality, trust and openness whilst embracing innovation and sustainability.</p>          </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="single-history-content">
                                    <div className="img-box">
                                        <div className="inner">
                                            <img src={mission} alt="mission" />
                                        </div>
                                    </div>
                                    <div className="text-box">
                                        <div className="inner">
                                            <div className="date">
                                                <h3>Values</h3>
                                            </div>
                                            <div className="title"></div>
                                            <div className="text">
                                                <p>We value the traits of being competent, collaborative and reliable in all our services. We build mutually beneficial relationships with our clients and shall at all times represent the best interests by providing world-className products and services.</p>           </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row slogan-row">
                <div className="sec-title text-center slogan-style2-area">
                    <div className="title1">Milestone</div>
                    <p className="bottom-text">Where we stand now</p>
                </div>
                <div className="row fact-counter">
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="single-fact-counter wow fadeInLeft" data-wow-delay="100ms" data-wow-duration="1500ms">
                            <div className="count-box">
                                <h1>
                                    <span className="timer" data-from="1" data-to="891" data-speed="5000" data-refresh-interval="50">891</span>
                                </h1>
                            </div>
                            <div className="title">
                                <h3>projects</h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="single-fact-counter wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1500ms">
                            <div className="count-box">
                                <h1>
                                    <span className="timer" data-from="1" data-to="1026" data-speed="5000" data-refresh-interval="50">1026</span>
                                </h1>
                            </div>
                            <div className="title">
                                <h3>Clients</h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="single-fact-counter wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                            <div className="count-box">
                                <h1>
                                    <span className="timer" data-from="1" data-to="300" data-speed="5000" data-refresh-interval="50">300</span>
                                </h1>
                            </div>
                            <div className="title">
                                <h3>Products</h3>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                        <div className="single-fact-counter wow fadeInLeft" data-wow-delay="400ms" data-wow-duration="1500ms">
                            <div className="count-box">
                                <h1>
                                    <span className="timer" data-from="1" data-to="100" data-speed="5000" data-refresh-interval="50">100</span>
                                </h1>
                            </div>
                            <div className="title">
                                <h3> Our Team</h3>
                            </div>
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </section>
        
        <section className="main-project-area style4">
            <div className="sec-title text-center">
                <div className="title3">Our Business Partners</div>
            </div>
            <div className="container-fluid main-project-style4">
                <div className="row filter-layout masonary-layout">
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={acs} alt="acoustical surfaces"  />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="https://www.acousticalsurfaces.com" target="_blank" rel="noreferrer noreferrer">www.acousticalsurfaces.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                            
                                <a href="/#">Acoustical Surfaces, Inc – USA</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={acoustical} alt="acoustical surfaces" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="https://www.acousticalsurfaces.in/" target="_blank" rel="noreferrer noreferrer">www.acousticalsurfaces.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                              
                                <a href="/#">Acoustical Surfaces</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={asi} alt="andersonsi" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="https://andersonsi.com/" target="_blank" rel="noreferrer noreferrer">www.andersonsi.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                              
                                <a href="/#">Anderson Specialty Industries</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={geometry} alt="acoustic geometry" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="https://acousticgeometry.com/" target="_blank" rel="noreferrer noreferrer">www.acousticgeometry.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                              
                                <a href="/#">Acoustic Geometry</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={krantz} alt="krantz" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="http://www.krantz.de" target="_blank" rel="noreferrer noreferrer">www.krantz.de</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                             
                                <a href="/#">Krantz – Germany</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={celenit} alt="celenit" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="http://www.celenit.com/" target="_blank" rel="noreferrer noreferrer">www.celenit.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                             
                                <a href="/#">Celenit – Italy</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={ideatec} alt="ideatec" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="http://ideatec.es/en_US/" target="_blank" rel="noreferrer noreferrer">www.ideatec.es</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                             
                                <a href="/#">Ideatec – Spain</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={fadeceiling} alt="fade ceiling" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="https://fadeceilings.com/" target="_blank" rel="noreferrer noreferrer">www.fadeceilings.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                              
                                <a href="/#">Fade Ceilings</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={kyd} alt="kyithyates" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="http://keithyates.com/" target="_blank" rel="noreferrer noreferrer">www.keithyates.com </a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                              
                               <a href="/#">Keith Yates Group – USA</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={newmat} alt="newmat" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="http://www.newmatusa.com/" target="_blank" rel="noreferrer noreferrer">www.newmatusa.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                               
                                <a href="/#">New mat – USA</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={cineak} alt="cineak" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="http://www.cineak.com/" target="_blank" rel="noreferrer noreferrer">www.cineak.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                                
                               <a href="/#">Cineak – Belgium</a>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 filter-item">
                        <div className="single-project-style7">
                            <div className="img-holder">
                                <img src={starhome} alt="star home cinema" />
                                <div className="overlay-content">
                                    <div className="inner-content">
                                        <div className="title-box">                     
                                            <h6>
                                                <a href="http://www.starhomecinema.com/" target="_blank" rel="noreferrer noreferrer">www.starhomecinema.com</a>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">                               
                               <a href="/#">Star Home Cinema – India</a>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </section>
    
</div>
    <Footer />
</>
  );
}

export default about;