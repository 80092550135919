import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './deco-wood.css';
function decowood() {
  return (
    
    <>
    <div className="boxed_wrapper" id="decowood">
    <Helmet>
        <title>DECOWOOD are Absorption Panels with Outstanding Acoustic Performance</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="DECOWOOD are Absorption Panels with Outstanding Acoustic Performance" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/deco-wood" />
        <link rel="alternate" href="https://www.moovgroup.com/deco-wood" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section decowood-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link active">Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link">Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link"  >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link"  >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#deco-wood" className="nav-link"  role="tab" data-toggle="tab" aria-selected="true">Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link"  >Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                   
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
                <div role="tabpanel" className="tab-pane fade active show" id="deco-woodtec">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">09. </span>
                        <h1 className="title1">DECO WOOD</h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <span className="product9"></span>
                            </div>
                            <div className="top-text-box">
                                <h2 className="title">DECO WOOD</h2>
                                <p>DECOWOOD are absorption panels with outstanding acoustic performance with a combination of Non-Woven Polyester Fiber and Veneer Wooden Finish. These panels give an aesthetic feel to the areas and have good absorption properties.</p>
                            </div>
                            <div className="blog-single-bottom-content-box">
                                <p>DECOWOOD acoustic panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <ul>
                                    <li>Theatres</li>
                                    <li>Conference Rooms</li>
                                    <li>Educational Instititions</li>
                                    <li>Convention Centre's</li>
                                    <li>Banquet Halls</li>
                                    <li>Multiplexes</li>
                                    <li>Colleges</li>
                                    <li>Corporate Offices</li>
                                    <li>Auditoriums</li>
                                    <li>Hospitals</li>
                                </ul>
                            </div>
                            <span className="line-footer"></span>
                            <div className="portfolio-container2">
                                <Link to="/acoustic-doors" className="main-btn main-btn-2 mt-40">Acoustic Doors <span className="icon">+</span></Link>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            
            </div>
	    </div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default decowood;