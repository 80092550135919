import React from "react";
import '../slider.css';
import { Link } from "react-router-dom";
import banner1 from '../../images/kapsarc-moov-group.webp';
import banner2 from '../../images/museum-of-future-dubai-moov-group.webp';
import banner3 from '../../images/Rashid-Library-Dubai-moov-group.webp';
import banner4 from '../../images/ferrari-world-abu-dhabi-moov-group.webp';
function slide() {
    return (
<>
<div id="slider">
  <div className="slides">
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>KAPSARC</h1><span class="banner-subtitle">Saudi Arabia</span>
                <h2>We successfully executed a project of this size and complexity where our scope was supply & installation of acoustic wall panels, Echo Panels & Acoustic Treatment for the walls & movable partitions. It is a privilege to be a part of an iconic project</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner1} alt="KAPSARC"/>
        </div>
    </div>
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>The Museum of Future</h1><span class="banner-subtitle">Dubai</span>
                <h2>We are honored to be a part of this project, where we installed acoustical plaster ceiling in order to enhance the sound quality and reduce the noise levels in the auditorium and hall area of the Museum of the Future - a unique and futuristic exhibition space in Dubai that has a stunning torus-shaped shell that features an Arabic poem by Dubai’s ruler about the Emirate’s future</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner2} alt="The Museum of Future"/>
        </div>
    </div>
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>Mohammed Bin Rashid Library</h1><span class="banner-subtitle">Dubai</span>
                <h2>Moov - The leader in Acoustical Solutions feel delighted to be a part of yet another visionary project that was completed recently is the Mohammed Bin Rashid Library - a magnificent library that promotes reading, literacy and Arabic literature in Dubai and UAE. We installed an acoustical plaster ceiling in the auditorium to enhance the sound quality and reduce the noise levels in this space</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner3} alt="Mohammed Bin Rashid Library"/>
        </div>
    </div>
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>Ferrari World</h1><span class="banner-subtitle">Abu Dhabi</span>
                <h2>We are extremely fortunate to be part of this iconic project where our scope was to treat the walls with acoustic core lined with fabric. We installed fabric wrapped acoustic wall panel system on the wall to increase the sound absorption there by to reduce reverberation</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner4} alt="Ferrari World"/>
        </div>
    </div>
    
  </div>
  
</div>

    </>
    );
  }
  
  export default slide;