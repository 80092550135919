import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './woodtec.css';
function woodlinear() {
  return (
    
    <>
    <div className="boxed_wrapper" id="woodtec">
    <Helmet>
		<title>Wood SLAT WoodTEC Linear Acoustic Panels</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Wood SLAT WoodTEC Linear Acoustic Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/woodtec-linear-acoustic-panel" />
    	<link rel="alternate" href="https://www.moovgroup.com/woodtec-linear-acoustic-panel" hreflang="en-GB" />
    </Helmet>
    <Header />
	<div id="moov">
    <section className="breadcrumb-section woodtec-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="section-gap">            
        <div className="container">
            <div className="row">
                
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                    <div className="sidebar-wrapper">
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Categories</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/woodtec-linear-acoustic-panel" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Linear Acoustic Panel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec-perforated-acoustic-panel" className="nav-link">Perforated Acoustic Panel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec-slotted-acoustic-panel" className="nav-link">Slotted Acoustic Panel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec-designer-acoustic-panel" className="nav-link">Designer Acoustic Panel</Link></li>
                            </ul>
                        </div>
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link></li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link></li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link></li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link></li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link></li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link></li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link">Foam Stop Polyurethane Foam</Link></li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link">Diffuso</Link></li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link">Polymax</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link">Polysorb</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link">Polysorb-DECO</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">01. </span>
                        <h1 className="title1">WOODTEC LINEAR ACOUSTIC PANELS</h1>
                    </div>
                   
                    <div className="row">
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="woodtec-sub1"></div>
                                </div>
                                <div className="top-text-box">
                                <h1><span className="fabriwall">WOODTEC</span> LINEAR ACOUSTIC PANELS</h1>
                                <br />
                                <p>Linearo Series Acoustic Panels comes with grooving on the viewed face and perforations on the backside giving it an astounding appearance and impressive acoustic properties. </p>
								<p>These panels come with a linear design which in turn gives a linear theme to walls and ceilings making it an ideal choice for spaces such as lecture halls and meeting rooms. </p>
								<p>Based on sound absorption demands of different spaces, they are made available in different customizations with different groove widths and different distances between grooves.</p>
                                </div>
                                <div className="blog-single-bottom-content-box">
                                <h2>Features</h2>
                                <br />
                                    <div className="row">
                                        <div className="col-xl-6">
                                        <ul>
                                        <li>Reduces reverberation and echo</li>
                                        <li>Low formaldehyde and moisture resistant</li>
                                        <li>Highly aesthetic</li>
										<li>High impact resistance</li>
								        </ul>
                                        </div>
                                        <div className="col-xl-6">
                                        <ul>
                                        <li>Better speech intelligibility</li>
                                        <li>Washable and recyclable</li>
                                        <li>Easily installable</li>
										<li>Highly sustainable</li>
									    </ul>
                                        </div>
                                    </div>
					            </div>
                            </div>
                        </div>    
                    </div>
                    
                    <div className="row">
                        <div className="faq-content-box">
                            <div className="accordion-box">
                            
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>Specification</h4>
                                    </div>
                                    <div className="accord-content collapsed">
							        <table className="table">
								    <tbody>
									<tr>
									<th scope="row">Core</th>
										<td>MDF Standard | MDF Fire rated | MDF Moisture Resistant</td>
									</tr>
									<tr>
									<th scope="row">Thickness (mm)</th>
										<td> 16 mm | 18 mm | 20 mm</td>
									</tr>
									<tr>
									<th scope="row">Sizes (mm)</th>
										<td>Standard: 2430 x 160 mm Optional: 600x600 , 600x1200 , 517x1381 , 517x2400 , 128 x 2430, 197x2400 , 293x2400 , 197x1381 mm (custom sizes)</td>
									</tr>
									<tr>
									<th scope="row">Absorbent</th>
										<td>Mineral wool (50mm) </td>
									</tr>
									<tr>
									<th scope="row">Density</th>
										<td>700 kg/m3</td>
									</tr>
									<tr>
									<th scope="row">Flammability</th>
										<td>B-s2 d0</td>
									</tr>
									<tr>
									<th scope="row">Installation</th>
										<td>Walls: Omega metallic profiles, Clips, Wood battens Ceilings: Standard system T24, Clips</td>
									</tr>
                                    <tr>
									<th scope="row">Finishes</th>
										<td>Melamine, Veneer, Painted</td>
									</tr>
									</tbody>
								    </table>
                                    </div>
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn">
                                        <h4>Linearo Series - Type 1</h4>
                                    </div>
                                    <div className="accord-content">
                                    <table className="table">
									<thead>
									<tr>
								    <th scope="col">Absorbent (plenum)</th>
									<th scope="col">125Hz</th>
									<th scope="col">250Hz</th>
									<th scope="col">500Hz</th>
									<th scope="col">1KHz</th>
									<th scope="col">2KHz</th>
									<th scope="col">4KHz</th>
									<th scope="col">NRC</th>
									</tr>
									</thead>
									<tbody>
									<tr>
									<th scope="row">50mm</th>
								    	<td>0.40</td>
										<td>0.70</td> 
										<td>0.80</td>
										<td>0.75</td>
										<td>0.65</td>
										<td>0.65</td>
										<td>0.65</td>
									</tr>
									<tr>
									<th scope="row">200mm</th>
										<td>0.65</td>
										<td>0.65</td> 
										<td>0.65</td>
										<td>0.70</td>
										<td>0.70</td>
										<td>0.70</td>
										<td>0.70</td>
									</tr>
									</tbody>
								    </table>
                                    </div>
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn">
                                	    <h4>Linearo Series - Type 2</h4>
                                    </div>
                                    <div className="accord-content">
                                    <table className="table">
								    <thead>
									<tr>
									<th scope="col">Absorbent (plenum)</th>
									<th scope="col">125Hz</th>
									<th scope="col">250Hz</th>
									<th scope="col">500Hz</th>
									<th scope="col">1KHz</th>
									<th scope="col">2KHz</th>
									<th scope="col">4KHz</th>
									<th scope="col">NRC</th>
									</tr>
									</thead>
									<tbody>
									<tr>
									<th scope="row">50mm</th>
									    <td>0.30</td>
										<td>0.70</td> 
										<td>0.95</td>
										<td>0.95</td>
										<td>0.90</td>
										<td>0.90</td>
										<td>0.80</td>
									</tr>
									<tr>
									<th scope="row">200mm</th>
										<td>0.75</td>
										<td>0.85</td> 
										<td>0.85</td>
										<td>0.90</td>
										<td>0.95</td>
									    <td>0.95</td>
										<td>0.90</td>
									</tr>
									</tbody>
								    </table>
                                    </div>
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn">
                             	        <h4>Linearo Series - Type 3</h4>
                                    </div>
                                    <div className="accord-content">
                                    <table className="table">
							    	<thead>
									<tr>
								    <th scope="col">Absorbent (plenum)</th>
									<th scope="col">125Hz</th>
									<th scope="col">250Hz</th>
									<th scope="col">500Hz</th>
									<th scope="col">1KHz</th>
									<th scope="col">2KHz</th>
									<th scope="col">4KHz</th>
									<th scope="col">NRC</th>
									</tr>
									</thead>
									<tbody>
									<tr>
									<th scope="row">50mm</th>
										<td>0.05</td>
										<td>0.73</td> 
										<td>0.98</td>
										<td>0.64</td>
										<td>0.75</td>
										<td>0.42</td>
										<td>0.65</td>
									</tr>
									<tr>
									<th scope="row">200mm</th>
										<td>0.56</td>
										<td>0.86</td> 
										<td>0.72</td>
										<td>0.89</td>
										<td>0.75</td>
										<td>0.45</td>
										<td>0.70</td>
									</tr>
									</tbody>
								    </table>
                                    </div>
                                </div>
                                
					        </div>
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <a href="https://www.acousticalsurfaces.in/woodtec" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
	</div>
    </div>
<Footer />
</>
  );
}

export default woodlinear;