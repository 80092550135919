import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './polysorb.css';
function polydesign() {
  return (
    
    <>
    <div className="boxed_wrapper" id="polysorb">
    <Helmet>
        <title>Polysorb Fabric Wrapped Acoustical Designer Panels</title>
        <meta name="description" content=" "/>
        <meta property="og:title" content="Polysorb Fabric Wrapped Acoustical Designer Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/polysorb-designer-panels" />
        <link rel="alternate" href="https://www.moovgroup.com/polysorb-designer-panels" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section polysorb-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="section-gap">            
        <div className="container">
            <div className="row">
                
                <div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                    <div className="sidebar-wrapper">
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Categories</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/polysorb-acoustic-wall-panels" className="nav-link" >Acoustical Wall Panels</Link></li>
                                <li className="nav-item">
                                    <Link to="/polysorb-ceiling-panels" className="nav-link" >Acoustical Ceiling Panels</Link></li>
                                <li className="nav-item">
                                    <Link to="/polysorb-ceiling-baffles" className="nav-link" >Acoustical Ceiling Baffles</Link></li>
                                <li className="nav-item">
                                    <Link to="/polysorb-designer-panels" className="nav-link active" >Acoustical Designer Panels</Link></li>
                            </ul>
                        </div>
                        
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <ul className="categories clearfix">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link" >Fabrisorb</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link></li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link></li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link></li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link></li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link></li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link></li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link></li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link" >Deco Wood</Link></li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link></li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane Foam</Link></li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link></li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link" >Polysorb</Link></li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">04. </span>
                        <h1 className="title1">POLYSORB ACOUSTIC DESIGNER PANELS</h1>
                    </div>
                    
                    <div className="row">
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="polysorb-sub4"></div>
                                </div>
                                <div className="top-text-box">
                                    <h1><span className="fabriwall">POLYSORB </span>- ACOUSTIC DESIGNER PANELS</h1>
                                    <br />
                                    <p>Polysorb Wall Panels provide a better sound solution and are engineered for unparalleled sound absorption performance. They are lightweight and are free from allergies and irritations; courtesy of their chemical free polyester fiber core. They provide exceptional acoustical performance in terms of sound absorption as well as dampening. They are durable and can withstand shocks. They are available in different sizes and does not affect the aesthetics of the space while providing sound absorption.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="faq-content-box">
                            <div className="accordion-box">
							
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                        <h4>Specification</h4>
                                    </div>
                                    <div className="accord-content collapsed">
									    <table className="table">
										<tbody>
											<tr>
											<th scope="row">Material</th>
												<td>Tackable walls made from 100% polyester (60% PET - Recycled Fiber, 40% PET - virgin fiber)</td>
											</tr>
											<tr>
											<th scope="row">Thickness (mm)</th>
												<td> 1/2", 1″ and 2"</td>
											</tr>
											<tr>
											<th scope="row">Sizes (mm)</th>
												<td>Standard Size: 48″ × 96″ Cut to Size/Custom Sizes: Up to : 48″ × 96″ For custom sizes, please call us for details. Ceiling Tiles: 23¾″ × 47¾″</td>
											</tr>
											<tr>
											<th scope="row">Weight</th>
												<td>1/2" thick 12.5# per cubic feet, 1" thick 7.5# per cubic feet, 2" thick 5# per cubic feet</td>
											</tr>
                                            <tr>
											<th scope="row">Colours</th>
												<td>All colors available</td>
											</tr>
											<tr>
											<th scope="row">Flammability</th>
												<td>className A Fire Rated per ASTM E84 Flame Spread Index = 15 Smoke Developed Index = 250</td>
											</tr>
											<tr>
											<th scope="row">Installation</th>
												<td>Wall Panels: Adhesive, Direct Attach, Z-Clip, Stick Pin options available Ceiling Tiles: Lay-In, Adhesive
												</td>
									        </tr>
										    </tbody>
									    </table>
                                    </div>
                                </div>
                                
                                <div className="accordion accordion-block">
                                    <div className="accord-btn active">
                                      <h4>REVERBERATION</h4>
                                    </div>
                                    <div className="accord-content collapsed">
                                        <table className="table">
										<thead>
											<tr>
											<th scope="col">Thickness</th>
											<th scope="col">125Hz</th>
											<th scope="col">250Hz</th>
											<th scope="col">500Hz</th>
											<th scope="col">1KHz</th>
											<th scope="col">2KHz</th>
											<th scope="col">4KHz</th>
											<th scope="col">NRC</th>
											</tr>
										</thead>
										<tbody>
											<tr>
											<th scope="row">20mm</th>
												<td>0.05</td>
												<td>0.22</td> 
												<td>0.64</td>
												<td>0.92</td>
												<td>1.04</td>
												<td>1.02</td>
												<td>0.70</td>
											</tr>
											<tr>
											<th scope="row">25mm</th>
												<td>0.14</td>
												<td>0.27</td> 
												<td>0.80</td>
												<td>1.11</td>
												<td>1.14</td>
												<td>1.14</td>
												<td>0.85</td>
											</tr>
											<tr>
											<th scope="row">40mm</th>
												<td>0.21</td>
												<td>0.65</td> 
												<td>0.95</td>
												<td>0.99</td>
												<td>1.01</td>
												<td>1.02</td>
												<td>0.90</td>
											</tr>
											<tr>
											<th scope="row">50mm</th>
												<td>0.22</td>
												<td>0.81</td> 
												<td>1.24</td>
												<td>1.30</td>
												<td>1.21</td>
												<td>1.16</td>
												<td>1.15</td>
											</tr>
										</tbody>
									    </table>
                                    </div>
                                </div>
                                
							</div>
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                        <a href="https://www.acousticalsurfaces.in/polysorb" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default polydesign;