import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './fabrisorb.css';
function fabrisorb() {
  return (
    
    <>
    <div className="boxed_wrapper" id="fabrisorb">
    <Helmet>
        <title>Fabrisorb - Fabric Acoustic Panels Supplier</title>
        <meta name="description" content="Fabrisorb - Fabric Wrapped Acoustical Wall & Ceiling Panels "/>
        <meta property="og:title" content="Sound Absorbing Fabrisorb Acoustic Panels" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/fabrisorb" />
        <link rel="alternate" href="https://www.moovgroup.com/fabrisorb" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section fabrisorb-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="#fabrisorb" className="nav-link active" role="tab" data-toggle="tab" aria-selected="true">Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link" >Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link"  >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link"  >Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pu-foam" className="nav-link" >Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link"  >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
        <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
           
            <div role="tabpanel" className="tab-pane fade active show" id="fabrisorb">
                    <div className="section-title mb-40">
                        <span className="line"></span>
                        <span className="title-tag">01. </span>
                        <h1 className="title1">Noise S.T.O.P. Fabrisorb <sup>Tm</sup></h1>
                    </div>
                    <div className="blog-post">
                        <div className="single-blog-post">
                            <div className="main-image-box">
                                <div className="product1"></div>
                            </div>
                            <div className="top-text-box">
                                <p>Fabrisorb acoustic wall and ceiling panels can significantly improve the sound quality of the space which it is used. Usage of Fabrisorb acoustic panels reduce the reverberation and background noise making the sound more precise and clearer.  This quality of these fabric covered acoustic panels makes it perfect for spaces such as offices, schools, meeting rooms, music rooms, hotels, banquet halls, auditoriums, recording studios, broadcasting studios etc where the sound quality is particularly of paramount importance. </p>
                                <p>Some spaces such as classNamerooms in a school will require the acoustic panels to absorb sound energy in mid and high frequencies so that the speech intelligibility is at its best. It will avoid unnecessary strain for teachers, and they can be heard clearly for optimized learning. Few spaces such as sports halls will require impact resistant panels and few like swimming pool spaces will require acoustic panels which will not take moisture and mould. Fabrisorb acoustic panels are the right solution for all such spaces.</p>
                            </div>
                            
						    <div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Aesthetically pleasing</li>
                                            <li>High sound absorption performance</li>
                                            <li>Available with various edges and colours</li>
											<li>Fire resistant</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Environmentally friendly</li>
                                            <li>Humidity resistance</li>
						                    <li>Easily cleanable and can be vacuumed</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
							
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Offices</li>
                                            <li>Schools</li>
                                            <li>Meeting Rooms</li>
											<li>Hotels</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Auditorium</li>
                                            <li>Recording Studios</li>
											<li>Conference halls</li>
                                            <li>Broadcasting Studios</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="blog-single-bottom-content-box">
                                <h2>Categories </h2>
                                <div className="row">
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/fabrisorb-acoustic-wall-panels"><h3>Acoustical Wall Panels</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>Acoustical<br /> Wall Panels</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/fabrisorb-acoustic-wall-panels"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/fabrisorb-acoustic-clouds"><h3>Acoustical Clouds</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Acoustical<br /> Clouds</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/fabrisorb-acoustic-clouds"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/fabrisorb-acoustic-baffles"><h3>Acoustical Baffles</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Acoustical<br /> Baffles</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>These acoustic baffles are perfect for any space where good speech privacy or speech intelligibility is important</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/fabrisorb-acoustic-baffles"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product-sub4"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/fabrisorb-acoustic-designer-panels"><h3>Acoustical Designer Panels</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>04</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Acoustical<br /> Designer Panels</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>They also add a 3D effect to the whole spcae which improves the overall looks of the space</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/fabrisorb-acoustic-designer-panels"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
				            </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/fabrisorb" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
            
            </div>
        
        </div>
	</div>
    </section>
    </div>
</div>
<Footer />
</>
  );
}

export default fabrisorb;
    