import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './pu-foam.css';
function pufoam() {
  return (
    
    <>
    <div className="boxed_wrapper" id="pu-foam">
    <Helmet>
        <title>Pufoam Noise Stop Polyurethane Foams</title>
        <meta name="description" content=""/>
        <meta property="og:title" content="Pufoam Noise Stop Polyurethane Foams" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/pu-foam" />
        <link rel="alternate" href="https://www.moovgroup.com/pu-foam" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section pufoam-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">PRODUCTS</h1>
            <ul className="breadcrumb-nav">
                <li><Link to="/#">Moov Group</Link></li>
                <li>Products</li>
            </ul>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	<div className="container">
        <div className="row">
			
			<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12 order-lg-1 order-sm-2">
                <div className="sidebar-wrapper">
                    <div className="single-sidebar categories-box">
                        <div className="sidebar-title">
                            <div className="title">Products</div>
                        </div>
                        <div className="container-fluid">
                            <ul className="nav categories clearfix product-tabs" role="tablist">
                                <li className="nav-item">
                                    <Link to="/fabrisorb" className="nav-link">Fabrisorb</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabrisorb-deco" className="nav-link">Fabrisorb-DECO</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/fabriwall" className="nav-link" >Fabriwall</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/decopanel" className="nav-link" >D'ecopanel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/woodtec" className="nav-link" >WoodTec</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/echo-eliminator" className="nav-link" >Echo Eliminator</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/vinyl-barrier" className="nav-link" >Noise STOP Vinyl Barrier</Link>
								</li>
                                <li className="nav-item">
                                    <Link to="/eco-therm" className="nav-link" >Eco Therm</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/deco-wood" className="nav-link"  >Deco Wood</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/acoustic-doors" className="nav-link" >Acoustic Doors</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="#pu-foam" className="nav-link" role="tab" data-toggle="tab" aria-selected="true">Foam Stop Polyurethane</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/diffuso" className="nav-link" >Diffuso</Link>
                                </li>
								<li className="nav-item">
								    <Link to="/polymax" className="nav-link" >Polymax</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb" className="nav-link"  >Polysorb</Link>
								</li>
								<li className="nav-item">
								    <Link to="/polysorb-deco" className="nav-link" >Polysorb-DECO</Link>
								</li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div> 
            
            <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12 order-lg-2 order-sm-1">
                
            <div role="tabpanel" className="tab-pane fade active show" id="polyurethane">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">11. </span>
                    <h1 className="title1">POLYURETHANE FOAMS</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product11"></div>
                        </div>
                        <div className="top-text-box">
                            <p>Acoustic foam is an open celled foam used for acoustic treatment. It attenuates airborne sound waves by increasing air resistance, thus reducing the amplitude of the waves. The energy is dissipated as heat.Acoustic foam comes in a variety of sizes and can be attached to walls, ceilings, doors, and other features of a room to control noise levels, vibration, and echoes.</p>
            
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                        <h2>Categories </h2>
                            <div className="row">
                            
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/pu-foam-pyramid-design"><h3>Foam Stop Pyramid Design</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>Foam Stop<br /> Pyramid Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>These polyurethane pyramid panels are developed to absorb maximum acoustical energy using minimum weight and thickness</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/pu-foam-pyramid-design"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/pu-foam-flat-design"><h3>Foam Stop Plain (FLAT) Design</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop Plain<br /> (FLAT) Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>PU Plain panels come in sound wave pattern and have the advantage of high absorptive surface area</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/pu-foam-flat-design"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/pu-foam-cylindrical-panels"><h3>Foam Stop Cylindrical Design</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop<br /> Cylindrical Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>These PU cylindrical panels are perfect for spaces where there are echoes and excessive reverberation time related issues</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/pu-foam-cylindrical-panels"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub4"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/pu-foam-bass-trap-design"><h3>Foam Stop Bass Trap Design</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>04</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop<br /> Bass Trap Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>PU bass trap panels effectively reduce the reverberations in a uniform manner across the desired acoustic spectrum</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/pu-foam-bass-trap-design"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub5"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <Link to="/pu-foam-eggcrate-design"><h3>Foam Stop Eggcrate Design</h3></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>05</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop<br /> Eggcrate Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>These panels have an unique shape which helps in improving sound absorption compared to conventional flat surfaces</p>
                                                </div>
                                                <div className="read-more">
                                                    <Link to="/pu-foam-eggcrate-design"><span className="icon-next"></span></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
				            </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <a href="https://www.acousticalsurfaces.in/foamstop-pu" className="main-btn main-btn-2 mt-40" target="_blank" rel="noreferrer noreferrer">learn more <span className="icon">+</span></a>
                </div>
            </div>
            </div>
        </div>
	</div>
    </section>
    </div>
    </div>
<Footer />
</>
  );
}

export default pufoam;