import React from "react";
import '../slider.css';
import { Link } from "react-router-dom";
import banner1 from '../../images/ferrari-world-abu-dhabi-moov-group.webp';
import banner2 from '../../images/presidential-palace-abu-dhabi-moov-group.webp';
import banner3 from '../../images/Auditorium-AbuDhabi-Port-Authority-moov-group.webp';
import banner4 from '../../images/Louvre-Abu-Dhabi-moov-group.webp';
function slide() {
    return (
<>
<div id="slider">
  <div className="slides">
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>Ferrari World</h1><span class="banner-subtitle">Abu Dhabi</span>
                <h2>We are extremely fortunate to be part of this iconic project where our scope was to treat the walls with acoustic core lined with fabric. We installed fabric wrapped acoustic wall panel system on the wall to increase the sound absorption there by to reduce reverberation</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner1} alt="Ferrari World"/>
        </div>
    </div>
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>Presidential Palace</h1><span class="banner-subtitle">Abu Dhabi</span>
                <h2>We are delighted to share with you our latest, challenging and rewarding project, where we supplied and installed acoustic panels, using our expertise and experience in acoustic solutions to deliver the best results possible for this great iconic building. We are proud to be a part of this project and we thank our client and the architect for trusting us with this opportunity</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner2} alt="Presidential Palace"/>
        </div>
    </div>
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>Port Authority</h1><span class="banner-subtitle">Abu Dhabi</span>
                <h2>Acoustic Design, Supply, Installation, Testing and Commissioning for the complete auditorium</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner3} alt="Port Authority"/>
        </div>
    </div>
    <div className="slider">
        <div className="content banner">
            <div className="content-txt">
                <h1>Louvre Museum</h1><span class="banner-subtitle">Abu Dhabi</span>
                <h2>We have been instrumental in treating the Echo and optimizing the Reverberation Times by introducing more than 22,000 SQM of acoustic wood wool</h2>
                <div class="btn-box">
                    <Link to="/projects" class="play-button"><span class="icon-next"></span></Link>
                    <Link to="/projects" class="slide-style2-button">More About the project</Link>
                </div>
            </div>
        </div>
        <div className="image">
            <img src={banner4} alt="Louvre Museum"/>
        </div>
    </div>
    
  </div>
  
</div>

    </>
    );
  }
  
  export default slide;