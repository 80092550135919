import React from "react";
import {Helmet} from "react-helmet";
import { Link } from "react-router-dom";
import Header from '../header';
import Footer from '../footer';
import './products.css';
function products() {
  return (
    
    <>
    <div className="boxed_wrapper" id="product">
    <Helmet>
        <title>Our Acoustic Products | Sound Absorbing Products</title>
        <meta name="description" content="With over 400 specialty soundproofing and noise control products and materials. Call 04-3310000 to speak with one of our knowledgeable soundproofing and noise control specialists."/>
        <meta property="og:title" content="Acoustic Products" />
        <meta property="og:description" content=""/>
        <meta property="og:image" content="../../images/moovgroup-office.webp" />
        <link rel="canonical" href="https://www.moovgroup.com/products" />
        <link rel="alternate" href="https://www.moovgroup.com/products" hreflang="en-GB" />
    </Helmet>
    <Header />
    <div id="moov">
    <section className="breadcrumb-section products-bg">
        <div className="container">
            <span className="page-tag">OUR</span>
            <h1 className="page-title">ACOUSTIC PRODUCTS</h1>
        </div> 
    </section>
    
    <section className="advanced-tabs section-gap">            
	    <div className="container">
            <div className="row">
    
	    		<div className="col-xl-3 col-lg-4 col-md-7 col-sm-12">
                    <div className="sidebar-wrapper">
                        <div className="single-sidebar categories-box">
                            <div className="sidebar-title">
                                <div className="title">Products</div>
                            </div>
                            <div className="container-fluid">
                                <ul className="nav categories clearfix product-tabs" role="tablist">
                                    <li className="nav-item">
                                        <a href="#fabrisorb" className="nav-link active" role="tab" data-toggle="tab" aria-selected="false">Fabrisorb</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#fabrisorb-deco" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Fabrisorb-DECO</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#fabriwall" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Fabriwall</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#decopanel" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">D'ecopanel</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#woodtec" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">WoodTec</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#echo-eliminator" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Echo Eliminator</a>
                                    </li>
								    <li className="nav-item">
    								    <a href="#vinyl-barrier" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Noise STOP Vinyl Barrier</a>
								    </li>
                                    <li className="nav-item">
                                        <a href="#eco-therm" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Eco Therm</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#deco-woodtec" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Deco Wood</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#sound-barrier" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Acoustic Doors</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#polyurethane" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Foam Stop Polyurethane</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#diffuso" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Diffuso</a>
                                    </li>
								    <li className="nav-item">
    								    <a href="#polymax" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Polymax</a>
								    </li>
								    <li className="nav-item">
    								    <a href="#polysorb" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Polysorb</a>
								    </li>
								    <li className="nav-item">
    								    <a href="#polysorb-deco" className="nav-link" role="tab" data-toggle="tab" aria-selected="false">Polysorb-DECO</a>
								    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> 
            
            
                <div className="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                
                    <div role="tabpanel" className="tab-pane fade active show" id="fabrisorb">
                        <div className="section-title mb-40">
                            <span className="line"></span>
                            <span className="title-tag">01. </span>
                            <h1 className="title1">Noise S.T.O.P. Fabrisorb <sup>Tm</sup></h1>
                        </div>
                        <div className="blog-post">
                            <div className="single-blog-post">
                                <div className="main-image-box">
                                    <div className="product1"></div>
                                </div>
                                <div className="top-text-box">
                                    <p>Fabrisorb acoustic wall and ceiling panels can significantly improve the sound quality of the space which it is used. Usage of Fabrisorb acoustic panels reduce the reverberation and background noise making the sound more precise and clearer.  This quality of these fabric covered acoustic panels makes it perfect for spaces such as offices, schools, meeting rooms, music rooms, hotels, banquet halls, auditoriums, recording studios, broadcasting studios etc where the sound quality is particularly of paramount importance. </p>
                                    <p>Some spaces such as classNamerooms in a school will require the acoustic panels to absorb sound energy in mid and high frequencies so that the speech intelligibility is at its best. It will avoid unnecessary strain for teachers, and they can be heard clearly for optimized learning. Few spaces such as sports halls will require impact resistant panels and few like swimming pool spaces will require acoustic panels which will not take moisture and mould. Fabrisorb acoustic panels are the right solution for all such spaces.</p>
                                </div>  

						        <div className="blog-single-bottom-content-box">
                                    <h2>Features </h2>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <ul>
                                                <li>Aesthetically pleasing</li>
                                                <li>High sound absorption performance</li>
                                                <li>Available with various edges and colours</li>
											    <li>Fire resistant</li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-6">
                                            <ul>
                                                <li>Environmentally friendly</li>
                                                <li>Humidity resistance</li>
						                        <li>Easily cleanable and can be vacuumed</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
							
                                <div className="blog-single-bottom-content-box">
                                    <h2>Applications </h2>
								    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <ul>
                                                <li>Offices</li>
                                                <li>Schools</li>
                                                <li>Meeting Rooms</li>
											    <li>Hotels</li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-6">
                                            <ul>
                                                <li>Auditorium</li>
                                                <li>Recording Studios</li>
											    <li>Conference halls</li>
                                                <li>Broadcasting Studios</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="blog-single-bottom-content-box">
                                    <h2>Categories </h2>
                                    <div className="row">
                                
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                        <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                            <div className="img-holder">
                                                <div className="product-sub1"></div>
                                                <div className="overlay-style-two"></div>
                                                <div className="overlay-content-box">
                                                    <div className="box">
                                                        <div className="inner">
                                                            <div className="icon">
                                                                <span className="fa fa-trophy"></span>
                                                            </div>
                                                            <div className="title">
                                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Wall Panels</h3></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <div className="inner-content">
                                                    <div className="top">
                                                        <div className="icon">
                                                            <span className="fa fa-hand-o-right"></span>
                                                        </div>
                                                        <div className="count">
                                                            <h1>01</h1>
                                                        </div>
                                                    </div>
                                                    <div className="bottom">
                                                        <div className="title">
                                                            <br /><h3>Acoustical<br /> Wall Panels</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overlay-content">
                                                    <div className="text">
                                                        <p>Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces</p>
                                                    </div>
                                                    <div className="read-more">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                        <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                            <div className="img-holder">
                                                <div className="product-sub2"></div>
                                                <div className="overlay-style-two"></div>
                                                <div className="overlay-content-box">
                                                    <div className="box">
                                                        <div className="inner">
                                                            <div className="icon">
                                                                <span className="fa fa-trophy"></span>
                                                            </div>
                                                            <div className="title">
                                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Clouds</h3></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <div className="inner-content">
                                                    <div className="top">
                                                        <div className="icon">
                                                            <span className="fa fa-hand-o-right"></span>
                                                        </div>
                                                        <div className="count">
                                                            <h1>02</h1>
                                                        </div>
                                                    </div>
                                                    <div className="bottom">
                                                        <div className="title">
                                                        <br /><h3>Acoustical<br /> Clouds</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overlay-content">
                                                    <div className="text">
                                                        <p>Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces</p>
                                                    </div>
                                                    <div className="read-more">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer">
                                                        <span className="icon-next"></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                        <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                            <div className="img-holder">
                                                <div className="product-sub3"></div>
                                                <div className="overlay-style-two"></div>
                                                <div className="overlay-content-box">
                                                    <div className="box">
                                                        <div className="inner">
                                                            <div className="icon">
                                                                <span className="fa fa-trophy"></span>
                                                            </div>
                                                            <div className="title">
                                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Baffles</h3></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <div className="inner-content">
                                                    <div className="top">
                                                        <div className="icon">
                                                            <span className="fa fa-hand-o-right"></span>
                                                        </div>
                                                        <div className="count">
                                                            <h1>03</h1>
                                                        </div>
                                                    </div>
                                                    <div className="bottom">
                                                        <div className="title">
                                                        <br /><h3>Acoustical<br /> Baffles</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overlay-content">
                                                    <div className="text">
                                                        <p>These acoustic baffles are perfect for any space where good speech privacy or speech intelligibility is important</p>
                                                    </div>
                                                    <div className="read-more">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer">
                                                        <span className="icon-next"></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                        <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                            <div className="img-holder">
                                                <div className="product-sub4"></div>
                                                <div className="overlay-style-two"></div>
                                                <div className="overlay-content-box">
                                                    <div className="box">
                                                        <div className="inner">
                                                            <div className="icon">
                                                                <span className="fa fa-trophy"></span>
                                                            </div>
                                                            <div className="title">
                                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Designer Panels</h3></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-holder">
                                                <div className="inner-content">
                                                    <div className="top">
                                                        <div className="icon">
                                                            <span className="fa fa-hand-o-right"></span>
                                                        </div>
                                                        <div className="count">
                                                            <h1>04</h1>
                                                        </div>
                                                    </div>
                                                    <div className="bottom">
                                                        <div className="title">
                                                        <br /><h3>Acoustical<br /> Designer Panels</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="overlay-content">
                                                    <div className="text">
                                                        <p>They also add a 3D effect to the whole spcae which improves the overall looks of the space</p>
                                                    </div>
                                                    <div className="read-more">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer">
                                                        <span className="icon-next"></span></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               
				                </div>
				            </div>
                       
                        </div>
                    </div>
                    <span className="line-footer"></span>
                    <div className="portfolio-container2">
                    <Link to="/fabrisorb-deco" className="main-btn main-btn-2 mt-40">Fabrisorb DECO <span className="icon">+</span></Link>
                    </div>
                </div>
            
                <div role="tabpanel" className="tab-pane fade" id="fabrisorb-deco">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">02. </span>
                    <h1 className="title1">FABRISORB-DECO</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product2"></div>
                        </div>
                        <div className="top-text-box">
                            <p>Acoustic panel is an essential part of creating a high-quality listening environment whether you are creating a space like recording studio, home theatre, a conference room, office room, classNameroom or even a living room where all what you want is to improve the sound quality. If you are serious about how these spaces sound, then you need to start thinking about what to use in such spaces to create a more balanced and accurate sound which can enhance your listening or recording experience.</p>
                            <p>For perfect balance of optimal sound quality and the aesthetics opt for Fabrisorb-Deco, where acoustics meets the eyes – Colours . As the name sounds Fabrisorb-Deco is the decorative version of Fabrisorb acoustic panels, where the surface is covered with finest quality of acoustic felt to compliment the architects colour concept. Fabrisorb-Deco comes with wide selection of acoustic felt colours to choose from.</p>
                        </div>
                        
						<div className="blog-single-bottom-content-box">
                            <h2>Features </h2>
                            <div className="row">
                                <div className="col-xl-6">
                                    <ul>
                                        <li>Aesthetically pleasing</li>
                                        <li>High sound absorption performance</li>
                                        <li>Available with various edges and colours</li>
										<li>Fire resistant</li>
                                    </ul>
                                </div>
                                <div className="col-xl-6">
                                    <ul>
                                        <li>Environmentally friendly</li>
                                        <li>Humidity resistance</li>
						                <li>Easily cleanable and can be vacuumed</li>
						                <li>Easy to apply</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
						
                        <div className="blog-single-bottom-content-box">
                            <h2>Applications </h2>
							<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                            <div className="row">
                                <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                </div>
                                <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                      
                        <div className="blog-single-bottom-content-box">
                            <h2>Categories </h2>
                            <div className="row">
                           
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product-sub1"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Wall Panels</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>01</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Acoustical<br /> Wall Panels</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product-sub2"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Clouds</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>02</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br /> Clouds</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Acoustically enhanced decorative wall panels are designed for all types of walls that improves the acoustic quality of all types of spaces</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product-sub3"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Baffles</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>03</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br /> Baffles</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>These acoustic baffles are perfect for any space where good speech privacy or speech intelligibility is important</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product-sub4"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Designer Panels</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>04</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br /> Designer Panels</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>They also add a 3D effect to the whole spcae which improves the overall looks of the space</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/fabrisorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
				        </div>
				    </div>
                    
                </div>
            </div>
            <span className="line-footer"></span>
            <div className="portfolio-container2">
                <Link to="/fabriwall" className="main-btn main-btn-2 mt-40">Fabriwall <span className="icon">+</span></Link>
            </div>
        </div>
        
            <div role="tabpanel" className="tab-pane fade" id="fabriwall">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">03. </span>
                    <h1 className="title1">FabriWALL</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product3"></div>
                        </div>
                        <div className="top-text-box">
                            <p>Fabriwall acoustical panel is a versatile, sustainable, and innovative track solution that empowers architects and designers to meet their desired specifications and criteria for any project. The track system comprises a framework, with the infill placed between it. The fabric is then stretched into the interlocking jaws of the track, providing the entire system with a seamless finish.</p>
                                    
                            
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
							    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Offices</li>
                                            <li>Schools</li>
                                            <li>Meeting Rooms</li>
										    <li>Hotels</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Auditorium</li>
                                            <li>Recording Studios</li>
										    <li>Conference halls</li>
                                            <li>Broadcasting Studios</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
						    <div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Aesthetically pleasing</li>
                                            <li>High sound absorption performance</li>
                                            <li>Available with various edges and colours</li>
											<li>Fire resistant</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-6">
                                        <ul>
                                            <li>Environmentally friendly</li>
                                            <li>Humidity resistance</li>
						                    <li>Easily cleanable and can be vacuumed</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
							
                            <div className="blog-single-bottom-content-box">
                                <h2>Categories </h2>
                                <div className="row">
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product3-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/fabriwall" target="_blank" rel="noreferrer noreferrer"><h3>FabriWALL TRACK</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>FabriWALL TRACK</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>The fabric is stretched over the semi rigid absorption panel to control sound</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/fabriwall" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product3-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/fabriwall" target="_blank" rel="noreferrer noreferrer"><h3>FabriWALL FABRIC</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>FabriWALL FABRIC</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Acoustical stretch fabric systems are in high demand in office spaces, lobbies, classNamerooms, theaters and more</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/fabriwall" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product3-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/fabriwall" target="_blank" rel="noreferrer noreferrer"><h3>FabriWALL PANEL</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>FabriWALL PANEL</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>PANEL is the unseen element of the system installed inside Track's frame and fabric</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/fabriwall" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
					        </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <Link to="/decopanel" className="main-btn main-btn-2 mt-40">D'ecopanel <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="decopanel">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">04. </span>
                    <h1 className="title1">D'ecopanel</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product4"></div>
                        </div>
                        <div className="top-text-box">
                            <p>D’ecopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core. They form an effective remedy for reverberation related issues in interior spaces and are customizable to any design depending upon the customer demands. These <Link to="/pet-panel">PET Panels</Link> are available in 21 different color options.</p>
                            
						    <div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Aesthetically pleasing</li>
                                        <li>High sound absorption performance</li>
                                        <li>Available with various edges and colours</li>
									    <li>Fire resistant</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Environmentally friendly</li>
                                        <li>Humidity resistance</li>
										<li>Easily cleanable and can be vacuumed</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
						    
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
                       
                        <div className="blog-single-bottom-content-box">
                            <h2>Categories </h2>
                            <div className="row">
                            
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product4-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer"><h3>D'ecopanel Plain Colors</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>D'ecopanel<br /> Plain Colors</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product4-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer"><h3>D'ecopanel Grooved Design</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>D'ecopanel<br /> Grooved Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product4-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer"><h3>D'ecopanel Print Design</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>D'ecopanel<br /> Print Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product4-sub4"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer"><h3>D'ecopanel Tiles</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>04</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>D'ecopanel<br /> Tiles</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product4-sub5"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer"><h3>D'ecopanel Plain Print Rolls</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>05</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>D'ecopanel<br /> Plain Print Rolls</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product4-sub6"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer"><h3>Decopanel Baffles Clouds</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>06</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Decopanel<br /> Baffles Clouds</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Decopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/decopanel" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
					        </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <Link to="/woodtec" className="main-btn main-btn-2 mt-40">WoodTEC <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="woodtec">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">05. </span>
                    <h1 className="title1">WoodTEC</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product5"></div>
                        </div>
                        <div className="top-text-box">
                            <p>WoodTEC panels are acoustic panels with high acoustic performance applicable for both walls and ceilings. The panels are made of MDF a perfect blend of aesthetical and technical qualities.</p>
                            
                            <div className="blog-single-bottom-content-box">
                            <h2>Applications </h2>
							<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p><br />
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditoriums</li>
                                        <li>Meeting Rooms</li>
                                        <li>Theatres</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Lecture Halls</li>
									    <li>Educational Instituition</li>
                                        <li>Airports</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                            <h2>Categories </h2>
                            <div className="row">
                            
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer"><h3>Linear Acoustic Panel</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>Linear Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Linearo Series Acoustic Panels comes with grooving on the viewed face and perforations</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer"><h3>Perforated Acoustic Panel</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Perforated Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Perfero acoustic panels are the go to choice when it comes to economically achieving high absorption characteristics</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer"><h3>Slotted Acoustic Panel</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Slotted Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Slotto acoustic panels help to achieve high absorption and acoustic levels at a medium cost</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product5-sub4"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer"><h3>Designer Acoustic Panel</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>04</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Designer Acoustic Panel</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>Designo acoustic panels comes in painted finish only and improves the visual outlook of the spaces by a great deal</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/woodtec" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <Link to="/echo-eliminator" className="main-btn main-btn-2 mt-40">Echo Eliminator <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="echo-eliminator">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">06. </span>
                    <h1 className="title1">ECHO ELIMINATOR</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product6"></div>
                        </div>
                        <div className="top-text-box">
                            <h1><span className="fabriwall"> </span>Bonded Acoustical Cotton (B.A.C.)</h1><br /><br />
                            <h4>The Most Cost Effective Acoustical Absorbing Material On The Market </h4><br />
						    <p>Echo Eliminator is a high-performance acoustical material made from recycled cotton. </p>
							<p>These cotton fabric panels are ideal for noise control applications.</p>
							<p>Echo Eliminator can be used as an acoustic wall panel or hanging baffle. </p>
							<p>Common applications of these cotton fabric panels are school gyms, classNamerooms, lunch rooms and any application where a high-performance noise control product is needed.</p> 
							<p>Echo Eliminator cotton fabric panels are eligible for LEED™ credits, className A fire rated and 100% recyclable. </p>
                        </div>
                    </div>
                    
                   
					<div className="blog-single-bottom-content-box">
					    <h2>Applications </h2>
                        <div className="row">
                            <div className="col-xl-6">
                            <ul>
                                <li>Recycled Cotton (Green Acoustical Material)</li>
                                <li>className A – Non-Flammable (Per ASTM E-84)</li>
                                <li>Lightweight</li>
								<li>Easy to Install</li>
								<li>Low Cost</li>
								<li>High Light Reflectance</li>
								<li>High Performance Acoustical Absorption</li>
								<li>Relocatable (with Grommets)</li>
                            </ul>
                            </div>
                        </div>
                    </div>
					
                    <div className="blog-single-bottom-content-box">
                        <h2>Product Photos </h2>
                        <div className="row">
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub1"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub2"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub3"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub4"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub5"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub6"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub7"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub8"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
				    </div>
                    
                    <div className="blog-single-bottom-content-box">
                        <h2>Color Options</h2>
                        <div className="row">
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub9"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub10"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub11"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub12"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub13"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1">
                                    <div className="img-holder">
                                        <div className="product6-sub14"></div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="overlay-content">
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/echo-eliminator" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
				    </div>
                       
				</div>
				<span className="line-footer"></span>
				<div className="portfolio-container2">
                    <Link to="/vinyl-barrier" className="main-btn main-btn-2 mt-40">Vinyl Barrier <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="vinyl-barrier">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">07. </span>
                    <h1 className="title1">Mass Loaded Vinyl Barrier</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product7"></div>
                        </div>
                        <div className="top-text-box">
                            <h1><span className="fabriwall"> </span>Noise S.T.O.P.™ Vinyl Barrier</h1><br />
                            <p> When noise and sound transmission needs to be effectively controlled, Mass Loaded Vinyl Noise Barrier is the solution to build noise barriers and soundproof walls. </p>
                        </div>
                    </div>
                    <div className="blog-single-bottom-content-box">
					    <h2>Product Specs </h2>
                        <div className="row">
                            <div className="col-xl-12">
                            <ul>
                                <li className="li-specs"><p>Material<span className="specs">Mass Loaded Vinyl Sound Barrier</span></p></li>
                                <li className="li-specs"><p>Pattern<span className="specs">Smooth Finish</span></p></li>
                                <li className="li-specs"><p>Features<span className="specs">This acoustical barrier is made from high density limp material to reduce noise transmission.</span></p></li>
								<li className="li-specs"><p>Applications<span className="specs">Noise S.T.O.P. Vinyl Barrier reduces noise transmission through ceilings, walls, floors, machinery enclosures, ductwork. Apply over suspended ceilings on studs or joints.</span></p></li>
								<li className="li-specs"><p>Thickness<span className="specs">1/16″ – 1/2 lb/sf; 1/8″ – 1 lb/sf; 1/4″ – 2 lb/sf</span></p></li>
								<li className="li-specs"><p>Sizes<span className="specs">54″ Wide × 20′, 30′, or 60′ Rolls – 2′×2′, 2′×4′ Sheets, Die Cutting Available</span></p></li>
								<li className="li-specs"><p>Colors<span className="specs">Black, Grey (Reinforced), Tan and Clear (Transparent or Translucent) - Aluminized Mylar / Foil can be added to one side</span></p></li>
								<li className="li-specs"><p>Flammability<span className="specs">UL 94VO</span></p></li>
								<li className="li-specs"><p>Installation<span className="specs">Acoustical barrier material can be nailed, screwed, stapled or can be reinforced and grommeted and hung like a curtain.</span></p></li>
							</ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="portfolio-container2">
                    <Link to="/eco-therm" className="main-btn main-btn-2 mt-40">ECO Therm <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="eco-therm">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">08. </span>
                    <h1 className="title1">ECO THERM</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <span className="product8"></span>
                        </div>
                        <div className="top-text-box">
                            <h2 className="title">ECO THERM</h2>
                            <p>The carbon-neutral ECOTHERM® acoustic spraying  is made from a mixture of recycled paper, wood adhesive, and water. It is professionally installed and effectively absorbs reverberation due to its tubular cellulosic fiber structure.</p>
                            <p>ECOTHERM® is an environmentally friendly product, as it is based on recycled paper. It can be directly installed on concrete or plasterboard. You can choose the layer thickness between 5 to 40 mm, depending on the level of damping desired. For specific applications, layer thicknesses greater  than 40 mm are also possible.</p>
                            <p>When using ECOTHERM® acoustic spraying, you achieve a timeless, simplified, and industrial appearance. The seamless design makes it easy to implement, as only the layer thickness and color need to be specified. This solution is not only efficient but also cost-effective.</p>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/deco-wood" className="main-btn main-btn-2 mt-40">DECO WOOD <span className="icon">+</span></Link>
                        </div>
                    </div>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="deco-woodtec">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">09. </span>
                    <h1 className="title1">DECO WOOD</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <span className="product9"></span>
                        </div>
                        <div className="top-text-box">
                            <h2 className="title">DECO WOOD</h2>
                            <p>DECOWOOD are absorption panels with outstanding acoustic performance with a combination of Non-Woven Polyester Fiber and Veneer Wooden Finish. These panels give an aesthetic feel to the areas and have good absorption properties.</p>
                        </div>
                        <div className="blog-single-bottom-content-box">
                            <p>DECOWOOD acoustic panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                            <ul>
                                <li>Theatres</li>
                                <li>Conference Rooms</li>
                                <li>Educational Instititions</li>
                                <li>Convention Centre's</li>
                                <li>Banquet Halls</li>
                                <li>Multiplexes</li>
                                <li>Colleges</li>
                                <li>Corporate Offices</li>
                                <li>Auditoriums</li>
                                <li>Hospitals</li>
                            </ul>
                        </div>
                        <span className="line-footer"></span>
                        <div className="portfolio-container2">
                            <Link to="/acoustic-doors" className="main-btn main-btn-2 mt-40">Acoustic Doors <span className="icon">+</span></Link>
                        </div>
                    </div>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="sound-barrier">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">10. </span>
                    <h1 className="title1">Acoustic Doors</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product10"></div>
                        </div>
                        <div className="top-text-box">
                            <h1><span className="fabriwall"> </span>Studio 3D™ Soundproof Interior Doors</h1>
                            <p>Moov Group is proud to offer the markets first affordable soundproof interior door. Our recording studio doors have an STC value up to 56. The Studio 3D soundproof doors are ideal for recording studios, offices, band rooms, hotels, dorm rooms, conference rooms or any application where a soundproof interior door is required. The soundproofing doors come standard in oak, ready to stain with silver hardware. Custom finishes and hardware options are available for this door sound insulation product.</p>
                        </div>
                    </div>
                    
                    
				    <div className="blog-single-bottom-content-box">
				    <h2>Features </h2>
                        <div className="row">
                            <div className="col-xl-6">
                                <ul>
                                <li>Low Cost – High Performance</li>
                                <li>STC Ratings Up To 56</li>
                                <li>Products Tested per ASTM E-90</li>
								<li>Custom Manufactured Acoustic Door to Any Size Specification</li>
								<li>Appx. 300 lb Door Assembly</li>
								<li>2¾″ 13 Layer Door Assembly</li>
								<li>Comes Pre-Hung and Assembled</li>
								<li>Adjustable Soundproof Jambs & Seals</li>
								<li>Ready To Stain/Paint to Match Your Decor</li>
								<li>Best Performance Wood Finished Door Assembly Available</li>
								<li>Automatic Sill</li>
								<li>Heavy Duty Hinges (4)</li>
								<li>3-Point Latching System</li>
                                </ul>
                            </div>
                        </div>
                    </div>
				    
			    </div>
			    <span className="line-footer"></span>
			    <div className="portfolio-container2">
                    <Link to="/pu-foam" className="main-btn main-btn-2 mt-40">Polyurethane Foams <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="polyurethane">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">11. </span>
                    <h1 className="title1">POLYURETHANE FOAMS</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product11"></div>
                        </div>
                        <div className="top-text-box">
                            <p>Acoustic foam is an open celled foam used for acoustic treatment. It attenuates airborne sound waves by increasing air resistance, thus reducing the amplitude of the waves. The energy is dissipated as heat.Acoustic foam comes in a variety of sizes and can be attached to walls, ceilings, doors, and other features of a room to control noise levels, vibration, and echoes.</p>
            
                            
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                        <h2>Categories </h2>
                            <div className="row">
                            
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub1"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer"><h3>Foam Stop Pyramid Design</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>01</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                        <br /><h3>Foam Stop Pyramid Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>These polyurethane pyramid panels are developed to absorb maximum acoustical energy using minimum weight and thickness</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub2"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer"><h3>Foam Stop Plain (FLAT) Design</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>02</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop Plain (FLAT) Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>PU Plain panels come in sound wave pattern and have the advantage of high absorptive surface area</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub3"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer"><h3>Foam Stop Cylindrical Design</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>03</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop Cylindrical Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>These PU cylindrical panels are perfect for spaces where there are echoes and excessive reverberation time related issues</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub4"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer"><h3>Foam Stop Bass Trap Design</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>04</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop Bass Trap Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>PU bass trap panels effectively reduce the reverberations in a uniform manner across the desired acoustic spectrum</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                        <div className="img-holder">
                                            <div className="product11-sub5"></div>
                                            <div className="overlay-style-two"></div>
                                            <div className="overlay-content-box">
                                                <div className="box">
                                                    <div className="inner">
                                                        <div className="icon">
                                                            <span className="fa fa-trophy"></span>
                                                        </div>
                                                        <div className="title">
                                                            <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer"><h3>Foam Stop Eggcrate Design</h3></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="inner-content">
                                                <div className="top">
                                                    <div className="icon">
                                                        <span className="fa fa-hand-o-right"></span>
                                                    </div>
                                                    <div className="count">
                                                        <h1>05</h1>
                                                    </div>
                                                </div>
                                                <div className="bottom">
                                                    <div className="title">
                                                    <br /><h3>Foam Stop Eggcrate Design</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="overlay-content">
                                                <div className="text">
                                                    <p>These panels have an unique shape which helps in improving sound absorption compared to conventional flat surfaces</p>
                                                </div>
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/foamstop-pu" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
				            </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <Link to="/diffuso" className="main-btn main-btn-2 mt-40">Diffuso <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="diffuso">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">12. </span>
                    <h1 className="title1">DIFFUSO</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product12"></div>
                        </div>
                        <div className="top-text-box">
                            <p>DIFFUSO is a 2-Dimensional PRD Diffuser carefully designed utilizing modulated prime root number theory. The calculation shows us that the reflected energies will be equal in all the diffraction directions. They are used to control reflections in the listening environment, particularly from rear walls and first-reflection points.</p>
            
                            
                            <div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
								<div className="row">
                                    <ul>
                                        <li>Made from solid wood</li>
                                        <li>Long lasting and impact resistant</li>
                                        <li>Highly aesthetic on exposed installation</li>
										<li>Reduce Flutter Echoes and Sound Bouncing of the walls</li>
										<li>Works best on Mid-High frequencies</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                            <h2>Applications</h2>
							<div className="row">
                                <ul>
                                    <li>Studios</li>
                                    <li>Mixing Rooms</li>
                                    <li>Hi-Fi Rooms</li>
									<li>Home Theaters</li>
									<li>Auditoriums</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <Link to="/polymax" className="main-btn main-btn-2 mt-40">Polymax <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="polymax">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">13. </span>
                    <h1 className="title1">Polymax</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product13"></div>
                        </div>
                        <div className="top-text-box">
                            <h2>Polymax<sup>Tm</sup>Acoustical Wall & Ceiling Tiles</h2>
                            <p>Poly Max™ polyester acoustic panel is a combination of 100% polyester (60% PET - recycled fibre, 40% PET - virgin fibre) which makes this panel non-allergic, non-toxic, fungal resistant and decorative acoustic panels with zero chemical irritants or formaldehyde.</p>
                            <p>Poly Max™ acoustic panels are available in a variety of colors & can be printed with custom graphics which fit into any decor.</p>
                            
                            
							<div className="blog-single-bottom-content-box">
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Formaldehyde Free</li>
                                        <li>No Binding Agents</li>
                                        <li>No Chemical Irritants</li>
										<li>Architecturally Decorative</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Tackable Surface</li>
                                        <li>Impact Resistant</li>
						                <li>className A Fire Rated</li>
                                        <li>60% Polyethylene Terephthalate – Recycled Content</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
							
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                            <h2>Applications </h2>
                            <p>Theaters & home theaters, gymnasiums & multipurpose room, commercial & office buildings, studios & production houses, pro audio, restaurants & night clubs, government & municipal buildings, hospitals & medical clinics, schools & universities, houses of worship, industrial & manufacturing facilities and much more</p>
                            <div className="row">
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub1"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub2"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub3"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub4"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                    <div className="single-service-style1">
                                        <div className="img-holder">
                                            <div className="product13-sub5"></div>
                                        </div>
                                        <div className="text-holder">
                                            <div className="overlay-content">
                                                <div className="read-more">
                                                    <a href="https://www.acousticalsurfaces.in/polymax-polyester" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
				        </div>
                        
                    </div>
                </div>
                <span className="line-footer"></span>
                <div className="portfolio-container2">
                    <Link to="/polysorb" className="main-btn main-btn-2 mt-40">Polysorb <span className="icon">+</span></Link>
                </div>
            </div>
            
            <div role="tabpanel" className="tab-pane fade" id="polysorb">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">14. </span>
                    <h1 className="title1">Polysorb</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <div className="product14"></div>
                        </div>
                        <div className="top-text-box">
                            <p>Polysòrb Panels are non-allergic and non-toxic chemical free decorative ceiling, wall panels having there core as polyester fibre. Polysorb panels are free of binding agents and having a higher value of impact resistance. These panels are economical with higher acoustic property.</p> 
							<p>Polysorb panels are considerable for the areas like home theatres, gymnasiums, offices, studios, restaurants, hospitals, house of worship and schools.</p>
							
							<div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Lightweight</li>
                                        <li>Impact Resistant</li>
                                        <li>Moisture, bacteria & fungi resistant</li>
										<li>Tackable surface</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Cleanable</li>
                                        <li>Available with various edges and colours</li>
									</ul>
                                    </div>
                                </div>
                            </div>
							
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                            <h2>Categories </h2>
                            <div className="row">
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product14-sub1"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Wall Panels</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>01</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Acoustical<br /> Wall Panels</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Polysorb Wall Panels provide a better sound solution and are engineered for unparalleled sound absorption performance</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product14-sub2"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Ceiling Panels</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>02</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br /> Clouds</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Polysorb Ceiling Panels are undoubtedly the best choice when it comes to acoustic panels for ceilings</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product14-sub3"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Ceiling Baffles</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>03</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical Ceiling Baffles</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Polysorb Baffles are a novel ceiling acoustic solution that boosts the design element of the space without affecting the amount of noise curtailment</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product14-sub4"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Designer Panels</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>04</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical Designer Panels</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Polysorb Designer Panels add a design factor to the space and can be customized to any design as per client's requirement</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
				        </div>
				    </div>
                    
                </div>
            </div>
            <span className="line-footer"></span>
            <div className="portfolio-container2">
                <Link to="/polysorb-deco" className="main-btn main-btn-2 mt-40">Polysorb DECO <span className="icon">+</span></Link>
            </div>
        </div>
        
            <div role="tabpanel" className="tab-pane fade" id="polysorb-deco">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">15. </span>
                    <h1 className="title1">POLYSORB DECO</h1>
                </div>
                <div className="blog-post">
                    <div className="single-blog-post">
                        <div className="main-image-box">
                            <span className="product15"></span>
                        </div>
                        <div className="top-text-box">
                            <p>Polysòrb Panels are non-allergic and non-toxic chemical free decorative ceiling, wall panels having there core as polyester fibre. Polysorb panels are free of binding agents and having a higher value of impact resistance. These panels are economical with higher acoustic property.</p> 
							<p>Polysorb panels are considerable for the areas like home theatres, gymnasiums, offices, studios, restaurants, hospitals, house of worship and schools.</p>
						
							<div className="blog-single-bottom-content-box">
                                <h2>Features </h2>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>High absorption values</li>
                                        <li>Available in various thickness and density as options</li>
                                        <li>High resistance to humidity</li>
										<li>Non-allergenic, non-toxic, fungal-resitant</li>
									</ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Does not contain chemical irritants or formaldehyde</li>
										<li>Can contribute to LEED V4.1 EQ Credit: Low-Emitting Materials</li>
                                        <li>Light weight and can be mounted easily</li>
                                        <li>Easily cleanable</li>
									</ul>
                                    </div>
                                </div>
                            </div>
							
                            <div className="blog-single-bottom-content-box">
                                <h2>Applications </h2>
								<p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                <div className="row">
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Offices</li>
                                        <li>Schools</li>
                                        <li>Meeting Rooms</li>
										<li>Hotels</li>
                                    </ul>
                                    </div>
                                    <div className="col-xl-6">
                                    <ul>
                                        <li>Auditorium</li>
                                        <li>Recording Studios</li>
										<li>Conference halls</li>
                                        <li>Broadcasting Studios</li>
                                    </ul>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        
                        <div className="blog-single-bottom-content-box">
                            <h2>Categories </h2>
                            <div className="row">
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "200ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product15-sub1"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Wall Panels</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>01</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                    <br /><h3>Acoustical<br /> Wall Panels</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>Our POLYSORB-DECO wall panels are very easy to install, very decorative and can be glued or mounted to any surface.</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="400ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "400ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product15-sub2"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Ceiling Clouds</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>02</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br />Ceiling Clouds</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>POLYSORB clouds are the right choice to use as clouds where there is generally a tight space requirement due to the low ceiling height</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-xl-2 col-lg-6 col-md-6 col-sm-12">
                                <div className="single-service-style1 wow fadeInUp animated" data-wow-delay="600ms" data-wow-duration="1200ms" style={{visibility: "visible", animationDuration: "1200ms", animationDelay: "600ms", animationName: "fadeInUp"}}>
                                    <div className="img-holder">
                                        <div className="product15-sub3"></div>
                                        <div className="overlay-style-two"></div>
                                        <div className="overlay-content-box">
                                            <div className="box">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <span className="fa fa-trophy"></span>
                                                    </div>
                                                    <div className="title">
                                                        <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer"><h3>Acoustical Ceiling Baffles</h3></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-holder">
                                        <div className="inner-content">
                                            <div className="top">
                                                <div className="icon">
                                                    <span className="fa fa-hand-o-right"></span>
                                                </div>
                                                <div className="count">
                                                    <h1>03</h1>
                                                </div>
                                            </div>
                                            <div className="bottom">
                                                <div className="title">
                                                <br /><h3>Acoustical<br /> Ceiling Baffles</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="overlay-content">
                                            <div className="text">
                                                <p>POLYSORB-DECO panels are light weight and can be easily hung as baffles or raft ceiling. Due to its strong and rigid edges give the baffles highly appealing and luxurious feel once installed</p>
                                            </div>
                                            <div className="read-more">
                                                <a href="https://www.acousticalsurfaces.in/polysorb" target="_blank" rel="noreferrer noreferrer">
                                        <span className="icon-next"></span></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
					    </div>
				    </div>
                 
                </div>
            </div>
            <span className="line-footer"></span>
            <div className="portfolio-container2">
                <Link to="/polysorb-deco" className="main-btn main-btn-2 mt-40">Polysorb DECO <span className="icon">+</span></Link>
            </div>
        </div>
     </div>   
    </div>
    </div>
    </section>
    
    
    <section className="mob-product">
    
        <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">01. </span>
                    <h1 className="title1">Fabrisorb</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-1">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product1"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">Fabric Wrapped Acoustical Wall & Ceiling Panels</h2>
                                        <p>Fabrisorb acoustic wall and ceiling panels can significantly improve the sound quality of the space which it is used. Usage of Fabrisorb acoustic panels reduce the reverberation and background noise making the sound more precise and clearer.  This quality of these fabric covered acoustic panels makes it perfect for spaces such as offices, schools, meeting rooms, music rooms, hotels, banquet halls, auditoriums, recording studios, broadcasting studios etc where the sound quality is particularly of paramount importance. </p>
                                        <p>Some spaces such as classNamerooms in a school will require the acoustic panels to absorb sound energy in mid and high frequencies so that the speech intelligibility is at its best. It will avoid unnecessary strain for teachers, and they can be heard clearly for optimized learning. Few spaces such as sports halls will require impact resistant panels and few like swimming pool spaces will require acoustic panels which will not take moisture and mould. Fabrisorb acoustic panels are the right solution for all such spaces.</p>
                                    </div>
                                    <Link to="/fabrisorb" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </section>
    
        <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">02. </span>
                    <h1 className="title1">FABRISORB-DECO</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-45">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product2"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title"> FABRISORB-DECO</h2>
                                        <p>Acoustic panel is an essential part of creating a high-quality listening environment whether you are creating a space like recording studio, home theatre, a conference room, office room, classNameroom or even a living room where all what you want is to improve the sound quality. If you are serious about how these spaces sound, then you need to start thinking about what to use in such spaces to create a more balanced and accurate sound which can enhance your listening or recording experience.</p>
                                        <p>For perfect balance of optimal sound quality and the aesthetics opt for Fabrisorb-Deco, where acoustics meets the eyes – Colours . As the name sounds Fabrisorb-Deco is the decorative version of Fabrisorb acoustic panels, where the surface is covered with finest quality of acoustic felt to compliment the architects colour concept. Fabrisorb-Deco comes with wide selection of acoustic felt colours to choose from.</p>
                                    </div>
                                    <Link to="/fabrisorb-deco" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">03. </span>
                    <h1 className="title1">FabriWALL</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-6">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product3"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title"> Stretch Fabric Acoustical Wall Track System </h2>
                                        <p>Fabriwall acoustical panel is a versatile, sustainable, and innovative track solution that empowers architects and designers to meet their desired specifications and criteria for any project. The track system comprises a framework, with the infill placed between it. The fabric is then stretched into the interlocking jaws of the track, providing the entire system with a seamless finish.</p>
                                    </div>
                                    <Link to="/fabriwall" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">04. </span>
                    <h1 className="title1">D'ecopanel</h1>
                </div> 
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-10">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product4"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title"> Decorative Acoustic Panels</h2>
                                        <p>D’ecopanels are highly absorptive acoustic panels with non-woven polyester fiber as its core. They form an effective remedy for reverberation related issues in interior spaces and are customizable to any design depending upon the customer demands. These panels are available in 21 different color options.</p>
                                    </div>
                                    <Link to="/decopanel" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
        <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">05. </span>
                    <h1 className="title1">WoodTEC</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-17">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product5"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">WoodTEC</h2>
                                        <p>WoodTEC panels are acoustic panels with high acoustic performance applicable for both walls and ceilings. The panels are made of MDF a perfect blend of aesthetical and technical qualities.</p>
                                    </div>
                                    <Link to="/woodtec" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">06. </span>
                    <h1 className="title1">ECHO ELIMINATOR</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-22">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product6"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">Bonded Acoustical Cotton (B.A.C.) </h2>
                                        <h2 className="title">The Most Cost Effective Acoustical Absorbing Material On The Market</h2>
                                        <p>Echo Eliminator is a high-performance acoustical material made from recycled cotton. These cotton fabric panels are ideal for noise control applications. Echo Eliminator can be used as an acoustic wall panel or hanging baffle. Common applications of these cotton fabric panels are school gyms, classNamerooms, lunch rooms and any application where a high-performance noise control product is needed. Echo Eliminator cotton fabric panels are eligible for LEED™ credits, className A fire rated and 100% recyclable.</p>
                                    </div>
                                    <Link to="/echo-eliminator" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">07. </span>
                    <h1 className="title1">Mass Loaded Vinyl Barrier</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-22">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product7"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">Noise S.T.O.P.™ Vinyl Barrier</h2>
                                        <p>When noise and sound transmission needs to be effectively controlled, Mass Loaded Vinyl Noise Barrier is the solution to build noise barriers and soundproof walls.</p>
                                    </div>
                                    <Link to="/vinyl-barrier" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">08. </span>
                    <h1 className="title1">ECO THERM</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-22">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product8"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">ECO THERM</h2>
                                        <p>The carbon-neutral ECOTHERM® acoustic spraying  is made from a mixture of recycled paper, wood adhesive, and water. It is professionally installed and effectively absorbs reverberation due to its tubular cellulosic fiber structure.</p>
                                        <p>ECOTHERM® is an environmentally friendly product, as it is based on recycled paper. It can be directly installed on concrete or plasterboard. You can choose the layer thickness between 5 to 40 mm, depending on the level of damping desired. For specific applications, layer thicknesses greater  than 40 mm are also possible.</p>
                                        <p>When using ECOTHERM® acoustic spraying, you achieve a timeless, simplified, and industrial appearance. The seamless design makes it easy to implement, as only the layer thickness and color need to be specified. This solution is not only efficient but also cost-effective.</p>
                                    </div>
                                    <Link to="/eco-therm" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">09. </span>
                    <h1 className="title1">DECO WOODTEC</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-22">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product9"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">DECO WOOD</h2>
                                        <p>DECOWOOD are absorption panels with outstanding acoustic performance with a combination of Non-Woven Polyester Fiber and Veneer Wooden Finish. These panels give an aesthetic feel to the areas and have good absorption properties.</p>
                                    </div>
                                    <Link to="/deco-wood" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">10. </span>
                    <h1 className="title1">Acoustic Doors</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-26">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product10"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">Studio 3D™ Soundproof Interior Doors</h2>
                                        <p>Moov Group is proud to offer the markets first affordable soundproof interior door. Our recording studio doors have an STC value up to 56. The Studio 3D soundproof doors are ideal for recording studios, offices, band rooms, hotels, dorm rooms, conference rooms or any application where a soundproof interior door is required. The soundproofing doors come standard in oak, ready to stain with silver hardware. Custom finishes and hardware options are available for this door sound insulation product.</p>
                                    </div>
                                    <Link to="/acoustic-doors" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">11. </span>
                    <h1 className="title1">POLYURETHANE FOAMS</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-26">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product11"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">FOAM STOP POLYURETHANE FOAMS</h2>
                                        <p>Acoustic foam is an open celled foam used for acoustic treatment. It attenuates airborne sound waves by increasing air resistance, thus reducing the amplitude of the waves. The energy is dissipated as heat.</p>
                                        <p>Acoustic foam comes in a variety of sizes and can be attached to walls, ceilings, doors, and other features of a room to control noise levels, vibration, and echoes.</p>
                                    </div>
                                    <Link to="/pu-foam" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">12. </span>
                    <h1 className="title1">DIFFUSO</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-22">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product12"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">DIFFUSO</h2>
                                        <p>DIFFUSO is a 2-Dimensional PRD Diffuser carefully designed utilizing modulated prime root number theory. The calculation shows us that the reflected energies will be equal in all the diffraction directions. They are used to control reflections in the listening environment, particularly from rear walls and first-reflection points.
                                        </p>
                                    </div>
                                    <Link to="/diffuso" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">13. </span>
                    <h1 className="title1">Polymax</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-38">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product13"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title">PolyMax Polyester Acoustics </h2>
                                        <p>Poly Max™ polyester acoustic panel is a combination of 100% polyester (60% PET - recycled fibre, 40% PET - virgin fibre) which makes this panel non-allergic, non-toxic, fungal resistant and decorative acoustic panels with zero chemical irritants or formaldehyde.</p>
								        <p>Poly Max™ acoustic panels are available in a variety of colors & can be printed with custom graphics which fit into any decor.</p>
                                    </div>
                                    <Link to="/polymax" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
			    </div>
            </div>   
        </section>
	
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">14. </span>
                    <h1 className="title1">Polysorb</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-40">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product14"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title"> Fabric Wrapped Acoustical Panels </h2>
                                        <p>Polysòrb Panels are non-allergic and non-toxic chemical free decorative ceiling, wall panels having there core as polyester fibre. Polysorb panels are free of binding agents and having a higher value of impact resistance. These panels are economical with higher acoustic property.</p>
                                        <p>Polysorb panels are considerable for the areas like home theatres, gymnasiums, offices, studios, restaurants, hospitals, house of worship and schools.</p>
                                    </div>
                                    <Link to="/polysorb" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    
	    <section className="advanced-tabs section-gap1">
            <div className="container">
                <div className="section-title mb-40">
                    <span className="line"></span>
                    <span className="title-tag">15. </span>
                    <h1 className="title1">POLYSORB DECO</h1>
                </div>
                <div className="tab-content">
                    <div role="tabpanel" className="tab-pane fade active show" id="tab-45">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-6 col-md-10">
                                <span className="product15"></span>
                            </div>
                            <div className="col-lg-6 col-md-10">
                                <div className="advanced-tab-text">
                                    <div className="section-title1 mb-40">
                                        <h2 className="title"> POLYSORB DECO</h2>
                                        <p>Polysòrb Panels are non-allergic and non-toxic chemical free decorative ceiling, wall panels having there core as polyester fibre. Polysorb panels are free of binding agents and having a higher value of impact resistance. These panels are economical with higher acoustic property.</p> 
							            <p>Polysorb panels are considerable for the areas like home theatres, gymnasiums, offices, studios, restaurants, hospitals, house of worship and schools.</p>
                                    </div>
                                    <Link to="/polysorb-deco" className="main-btn main-btn-2 mt-40">learn more <span className="icon">+</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
	
    </section>
    </div>
    </div>
    <Footer />
</>
  );
}

export default products;