import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Header from '../header';
import Footer from '../footer';
import './career.css';
function Career() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        age: "",
        expertise: "",
        experience: "",
        position: "",
    });

    const [errors, setErrors] = useState({
        firstName: "",
        lastName: "",
        email: "",
        telephone: "",
        age: "",
        expertise: "",
        experience: "",
        position: "",
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let errorMessage = "";

        if (name === "firstName" || name === "lastName") {
            const nameRegex = /^[a-zA-Z\s]*$/;
            errorMessage = !nameRegex.test(value) ? "Name can only contain letters" : "";
        } else if (name === "email") {
            const emailRegex = /^[^\s@]+@gmail\.(com|in)$/i;
            errorMessage = !emailRegex.test(value) ? "Invalid email address" : "";
        } else if (name === "telephone") {
            const numberRegex = /^\d*$/;
            errorMessage = !numberRegex.test(value) ? "Telephone number must contain only numeric" : "";
        } else if (name === "age") {
            const ageValue = parseInt(value);
            errorMessage = isNaN(ageValue) || ageValue < 18 || ageValue > 100 ? "Please enter a valid age (between 18 and 100)" : "";
        }

        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: errorMessage });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Check for errors before submitting
        const formErrors = Object.values(errors).filter((error) => error !== "");
        if (formErrors.length > 0) {
            alert("Please fix the errors before submitting the form.");
            return;
        }


        const { firstName, lastName, email, telephone, age, expertise, experience, position } = formData;

        const mailtoLink = `mailto:career@moovgroup.com?subject=CAREER SUBMISSION FORM &body=Name: ${firstName} ${lastName}%0D%0AEmail: ${email}%0D%0ATelephone: ${telephone}%0D%0AAge: ${age}%0D%0AExpertise: ${expertise}%0D%0ARelevant Experience: ${experience}%0D%0APosition: ${position}`;

        window.location.href = mailtoLink;


        setFormData({
            firstName: "",
            lastName: "",
            email: "",
            telephone: "",
            age: "",
            expertise: "",
            experience: "",
            position: "",
        });

    };
    return (

        <>
            <div className="boxed_wrapper" id="career">
                <Helmet>
                    <title>Current Job Openings - Moov Group</title>
                    <meta name="description" content=" " />
                    <meta property="og:title" content="Current Job Openings - Moov Group" />
                    <meta property="og:description" content="" />
                    <meta property="og:image" content="../../images/moovgroup-office.webp" />
                    <link rel="canonical" href="https://www.moovgroup.com/career" />
                    <link rel="alternate" href="https://www.moovgroup.com/career" hreflang="en-GB" />
                </Helmet>
                <Header />
                <section className="appointment-area career-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6">
                                <div className="map-content-box">
                                    <div className="sec-title">
                                        <div className="title">We are Hiring</div>
                                    </div>
                                    <div className="job-listing">
                                        <div className="sec-title">
                                            <p>Current Job Openings</p>
                                        </div>
                                        <div className="job-card">
                                            <h4><span className="title-tag">01</span>Project Engineer</h4>
                                            <ul>
                                                <li>Prepare, schedule, coordinate and monitor the allocated Projects</li>
                                                <li>Monitor compliance to applicable codes, practices, QA/QC policies, performance standards and specifications for Projects</li>
                                                <li>Interact daily with the clients to interpret on their Project requirements</li>
                                            </ul>
                                        </div>

                                        <div className="job-card">
                                            <h4><span className="title-tag">02</span>Autocad Designer</h4>
                                            <ul>
                                                <li>Developing designs for Projects from scratch</li>
                                                <li>Presenting design drafts for client approvals</li>
                                                <li>Blending techniques and aesthetics to incorporate in final designs</li>
                                            </ul>
                                        </div>

                                        <div className="job-card">
                                            <h4><span className="title-tag">03</span>Production Engineer</h4>
                                            <ul>
                                                <li>Supervising the overall manufacturing processes, ensuring quality work is done in a safe, efficient manner</li>
                                                <li>Liaising with other engineers to develop plans that improve production, costs, and labour required for concerned requirements</li>
                                                <li>Diagnosing problems in the production line and providing recommendations and training for improvements</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-6 col-lg-6" id="form">
                                <div className="sec-title">
                                    <div className="title1">Register your  <span className="fontweight">dream job</span>
                                    </div>
                                </div>
                                <div className="appointment-box wow slideInRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                                    <div className="title-box">
                                        <h2>Career</h2>
                                        <span>Leave your information here and get reply from our expert in 24 hours, don’t hesitate to ask.</span>
                                    </div>
                                    <div className="appointment">
                                        <form className="appointment-form" onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div className="single-box">
                                                        <input type="text" name="firstName" placeholder="First Name" required value={formData.firstName} onChange={handleInputChange} />
                                                        {errors.firstName && <p style={{ color: "red", fontSize: "12px" }} className="error">{errors.firstName}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="single-box">
                                                        <input type="text" name="lastName" placeholder="Last Name" required value={formData.lastName} onChange={handleInputChange} />
                                                        {errors.lastName && <p style={{ color: "red", fontSize: "12px" }} className="error">{errors.lastName}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="single-box">
                                                        <input type="email" name="email" placeholder="Email" required value={formData.email} onChange={handleInputChange} />
                                                        {errors.email && <p style={{ color: "red", fontSize: "12px" }} className="error">{errors.email}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div className="single-box">
                                                        <input type="text" name="telephone" placeholder="Telephone" required value={formData.telephone} onChange={handleInputChange} />
                                                        {errors.telephone && <p style={{ color: "red", fontSize: "12px" }} className="error">{errors.telephone}</p>}
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="single-box">
                                                        <input type="text" name="age" placeholder="Age" required value={formData.age} onChange={handleInputChange} />
                                                        {errors.age && <p style={{ color: "red", fontSize: "12px" }} className="error">{errors.age}</p>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div className="single-box">
                                                        <input type="text" name="expertise" placeholder="Expertise" required value={formData.expertise} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="single-box">
                                                        <input type="text" name="experience" placeholder="Relevent Experience" required value={formData.experience} onChange={handleInputChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="single-box">
                                                        <input type="text" name="position" list="positionList" placeholder="Select Position" required value={formData.position} onChange={handleInputChange} />
                                                        <datalist id="positionList">
                                                            <option value="Project Engineer" />
                                                            <option value="Autocad Engineer" />
                                                            <option value="Production Engineer" />
                                                        </datalist>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xl-12">
                                                    <div className="single-box">
                                                        <button className="btn-one" type="submit">Submit Here<span className="flaticon-next"></span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    );
}

export default Career;